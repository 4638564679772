import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { RootState } from "../actions/store";
import AdminPage from "./JobUpdates/AdminPage";
import { isUserAdmin } from "../uiHelper";

const ProtectedAdminRoute: React.FC = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const user = useSelector((state: RootState) => state.currentUser);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (user && user.email) {
        setIsAdmin(isUserAdmin(user.email));
      }
      setLoading(false); // Stop loading after the timeout
    }, 1000); // 1 second delay to fetch user data

    return () => clearTimeout(timeoutId); // Cleanup timeout on unmount
  }, [user]);

  if (loading) {
    return <div className="m-20 items-center">Loading...</div>; // Loading indicator
  }

  if (!user || !isAdmin) {
    return <Navigate to="/no-access" />; // Redirect to no-access if user is not found or not admin
  }

  return <AdminPage />;
};

export default ProtectedAdminRoute;
