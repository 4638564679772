import React, { useState, useEffect } from "react";
import axios from "axios";
import AceEditor from "react-ace";
import { useSocket } from "./SocketProvider";

import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-monokai";

const baseUrl = process.env.REACT_APP_ENV
  ? "https://asia-south1-kampkode-ecb6c.cloudfunctions.net/kampkodeCodeEditor-api"
  : "http://127.0.0.1:5001/kampkode-ecb6c/asia-south1/kampkodeCodeEditor-api";

const CodeEditor = ({ isTeacher, classId }) => {
  const [code, setCode] = useState("");
  const [language, setLanguage] = useState(""); // Default to empty
  const [output, setOutput] = useState("");
  const [languages, setLanguages] = useState([]);

  const [mode, setMode] = useState("javascript");
  const socket = useSocket();

  useEffect(() => {
    socket.on("receive-code", (receivedCode) => {
      setCode(receivedCode);
    });
    // eslint-disable-next-line
  }, []);

  const onChange = (newCode) => {
    setCode(newCode);
    if (isTeacher && socket) {
      socket.emit("send-code", { classId, newCode });
    }
  };
  console.log(mode);
  console.log(language);

  const handleModeChange = (e) => {
    setLanguage(e.target.value);
    const langId = parseInt(e.target.value, 10); // parse to integer if it's a string

    switch (langId) {
      case 63:
      case 64:
        setMode("javascript");
        break;
      case 70:
      case 92:
      case 71:
        setMode("python");
        break;
      case 91:
      case 62:
        setMode("java");
        break;
      default:
        setMode("javascript");
    }
  };

  useEffect(() => {
    // Fetch languages from the backend
    const fetchLanguages = async () => {
      try {
        console.log("code editor");
        const response = await axios.get(`${baseUrl}/languages`);
        console.log(response);
        setLanguages(response.data);
      } catch (error) {
        console.error("Failed to fetch languages:", error);
      }
    };
    fetchLanguages();
  }, []);

  const compileCode = async () => {
    try {
      const response = await axios.post(`${baseUrl}/compile`, {
        code,
        language,
      });
      console.log(response);
      const token = response.data.token;
      console.log("token = ", token);
      if (response.status === 200 && token) {
        const resp = await axios.get(
          `${baseUrl}/getSubmission/?token=${token}`
        );
        console.log(resp);
        setOutput(resp.data.stdout || "Compilation Error");
      }
    } catch (error) {
      console.error("Compilation failed:", error);
      setOutput("Compilation failed");
    }
  };

  return (
    <>
      <div>
        <select onChange={handleModeChange}>
          {languages.map((lang) => (
            <option key={lang.id} value={lang.id}>
              {lang.name}
            </option>
          ))}
        </select>
        {/* <textarea
          rows="10"
          cols="50"
          value={code}
          onChange={(e) => setCode(e.target.value)}
        ></textarea> */}
      </div>
      <div>
        <h1>My Code Editor</h1>

        {/* <label htmlFor="mode">Choose Mode: </label>
        <select id="mode" onChange={handleModeChange} value={mode}>
          <option value="javascript">JavaScript</option>
          <option value="python">Python</option>
          <option value="java">Java</option>
        </select> */}

        <AceEditor
          mode={mode}
          theme="monokai"
          name="blah2"
          // onLoad={this.onLoad}
          onChange={onChange}
          fontSize={14}
          showPrintMargin={true}
          showGutter={true}
          highlightActiveLine={true}
          value={code}
          setOptions={{
            enableBasicAutocompletion: false,
            enableLiveAutocompletion: false,
            enableSnippets: true,
            showLineNumbers: true,
            showGutter: true,
            tabSize: 4,
          }}
          readOnly={!isTeacher}
        />
        <div>
          <button onClick={compileCode}>Run</button>
          <div>
            <strong>Output:</strong>
            <pre>{output}</pre>
          </div>
        </div>
      </div>
    </>
  );
};

export default CodeEditor;
