import React from "react";

interface ConfirmationModalProps {
  title: string;
  showModal: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  title,
  showModal,
  onConfirm,
  onCancel,
}) => {
  if (!showModal) return null;

  return (
    <div
      className="z-50"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        borderRadius: "10px",
      }}
    >
      <div
        style={{
          background: "white",
          padding: "20px",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          border: "2px solid #7D6EEB",
          gap: "30px",
        }}
      >
        <p style={{ fontWeight: "bold" }}>{title}</p>
        <div style={{ display: "flex", gap: "20px" }}>
          <button
            onClick={onConfirm}
            style={{
              background: "#E21616",
              border: "none",
              fontSize: "16px",
              color: "white",
              padding: "8px 16px",
              cursor: "pointer",
              borderRadius: "7px",
            }}
          >
            Exit
          </button>
          <button
            onClick={onCancel}
            style={{
              background: "#D9D9D9",
              border: "none",
              fontSize: "16px",
              color: "#000000",
              padding: "8px 16px",
              cursor: "pointer",
              borderRadius: "7px",
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
