import React from 'react'
import NumberTicker from '../magicui/number-ticker'

const OurMission = () => {
  return (
    <div className='mt-10 flex flex-col justify-center gap-20 mx-10 lg:mx-20 py-10'>
    <div className='flex gap-3 lg:gap-0  flex-col lg:flex-row '>
        <div className='w-full lg:w-[70%]' >

            <span className='text-themepruple text-md pt-4 mt-10'> Our Mission </span>
            <h1 className='text-4xl lg:text-5xl font-semibold py-2 lg:w-[95%] text-[#101828] leading-tight'>Inspiring Students to Achieve Their Full Potential and Lead with Confidence</h1>
        </div>
        <div className='leading-tight text-[#475467] font-light text-xl  lg:mt-8  lg:mr-16 flex-1'>

            <p>We commit to nurturing each student’s abilities, fostering their growth, and guiding them towards confident leadership and exceptional achievements.</p>
        </div>
    </div>
    <div className='h-fit lg:h-60  flex flex-col lg:flex-row gap-14 bg-[#EFE7FF] text-themepruple text-center justify-around rounded-lg py-12 w-full lg:px-20 mx-auto'>
          <div className='flex flex-col items-center justify-center lg:w-80'>
            <NumberTicker value={5000}  className='text-themepruple font-semibold'/>
            <p className='text-[#42307D] text-xl font-medium  mt-4'>Hours of Training Delivered</p>
          </div>
          
          <div className='flex flex-col items-center justify-center lg:w-80'>

            <NumberTicker value={120} className='text-themepruple font-semibold'/>
            <p className='text-[#42307D] text-xl font-medium mt-4'>Successful Programs Completed</p>
          </div>
          
          <div className='flex flex-col items-center justify-center lg:w-80'>
            <NumberTicker value={10000} className='text-themepruple font-semibold'/>
            <p className='text-[#42307D] text-xl font-medium mt-4'>Students Enrolled</p>
          </div>

        </div>
</div>
  )
}

export default OurMission