import React from "react";

interface ProgressBarProps {
  progress: number;
  height?: string;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  progress,
  height = "20px",
}) => {
  // Define styles for the container
  const containerStyles: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    width: "100%",
  };

  // Define styles for the progress bar itself
  const barStyles: React.CSSProperties = {
    height: height,
    width: "100%",
    backgroundColor: "#e0e0de",
    borderRadius: "50px",
    overflow: "hidden",
  };

  // Define styles for the filler
  const fillerStyles: React.CSSProperties = {
    height: "100%",
    width: `${progress}%`,
    backgroundColor: "#7f6efc",
    transition: "width 0.5s ease-in-out",
  };

  // Define styles for the label
  const labelStyles: React.CSSProperties = {
    marginLeft: "10px",
    fontWeight: "bold",
    color: "#7f6efc",
  };

  return (
    <div style={containerStyles}>
      <div style={barStyles}>
        <div style={fillerStyles} />
      </div>
      <span style={labelStyles}>{`${progress}%`}</span>
    </div>
  );
};

export default ProgressBar;
