import { Reading_Material } from "../../data/ReadingMaterials";
import { NavLink } from "react-router-dom";
import ProgressBar from "../utils/ProgressBar";

const ReadingHome = () => {
  return (
    <div className="pt-[80px]">
      <div className="w-full text-center bg-customThinPurple pt-6">
        <div className="font-semibold text-6xl pt-8">Reading Materials</div>
        <div className="py-8 lg:px-40 md:px-10 px-10 font-medium">
          -Dive into a World of Insights with our Reading Resources.
        </div>
      </div>
      <div>
        <div className="flex flex-wrap justify-around px-10 lg:px-20 py-10">
          {Reading_Material.map((course: any) => {
            return (
              <NavLink
                key={course.courseId}
                to={`/reading-materials/${course.courseId}`}
              >
                <div
                  className="p-6 w-[400px] border border-customLightPurple rounded-2xl m-6 hover:bg-[#E4E4E4] cursor-pointer"
                  style={{ fontFamily: "Poppins" }}
                >
                  <div className="text-center font-bold text-customLightPurple text-2xl pb-2">
                    {course.courseName}
                  </div>
                  <div className="text-center text-[#808080] pb-2">
                    {course.description}
                  </div>
                  <ProgressBar progress={50} height="5px" />
                </div>
              </NavLink>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ReadingHome;
