import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import WordRotate from "../magicui/word-rotate";
const MainContent = () => {
  return (
    <div>
      <div className="w-4/5 md:w-3/4 lg:w-2/3 mx-auto text-center flex flex-col gap-5 items-center justify-center ">
        <span className="border rounded-2xl w-fit pl-5 pr-2 py-1 text-[#344054]">
          <span className="border rounded-lg w-fit px-2 -ml-4 mr-1">
            <span className="w-2 h-2 bg-purple-500 rounded-full shadow-sm shadow-purple-500/50 inline-block mb-0.5 mr-1 "></span>
            New
          </span>
          <a href="/job-updates" className="cursor-pointer"> Check out job updates 
          <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
          </a>
        </span>
        <div className="flex gap-3 justify-between">

        <span className="text-2xl md:text-4xl lg:text-6xl font-bold leading-none mt-1 md:mt-0 ">
          We Provide
        </span>
        <WordRotate
          className="text-2xl  md:text-4xl lg:text-6xl font-bold text-[#7F56D9] dark:text-white pb-4 w-32 md:w-44 lg:w-80"
          words={["Internship","Projects" ,"CRT"]}
          />

        </div>
        <p className="px-6 md:px-10 lg:px-24 text-sm md:text-xl text-center w-full  text-[#475467] mx-auto">
          Unlock your potential with our comprehensive training. Track your
          progress in real- time, learn from industry experts, and enjoy
          affordable, high-quality education. Trusted by over 4,000 students.
        </p>
        <div className="flex flex-col md:flex-row items-center justify-center gap-3 w-fullpy-4">
  <button className="border px-5 py-3 bg-white  rounded-sm w-fit font-semibold font-Inter text-[#344054]">
    <FontAwesomeIcon icon={faArrowRight} className="mr-2 text-[16px]" /> Start Learning Today
  </button>
  <button
    className="text-white px-20 py-3 rounded-sm w-fit bg-[#7F56D9] hover:bg-[#7F56FF] transition-all ease-in-out "
  >
    Join Now
  </button>
</div>

      </div>
    </div>
  );
};

export default MainContent;
