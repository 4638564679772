
import React from 'react'

interface BenifitsCardProps {
    title : string;
    description : string;
    Icon : any;
}

const BenifitsCard: React.FC<BenifitsCardProps> = ({title, description, Icon}) => {
  return (
    <div className='bg-[#F9FAFB] w-72 lg:h-64 lg:w-96 flex flex-col justify-center items-center relative mt-4 rounded-md gap-6 px-6 py-14 mx-auto overflow-visible'>
        
        <div className='absolute  left-1/2  transform -translate-x-1/2 -top-5'>
  
        <Icon/>
        </div>
        <h2 className='font-semibold text-[#101828] text-center mx-6'>{title}</h2>
        <p className='text-[#475467] font-normal text-base text-center'>{description}</p>

    </div>
  )
}

export default BenifitsCard