import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import HtmlRenderer from "./HtmlRenderer";
import { Reading_Material } from "../../data/ReadingMaterials"; // Uncomment or correct based on your import

// Populate this array with your data

const ReadingMaterial: React.FC = () => {
  const { courseId } = useParams<{ courseId: string }>();
  // const { courseName } = useParams<{ courseName: string }>();
  const [showSelectedIndex, setShowSelectedIndex] = useState<number>(0);
  const [showSelectedTopic, setShowSelectedTopic] = useState<number>(0);

  useEffect(() => {
    const course = Reading_Material.find((item) => item.courseId === courseId);
    if (course && course.chapters[0] && course.chapters[0].subTopics) {
      setShowSelectedIndex(0);
      setShowSelectedTopic(0);
    }
  }, [courseId]); // Include courseId in the dependency array

  const selectionHandler = (index: number): void => {
    setShowSelectedIndex(index);
    setShowSelectedTopic(0);
  };

  const topicSelectionHandler = (
    event: React.MouseEvent,
    chapterIndex: number,
    topicIndex: number
  ): void => {
    event.stopPropagation();
    setShowSelectedIndex(chapterIndex);
    setShowSelectedTopic(topicIndex);
  };
  const nextTopic = (): void => {
    const course = Reading_Material.find((item) => item.courseId === courseId);
    if (course) {
      if (
        showSelectedTopic <
        course.chapters[showSelectedIndex].subTopics.length - 1
      ) {
        setShowSelectedTopic(showSelectedTopic + 1); // Only update showSelectedTopic
      } else if (showSelectedIndex < course.chapters.length - 1) {
        selectionHandler(showSelectedIndex + 1);
        // setShowSelectedIndex(showSelectedIndex + 1);  // Update the chapter index
        // setShowSelectedTopic(0);  // Redirect to the first topic of the next chapter
      }
    }
  };

  const prevTopic = (): void => {
    const course = Reading_Material.find((item) => item.courseId === courseId);
    if (course) {
      if (showSelectedTopic > 0) {
        setShowSelectedTopic(showSelectedTopic - 1); // Move to the previous topic in the current chapter
      } else if (showSelectedIndex > 0) {
        setShowSelectedIndex(showSelectedIndex - 1); // Move to the last chapter
        const lastTopicIndex =
          course.chapters[showSelectedIndex - 1].subTopics.length - 1;
        setShowSelectedTopic(lastTopicIndex); // Redirect to the last topic of the previous chapter
      }
    }
  };

  const markAdCompletedHandler = () => {};

  const course = Reading_Material.find((item) => item.courseId === courseId);

  return (
    <>
      <div className="w-full text-center bg-customThinPurple">
        <div className="absolute top-6 left-6 flex items-center text-customLightPurple font-bold">
          <span className="w-1 h-1 mr-1 rounded-full bg-customLightPurple inline-block"></span>
          Reading Materials
        </div>
        <Link
          to="/reading-materials"
          className="inline-block absolute top-6 right-6"
        >
          <div className="border px-6 mx-2 text-red-600 shadow-sm font-bold shadow-red-600 border-red-600 py-1 rounded-lg">
            <span>X</span> Exit
          </div>
        </Link>
        <div className="font-semibold text-6xl pt-8">{course?.courseName}</div>
        <div className="py-4 lg:px-40 md:px-10 px-10 font-medium">
          -Knowledge is Power - Start Reading Today!
        </div>
      </div>
      <div className="flex py-6">
        {/* Left Sidebar */}
        <div className="w-1/4 min-h-[100vh] ml-4 p-4 rounded-2xl border-2 border-[#7d6eeb]">
          <div className="text-center text-customLightPurple pb-10">
            {/* <h2 className="text-2xl font-bold">{course?.courseName}</h2> */}
          </div>
          {course ? (
            course.chapters.map((chapter, chapterIndex) => (
              <div
                onClick={() => selectionHandler(chapterIndex)}
                key={chapterIndex}
                className="mb-4"
              >
                <div
                  className={`text-lg text-[#7D6EEB] font-semibold cursor-pointer pl-2 py-1 rounded `}
                >
                  {/* ${chapterIndex === showSelectedIndex ? 'bg-[#d4cfff] text-white' : 'hover:bg-gray-200'} */}
                  <div className="flex justify-between items-center">
                    {chapter.title}
                    {true && (
                      <div className="bg-[#27DA4F] rounded-full w-[22px] h-[22px] flex items-center justify-center">
                        <svg
                          className="w-[22px] h-[22px] text-white"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="3"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path d="M5 13l4 4L19 7" />
                        </svg>
                      </div>
                    )}
                  </div>
                  {!(course.chapters.length - 1 === chapterIndex) && (
                    <hr style={{ borderTop: "1.5px solid #7d6eeb" }} />
                  )}
                </div>
                {chapterIndex === showSelectedIndex && (
                  <div className="ml-4">
                    {chapter.subTopics.map((topic, topicIndex) => (
                      <div
                        onClick={(event) =>
                          topicSelectionHandler(event, chapterIndex, topicIndex)
                        }
                        key={topicIndex}
                        className={`${
                          topicIndex === showSelectedTopic
                            ? "bg-blue-300 text-white"
                            : "hover:bg-gray-200"
                        } text-sm text-[gray] font-semibold cursor-pointer pl-2 py-1 rounded mt-2 `}
                        // ${topicIndex === showSelectedTopic? 'bg-blue-300 text-white' :'hover:bg-gray-200' }
                      >
                        {topic.title}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))
          ) : (
            <p>No data for this course</p>
          )}
        </div>
        {/* Right Main Content */}
        <div className="w-3/4 p-4">
          <div className="bg-white rounded shadow p-4">
            {course ? (
              <>
                <div className="flex justify-end mb-4 items-center">
                  <button
                    onClick={prevTopic}
                    disabled={
                      showSelectedIndex === 0 && showSelectedTopic === 0
                    }
                    className={`rounded text-[#7d6eeb] ${
                      showSelectedTopic === 0 && showSelectedIndex === 0
                        ? "opacity-50 cursor-not-allowed"
                        : ""
                    }`}
                  >
                    &lt; Previous
                  </button>
                  <button
                    onClick={nextTopic}
                    disabled={
                      showSelectedIndex === course.chapters.length - 1 &&
                      showSelectedTopic ===
                        course.chapters[showSelectedIndex].subTopics.length
                    }
                    className={`ml-8 rounded text-[#7d6eeb] ${
                      showSelectedIndex === course.chapters.length - 1
                        ? "opacity-50 cursor-not-allowed"
                        : ""
                    } 
                    ${
                      showSelectedIndex === course.chapters.length - 1 &&
                      showSelectedTopic ===
                        course.chapters[showSelectedIndex].subTopics.length
                        ? "opacity-50 cursor-not-allowed"
                        : ""
                    }`}
                  >
                    Next &gt;
                  </button>
                </div>
                {course.chapters.map((chapter, chapterIndex) => {
                  return chapterIndex === showSelectedIndex
                    ? chapter.subTopics.map((topic, topicIndex) => {
                        return topicIndex === showSelectedTopic ? (
                          <div key={topicIndex} className="mb-4 p-2 rounded">
                            <div className="text-4xl text-[#7d6eeb] font-bold mb-6">
                              {topic.title}
                            </div>
                            <hr
                              style={{
                                borderTop: "1.5px solid #7d6eeb",
                                padding: "1rem",
                              }}
                            />
                            {/* <div className='html-content-wrapper'><div dangerouslySetInnerHTML={{__html:topic.description }} /></div> */}
                            <div>
                              <HtmlRenderer
                                htmlString={topic.description as string}
                              />
                            </div>
                          </div>
                        ) : null;
                      })
                    : null;
                })}
                <hr
                  style={{
                    borderTop: "1.5px solid #7d6eeb",
                    padding: "1rem",
                  }}
                />
                <div className="flex justify-between mb-4 items-center">
                  <button
                    className="text-white rounded-[5%] bg-[#7d6eeb] ml-5 px-5 py-2"
                    onClick={() => markAdCompletedHandler}
                  >
                    Mark As Completed
                  </button>
                  <div className="flex justify-end items-center">
                    <button
                      onClick={prevTopic}
                      disabled={
                        showSelectedIndex === 0 && showSelectedTopic === 0
                      }
                      className={`rounded text-[#7d6eeb] ${
                        showSelectedTopic === 0 && showSelectedIndex === 0
                          ? "opacity-50 cursor-not-allowed"
                          : ""
                      }`}
                    >
                      &lt; Previous
                    </button>
                    <button
                      onClick={nextTopic}
                      disabled={
                        showSelectedIndex === course.chapters.length - 1 &&
                        showSelectedTopic ===
                          course.chapters[showSelectedIndex].subTopics.length
                      }
                      className={`ml-8 rounded text-[#7d6eeb] ${
                        showSelectedIndex === course.chapters.length - 1
                          ? "opacity-50 cursor-not-allowed"
                          : ""
                      } 
                    ${
                      showSelectedIndex === course.chapters.length - 1 &&
                      showSelectedTopic ===
                        course.chapters[showSelectedIndex].subTopics.length
                        ? "opacity-50 cursor-not-allowed"
                        : ""
                    }`}
                    >
                      Next &gt;
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <p>No data for this course</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ReadingMaterial;
