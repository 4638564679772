const baseUrl = process.env.REACT_APP_ENV
  ? "https://asia-south1-kampkode-ecb6c.cloudfunctions.net/kampkode/"
  : "http://127.0.0.1:5001/kodekamp-dev-7f75c/asia-south1/kampkode/";
  
export const sendMessage = async (payload: {
  name: string;
  email: string;
  phone: string;
  message: string;
}) => {
  return await fetch(`${baseUrl}sendQuery`, {
    headers: {
      "Content-Type": "application/json",
      "X-Firebase-AppCheck": `appCheckTokenResponse.token`,
    },
    method: "POST",
    body: JSON.stringify(payload),
  }).then((resp) => resp.json());
};
