import { Link, useParams } from "react-router-dom";
import { flashcards } from "../../data/flashcards";
import { useState, useEffect, useCallback } from "react";
import "./FlashcardDetailsPage.css";
import right from "../../assets/right.png";
import left from "../../assets/left.png";

function FlashcardDetailsPage() {
  const { id: category } = useParams();

  const matchingFlashcards = flashcards.filter(
    (flashcard) => flashcard.category === category
  );

  const [isFlipped, setIsFlipped] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const handleClick = () => {
    setIsFlipped(!isFlipped);
  };

  const handleNextClick = useCallback(() => {
    if (currentQuestionIndex < matchingFlashcards.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setIsFlipped(false);
    }
  }, [currentQuestionIndex, matchingFlashcards.length]);

  const handlePrevClick = useCallback(() => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      setIsFlipped(false);
    }
  }, [currentQuestionIndex]);

  useEffect(() => {
    function handleArrowKeys(event: KeyboardEvent) {
      if (event.keyCode === 37) {
        handlePrevClick();
      } else if (event.keyCode === 39) {
        handleNextClick();
      }
    }

    window.addEventListener("keydown", handleArrowKeys);
    return () => {
      window.removeEventListener("keydown", handleArrowKeys);
    };
  }, [currentQuestionIndex, handleNextClick, handlePrevClick]);

  return (
    <>
      <div className="w-full text-center bg-customThinPurple">
        <div className="absolute top-6 left-6 flex items-center text-customLightPurple font-bold">
          <span className="w-1 h-1 mr-1 rounded-full bg-customLightPurple inline-block"></span>
          Flash Cards
        </div>
        <Link to="/flashcards" className="inline-block absolute top-6 right-6">
          <div className="border px-6 mx-2 text-red-600 shadow-sm font-bold shadow-red-600 border-red-600 py-1 rounded-lg">
            <span>X</span> Exit
          </div>
        </Link>
        <div className="font-semibold text-6xl pt-8">
          {matchingFlashcards[currentQuestionIndex].category}
        </div>
        <div className="py-8 lg:px-40 md:px-10 px-10 font-medium">
          Flashcards are compact learning tools, often in the form of small
          cards or digital sets, with information or questions on one side and
          corresponding answers on the other.
        </div>
      </div>
      {matchingFlashcards.length > 0 && (
        <div className="py-20">
          {/* <div className="question-number">
            <p>{`${currentQuestionIndex + 1} / ${
              matchingFlashcards.length
            }`}</p>
          </div> */}
          <div className={`card ${isFlipped ? "flipped" : ""}`}>
            <div className="front">
              <h2 className="question">{`Q${currentQuestionIndex + 1}) ${
                matchingFlashcards[currentQuestionIndex].question
              }`}</h2>
              <div className="button-container">
                {currentQuestionIndex === 0 ? (
                  <span></span>
                ) : (
                  <button
                    className="prev-button mb-[25%] ml-[-15%] bg-[#ffffff]"
                    onClick={handlePrevClick}
                  >
                    <img
                      src={left}
                      alt="left icon"
                      className="text-customLightPurple"
                    />
                  </button>
                )}
                <button className="flip-button" onClick={handleClick}>
                  Flip
                </button>
                {currentQuestionIndex !== matchingFlashcards.length - 1 && (
                  <button
                    className="next-button mb-[25%] mr-[-15%]"
                    onClick={handleNextClick}
                  >
                    <img src={right} alt="right" />
                  </button>
                )}
              </div>
            </div>
            <div className="back">
              <h2 className="answer">
                {matchingFlashcards[currentQuestionIndex].answer}
              </h2>
              <button className="flip-button" onClick={handleClick}>
                Flip
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default FlashcardDetailsPage;
