const baseUrl = process.env.REACT_APP_ENV
  ? "https://asia-south1-kampkode-ecb6c.cloudfunctions.net/kampkode/"
  : "http://127.0.0.1:5001/kampkode-dev-7f75c/asia-south1/kampkode/";

export const getAllQuizzesMetaData = async () => {
  return await fetch(`${baseUrl}getAllQuizzesMetaData`, {
    headers: {
      "Content-Type": "application/json",
      "X-Firebase-AppCheck": `appCheckTokenResponse.token`,
    },
    method: "POST",
  }).then((resp) => resp.json());
};

export const getQuiz = async (payload: {
  id: string | undefined;
  quizId: string | null;
}) => {
  return await fetch(`${baseUrl}getQuiz`, {
    headers: {
      "Content-Type": "application/json",
      "X-Firebase-AppCheck": `appCheckTokenResponse.token`,
    },
    method: "POST",
    body: JSON.stringify(payload),
  }).then((resp) => resp.json());
};

export const SubmitQuiz = async (payload: {
  email: string;
  quizTitle: string | undefined;
  quizId: string;
  quizTopic: string;
  totalScore: number;
}) => {
  return await fetch(`${baseUrl}submitQuiz`, {
    headers: {
      "Content-Type": "application/json",
      "X-Firebase-AppCheck": `appCheckTokenResponse.token`,
    },
    method: "POST",
    body: JSON.stringify(payload),
  }).then((resp) => resp.json());
};
