import React from 'react'
import DesktopCarousel from './DesktopCarousel'
import MobileCarousel from './MobileCarousel'
import { ProjectDataType } from '@/types/types';
const CarouselComponent = ( { Data }: { Data: ProjectDataType } ) => {
  // console.log('got data in Carousel Componenet')
  // console.log(Data)
  return (
    <div>
        <div>
        <div className='hidden lg:block'>
        <DesktopCarousel Data={Data}/>
        </div>
        <div className='lg:hidden'>
        <MobileCarousel  Data={Data}/>
        </div>
      </div>
    </div>
  )
}

export default CarouselComponent