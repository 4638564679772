import React from "react";
import "./index.css";
import AppWrapper from "./components/App";
import reportWebVitals from "./reportWebVitals";
import { createRoot } from "react-dom/client";

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<AppWrapper />);

// Same reportWebVitals code
reportWebVitals();
