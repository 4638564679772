import React from 'react'
import msme from '../../assets/msme.png'
import mca from '../../assets/mca.png'
import aicte from '../../assets/aicte.png'
import startUp from '../../assets/startupind.png'

const SocialProof = () => {
  return (
    <div className='flex mx-10 md:mx-14 lg:mx-24 flex-col'>
      <div className='w-full md:w-1/2 my-auto '>
      <div className='flex  my-2 '> 
        <div className='w-1/4 h-0.5 bg-gradient-to-r from-blue-200 to-blue-600 my-auto'></div> 
        <span className='uppercase text-blue-700 mx-3 font-semibold'>The new standard</span>
      </div>
        <h1 className=' py-2 text-xl md:text-2xl lg:text-4xl font-semibold '>Trusted by some awesome companies</h1>
      </div>
      <div className='flex flex-wrap h-fit w-full py-10 px-3 md:px-8 gap-x-12 gap-5 md:gap-x-24 justify-center items-center'>
        <img src={msme} alt='msme logo' />  
        <img src={mca} alt='msme logo' />
        <img src={startUp} alt='msme logo' />
        <img src={aicte} alt='msme logo' />
      </div>



    </div>
  )
}

export default SocialProof
