import React from "react";

interface ConsoleInterface {
  code?: string;
  language?: number;
  classId?: string;
  hasAccess?: boolean;
  role?: string;
  output: string;
  clearConsoleHandler: () => void;
}

const Console: React.FC<ConsoleInterface> = ({
  hasAccess,
  output,
  clearConsoleHandler,
  role,
}) => {
  return (
    <div className="flex flex-col w-full h-full">
      <div className="py-2 px-4 rounded-t-3xl border-t-2 shadow-lg w-full flex justify-between itsms-center">
        <div className="font-bold text-lg py-2 text-customLightPurple">
          {`${role} Console {}`}
        </div>
        {hasAccess && (
          <button
            onClick={clearConsoleHandler}
            className="bg-customLightPurple hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            clear
          </button>
        )}
      </div>
      <div className="bg-gray-800 text-white w-full h-full rounded p-4">
        <strong className="font-semibold">Output:</strong>
        <pre className="bg-gray-700 rounded p-2 mt-2 text-xs font-mono">
          {output}
        </pre>
      </div>
    </div>
  );
};

export default Console;
