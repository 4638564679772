import React from "react";

const NoAccess: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen px-4 md:px-8 lg:px-16">
      <h1 className="text-2xl md:text-4xl font-bold mb-4">Access Denied</h1>
      <p className="text-lg md:text-xl text-center">
        You do not have permission to view this page.
      </p>
    </div>
  );
};

export default NoAccess;
