import React from "react";

type SubTopic = {
  Id: number;
  title: string;
  description: React.ReactNode;
};

type Chapter = {
  id: number;
  title: string;
  subTopics: SubTopic[];
};

type Course = {
  courseName: string;
  courseId: string;
  description: string;
  chapters: Chapter[];
};

export const Reading_Material: Course[] = [
  {
    courseName: "Java",
    courseId: "java",
    description:
      "Explore Java from basics to advanced with our concise reading material. Elevate your coding skills effortlessly!",
    chapters: [
      {
        id: 1,
        title: "Introduction to Java and Programming",
        subTopics: [
          {
            Id: 1,
            title: "What is JAVA?",
            description: `Java is a robust, high-level programming language developed by James Gosling at Sun Microsystems in 1995. Java's philosophy—Write Once, Run Anywhere (WORA)—revolutionized the software industry by ensuring that Java code, once compiled, can run on any platform that supports Java without the need for recompilation. This cross-platform compatibility is facilitated by the Java Virtual Machine (JVM), a pivotal component of the Java Runtime Environment (JRE).\n

                The JVM is an abstract computing machine that enables a computer to run a Java program. It works by converting Java bytecode into machine code, allowing Java applications to run on any device or operating system. The JRE provides the libraries, Java APIs, and runtime environment necessary for executing Java applications. However, to develop Java applications, developers need the Java Development Kit (JDK), which includes the JRE and development tools like compilers and debuggers.\n
                
                Java's features include object-oriented principles, which promote cleaner, more modular code; garbage collection, which automates memory management; and a vast standard library that developers can leverage to build complex applications efficiently. Additionally, Java's network-centric design made it an integral player in the rise of the internet, providing a secure platform for web and enterprise applications.`,
          },
          {
            Id: 2,
            title: "Setting Up the Environment",
            description: `
                <h2 className="text-4xl"><strong> Setting Up the Environment</strong></h2>
                <p>Setting up a proper Java development environment is the foundational step for any Java programmer. This setup involves installing the Java Development Kit (JDK), configuring the environment variables, and choosing an Integrated Development Environment (IDE) that suits your preferences. Let's delve into each of these steps:</p>
                
                <h3><strong>Installing JDK</strong></h3>
                <p>The JDK is a software development environment used for developing Java applications and applets. It includes the Java Runtime Environment (JRE), an interpreter/loader (Java), a compiler (javac), an archiver (jar), a documentation generator (Javadoc), and other tools needed in Java development.</p>
                <ol>
                    <li><strong>Download JDK:</strong> Begin by visiting the official Oracle website and downloading the latest version of JDK compatible with your operating system.</li>
                    <li><strong>Installation Process:</strong> Run the downloaded installer, which guides you through the installation process. Accept the license agreement, select a destination folder, and proceed with the installation.</li>
                </ol></br>
                
                <h3><strong>Configuring Environment Variables</strong></h3>
                <p>Environment variables are system-wide values used by the operating system to allow certain programs to know where to find directories and files. Setting them up is crucial for Java to function properly on your system.</p>
                <ol>
                    <li><strong>JAVA_HOME:</strong> Create a new environment variable named <code>JAVA_HOME</code> and set it to the JDK installation path. This variable points to the JDK directory and is used by various Java tools.</li>
                    </br><li><strong>Path Variable:</strong> Add the JDK's <code>bin</code> directory to the system's <code>Path</code> variable. This ensures that Java commands like <code>java</code> and <code>javac</code> can be run from any directory without having to specify their full path.</li>
                </ol></br>
                
                <h3><strong>IDE Setup</strong></h3>
                <p>An Integrated Development Environment (IDE) is a software suite that consolidates basic tools required to write and test software. While Java code can be written in simple text editors, an IDE provides additional features like syntax highlighting, code completion, and debugging tools.</p>
                <ol>
                    <li><strong>Choosing an IDE:</strong> Popular Java IDEs include Eclipse, IntelliJ IDEA, and NetBeans. Each has its own set of features and user preferences vary.</li>
                    </br><li><strong>Downloading and Installing:</strong> Once you've chosen an IDE, download it from the official website and follow the installation instructions.</li>
                    </br><li><strong>IDE Configuration:</strong> After installation, you may need to configure the IDE by specifying the <code>JAVA_HOME</code> directory and fine-tuning settings to suit your coding style.</li>
                    </br><li><strong>Create Your First Project:</strong> Most IDEs offer a wizard to create new Java projects. This helps in setting up the project structure, including source and library directories.</li>
                </ol></br>
                
                <p>Once the JDK is installed, environment variables configured, and the IDE is set up, you're ready to start developing in Java. This environment allows for writing, compiling, and executing Java programs, providing a robust platform for beginners and professionals alike to develop their Java skills.</p>
            `,
          },
          {
            Id: 3,
            title: "Your First Java Program",
            description: `<h2><strong>Structure of a Java Program</strong></h2>
                <p>The structure of a Java program refers to the way in which its various parts are organized and put together. A typical Java program consists of a class declaration, a main method, and a set of statements that are executed when the program runs.</p>
                </br>
                <h2><strong>Writing and Running "Hello, World!</strong></h2>
                <p>The 'Hello, World!' program is a simple Java program that outputs 'Hello, World!' to the console. It is often used as an introductory program for beginners to understand the basic structure and syntax of Java.</p>
                </br>
                <h3><strong>Steps to Write and Run 'Hello, World!':</strong></h3>
                <ul>
                    <li> 1. Create a file named 'HelloWorld.java'</li>
                    </br><li>2. Write the code for the 'Hello, World!' program.</li>
                    </br><li>3. Compile the program using a Java compiler.</li>
                    </br><li>4. Run the compiled program to see the 'Hello, World!' output.</li>
                </ul>
                <br>
                
                <h3><strong>Example Code:</strong></h3>
                <pre>
                    <code>
            public class HelloWorld {
                public static void main(String[] args) {
                    System.out.println("Hello, World!");
                }
            }
                    </code>
                </pre>`,
          },
          {
            Id: 4,
            title: "Basic Syntax and Conventions ",
            description: `    <h2 id="commentsKeywordsIdentifiers"><strong>Comments, Keywords, Identifiers</strong></h2>
                <p>In Java, understanding comments, keywords, and identifiers is fundamental to writing clear and effective code. Each serves a unique purpose in the language.</p>
                <br>
                
                <h3><strong>Comments</strong></h3>
                <p>Comments are non-executable lines that programmers use to annotate their code. Java supports both single-line comments (denoted by //) and multi-line comments (enclosed between /* and */).</p>
                <p><em>Example:</em> <code>// This is a single-line comment</code></p>
                <p><em>Example:</em> <code>/* This is a multi-line comment that can span multiple lines. */</code></p>
                <br>
                
                <h3><strong>Keywords</strong></h3>
                <p>Keywords are reserved words in Java that have a special significance. Keywords cannot be used as identifiers for variables, methods, or other entities in your code.</p>
                <p><em>Example:</em> <code>class, public, static, void</code></p>
                <br>
                
                <h3><strong>Identifiers</strong></h3>
                <p>Identifiers are names that programmers give to variables, methods, classes, and other entities in their code.</p>
                <p><em>Example:</em> <code>int myVariable;</code></p>
                <p><em>Example:</em> <code>class MyClass { }</code></p>
                <br>
                
                <ul>
                    <li>Choosing meaningful identifiers makes your code self-explanatory and enhances its readability.</li>
                </ul>
                <br>
            
                <h2 id="variablesDataTypes"><strong>Variables and Data Types</strong></h2>
                <p>Variables are containers for storing data values. In Java, each variable must be declared with a data type.</p>
                <br>
                
                <h3><strong>Primitive Data Types</strong></h3>
                <p>Primitive data types in Java include byte, short, int, long, float, double, char, and boolean.</p>
                <p><em>Example:</em> <code>int number = 5;</code></p>
                <p><em>Example:</em> <code>boolean isJavaFun = true;</code></p>
                <br>
                
                <h3><strong>Non-Primitive Data Types</strong></h3>
                <p>Non-primitive data types, or reference types, refer to objects and arrays.</p>
                <p><em>Example:</em> <code>String greeting = "Hello!";</code></p>
                <p><em>Example:</em> <code>int[] numbers = {1, 2, 3, 4, 5};</code></p>
                <br>
            
                <p>Understanding the basic syntax and conventions in Java is crucial for writing efficient and error-free programs. Mastering the use of comments, keywords, identifiers, and data types lays the foundation for more advanced programming concepts and techniques.</p>
                <br>`,
          },
        ],
      },
      {
        id: 2,
        title: "Control Flow and Methods",
        subTopics: [
          {
            Id: 1,
            title: "Operators and Expressions",
            description: `
                <br>
            
                <p>In Java, operators are special symbols that perform specific operations on one, two, or three operands, and then return a result. An expression is a combination of variables, operators, and values that yields a result.</p>
                <br>
            
                <h2><strong>Types of Operators</strong></h2>
                <p>Java provides a rich set of operators categorized into several types:</p>
                <br>
                
                <h3><strong>Arithmetic Operators</strong></h3>
                <p>Arithmetic operators are used to perform basic mathematical operations like addition, subtraction, multiplication, and division.</p>
                <p><em>Example:</em> <code>int sum = 10 + 5; // Adds 10 and 5</code></p>
                <p><em>Example:</em> <code>int result = 20 / 5; // Divides 20 by 5</code></p>
                <br>
                
                <h3><strong>Relational Operators</strong></h3>
                <p>Relational operators are used to compare two values and return a boolean result.</p>
                <p><em>Example:</em> <code>boolean isEqual = (10 == 10); // Compares for equality</code></p>
                <p><em>Example:</em> <code>boolean isGreater = (20 > 10); // Checks if 20 is greater than 10</code></p>
                <br>
                
                <h3><strong>Logical Operators</strong></h3>
                <p>Logical operators are used to perform logical "AND", "OR", and "NOT" operations.</p>
                <p><em>Example:</em> <code>boolean andResult = (true && false); // Logical AND</code></p>
                <p><em>Example:</em> <code>boolean orResult = (true || false); // Logical OR</code></p>
                <br>
                
                <h3><strong>Assignment Operators</strong></h3>
                <p>Assignment operators are used to assign values to variables.</p>
                <p><em>Example:</em> <code>int x = 10; // Assigns 10 to x</code></p>
                <p><em>Example:</em> <code>x += 5; // Adds 5 to x</code></p>
                <br>
            
                <h3><strong>Unary Operators</strong></h3>
                <p>Unary operators require only one operand. They perform various operations such as incrementing/decrementing a value by one, negating an expression, or inverting the value of a boolean.</p>
                <p><em>Example:</em> <code>x++; // Increments x by 1</code></p>
                <p><em>Example:</em> <code>boolean isFalse = !true; // Inverts the value of true</code></p>
                <br>
            
                <h2><strong>Expressions</strong></h2>
                <p>Expressions are constructed from variables, operators, and method calls. Expressions are essential for writing statements and evaluating values within the program.</p>
                <p><em>Example:</em> <code>int area = length * width; // An expression to calculate area</code></p>
                <br>
            
                <p>Understanding operators and expressions in Java is vital for performing various types of calculations and logic in your programs. They are the building blocks of any decision-making or computational process in your code.</p>
                <br>`,
          },
          {
            Id: 2,
            title: "Control flow statements",
            description: `  <br>

                <p>Control flow statements are crucial in Java for dictating the flow of execution based on certain conditions or loops. They are the backbone of decision making and repetitive task execution in the program.</p>
                <br>
            
                <h2><strong>Conditionals</strong></h2>
                <p>Conditional statements allow the program to take different paths based on conditions.</p>
                <br>
                
                <h3><strong>if Statement</strong></h3>
                <p>The 'if' statement executes a block of code if a specified condition is true.</p>
                <p><em>Example:</em> <code>if (score > 50) { System.out.println("You passed!"); }</code></p>
                <p>If the score is greater than 50, the program will output "You passed!".</p>
                <br>
                
                <h3><strong>switch Statement</strong></h3>
                <p>The 'switch' statement allows for the easy dispatch of execution to different parts of code based on the value of an expression.</p>
                <p><em>Example:</em>
                <code>
                switch (grade) {<br>
                &nbsp;&nbsp;case 'A':<br>
                &nbsp;&nbsp;&nbsp;&nbsp;System.out.println("Excellent!");<br>
                &nbsp;&nbsp;&nbsp;&nbsp;break;<br>
                &nbsp;&nbsp;case 'B':<br>
                &nbsp;&nbsp;&nbsp;&nbsp;System.out.println("Good job!");<br>
                &nbsp;&nbsp;&nbsp;&nbsp;break;<br>
                &nbsp;&nbsp;default:<br>
                &nbsp;&nbsp;&nbsp;&nbsp;System.out.println("Grade not recognized");<br>
                }
                </code></p>
                <p>This switch statement will output a message based on the value of 'grade'.</p>
                <br>
            
                <h2><strong>Loops</strong></h2>
                <p>Loop statements allow for the repeated execution of a block of code as long as a condition remains true.</p>
                <br>
                
                <h3><strong>for Loop</strong></h3>
                <p>The 'for' loop is used to iterate over a range of values or arrays.</p>
                <p><em>Example:</em> <code>for (int i = 0; i < 5; i++) { System.out.println(i); }</code></p>
                <p>This loop will print the numbers 0 to 4 in the console.</p>
                <br>
                
                <h3><strong>while Loop</strong></h3>
                <p>The 'while' loop executes a block of code as long as the specified condition is true.</p>
                <p><em>Example:</em> <code>while (count < 5) { System.out.println(count); count++; }</code></p>
                <p>This loop will print numbers from 0 to 4 as long as 'count' is less than 5.</p>
                <br>
                
                <h3><strong>do-while Loop</strong></h3>
                <p>The 'do-while' loop is similar to the 'while' loop but guarantees that the block of code is executed at least once.</p>
                <p><em>Example:</em> <code>do { System.out.println(count); count++; } while (count < 5);</code></p>
                <p>This loop will print numbers from 0 to 4, executing at least once before checking the condition.</p>
                <br>
            
                <p>Understanding control flow statements is fundamental for creating dynamic and responsive Java applications. Conditionals and loops provide the mechanisms to control the execution flow based on logic and conditions, enabling programmers to write more efficient and powerful code.</p>
                <br>`,
          },
          {
            Id: 3,
            title: "Methods in Java",
            description: `<br>

                <p>Methods in Java are blocks of code that perform specific tasks and can be called from other parts of a program. They help in code reusability, modularity, and organization of complex tasks into simpler, manageable ones.</p>
                <br>
            
                <h2><strong>Defining and Calling Methods</strong></h2>
                <p>A method is defined with a name, return type, and parameters. It encapsulates a set of instructions that can be executed when the method is called.</p>
                <br>
                
                <p><em>Example of Defining a Method:</em></p>
                <p><code>public int addNumbers(int num1, int num2) {<br>
                &nbsp;&nbsp;return num1 + num2;<br>
                }</code></p>
                <p>This method 'addNumbers' takes two integer parameters and returns their sum.</p>
                <br>
            
                <p><em>Example of Calling a Method:</em></p>
                <p><code>int result = addNumbers(10, 20);<br>
                System.out.println(result);</code></p>
                <p>This code calls the 'addNumbers' method with arguments 10 and 20 and prints the result (30).</p>
                <br>
            
                <h2><strong>Method Parameters and Return Types</strong></h2>
                <p>Methods can take parameters, which are variables passed into the method, and return a value after executing the code block.</p>
                <br>
            
                <h3><strong>Parameters</strong></h3>
                <p>Parameters allow methods to accept inputs and perform operations based on those inputs.</p>
                <p><em>Example:</em> <code>public void greet(String name) {<br>
                &nbsp;&nbsp;System.out.println("Hello, " + name);<br>
                }</code></p>
                <p>This method 'greet' takes a String parameter 'name' and prints a greeting message.</p>
                <br>
                
                <h3><strong>Return Types</strong></h3>
                <p>Return types specify the type of value a method returns. If a method doesn't return any value, its return type is 'void'.</p>
                <p><em>Example:</em> <code>public boolean isEven(int number) {<br>
                &nbsp;&nbsp;return number % 2 == 0;<br>
                }</code></p>
                <p>This method 'isEven' returns a boolean value indicating whether the provided number is even or not.</p>
                <br>
            
                <p>Mastering methods in Java is critical for building scalable and maintainable applications. They provide a way to encapsulate code functionality, promote code reuse, and simplify complex operations into manageable segments.</p>
                <br>`,
          },
          {
            Id: 4,
            title: "Overloading and Recursion",
            description: ` <br>

                <h2><strong>Method Overloading</strong></h2>
                <p>Method overloading occurs when two or more methods in a class have the same name but different parameters. It increases the program's readability and reusability.</p>
                <br>
                
                <p><em>Example:</em></p>
                <p><code>public int multiply(int a, int b) {<br>
                &nbsp;&nbsp;return a * b;<br>
                }<br>
                public int multiply(int a, int b, int c) {<br>
                &nbsp;&nbsp;return a * b * c;<br>
                }</code></p>
                <p>Both methods are named 'multiply' but have different parameters.</p>
                <br>
            
                <h2><strong>Method Overriding</strong></h2>
                <p>Method overriding occurs when a subclass provides a specific implementation of a method already provided by one of its superclasses.</p>
                <br>
                
                <p><em>Example:</em></p>
                <p><code>class Animal {<br>
                &nbsp;&nbsp;void sound() {<br>
                &nbsp;&nbsp;&nbsp;&nbsp;System.out.println("Animal makes a sound");<br>
                &nbsp;&nbsp;}<br>
                }<br>
                class Dog extends Animal {<br>
                &nbsp;&nbsp;void sound() {<br>
                &nbsp;&nbsp;&nbsp;&nbsp;System.out.println("Dog barks");<br>
                &nbsp;&nbsp;}<br>
                }</code></p>
                <p>The 'Dog' class overrides the 'sound' method of its superclass 'Animal'.</p>
                <br>
            
                <h2><strong>Overloading vs Overriding</strong></h2>
                <p>Overloading happens within the same class, whereas overriding involves two classes that have an IS-A (inheritance) relationship. Overloading is about having the same method name with different parameters, while overriding is about providing a specific implementation in the subclass for a method already defined in the superclass.</p>
                <br>
            
                <h2><strong>Basic Recursion Concepts</strong></h2>
                <p>Recursion is a programming technique where a method calls itself to solve a problem. It's essential for solving complex problems that can be broken down into simpler, identical subproblems.</p>
                <br>
                
                <p><em>Example:</em></p>
                <p><code>public int factorial(int n) {<br>
                &nbsp;&nbsp;if (n == 0) return 1;<br>
                &nbsp;&nbsp;return n * factorial(n - 1);<br>
                }</code></p>
                <p>This 'factorial' method calls itself to calculate the factorial of a number.</p>
                <br>
            
                <p>Understanding method overloading, overriding, and recursion is essential for Java programmers. These concepts allow for flexible method invocation, better code organization, and solving complex problems efficiently.</p>
                <br>`,
          },
        ],
      },
      {
        id: 3,
        title: "Object-Oriented Programming Basics",
        subTopics: [
          {
            Id: 1,
            title: "Introduction to OOP",
            description: `<br>

                <h2><strong>Introduction to OOP</strong></h2>
                <p>Object-Oriented Programming (OOP) is a programming paradigm based on the concept of "objects", which can contain data, in the form of fields, often known as attributes; and code, in the form of procedures, often known as methods.</p>
                <p>OOP focuses on the objects that developers want to manipulate rather than the logic required to manipulate them. This approach to programming is well-suited for programs that are large, complex, and actively updated or maintained.</p>
                <br>
            
                <h2><strong>Creating Classes and Objects</strong></h2>
                <p>In Java, a class is a blueprint for creating objects. A class encapsulates the data for the object and methods to manipulate that data.</p>
                <br>
                
                <p><em>Example of Creating a Class:</em></p>
                <p><code>public class Car {<br>
                &nbsp;&nbsp;String brand;<br>
                &nbsp;&nbsp;int year;<br>
                &nbsp;&nbsp;void display() {<br>
                &nbsp;&nbsp;&nbsp;&nbsp;System.out.println("Brand: " + brand + ", Year: " + year);<br>
                &nbsp;&nbsp;}<br>
                }</code></p>
                <p>This 'Car' class has two fields (brand and year) and a method 'display' to show the car's details.</p>
                <br>
            
                <p><em>Example of Creating an Object:</em></p>
                <p><code>Car myCar = new Car();<br>
                myCar.brand = "Tesla";<br>
                myCar.year = 2020;<br>
                myCar.display();</code></p>
                <p>This code creates an object 'myCar' of the class 'Car' and initializes its fields before calling its 'display' method.</p>
                <br>
            
                <p>Understanding objects and classes is fundamental in Java, as it is an object-oriented language. Mastering these concepts allows developers to create modular, reusable, and manageable code.</p>
                <br>`,
          },
          {
            Id: 2,
            title: "Class Members and Constructors",
            description: `<br>

                <h2><strong>Fields, Methods, Constructors</strong></h2>
                <p>Fields (also known as attributes or properties) are the variables within a class that define the state of an object. Methods are the behaviors or functionalities associated with objects of the class. Constructors are special methods used to initialize objects.</p>
                <br>
                
                <p><em>Example:</em></p>
                <p><code>public class Book {<br>
                &nbsp;&nbsp;String title; // Field<br>
                &nbsp;&nbsp;String author; // Field<br>
                <br>
                &nbsp;&nbsp;public Book(String title, String author) { // Constructor<br>
                &nbsp;&nbsp;&nbsp;&nbsp;this.title = title;<br>
                &nbsp;&nbsp;&nbsp;&nbsp;this.author = author;<br>
                &nbsp;&nbsp;}<br>
                <br>
                &nbsp;&nbsp;public void displayInfo() { // Method<br>
                &nbsp;&nbsp;&nbsp;&nbsp;System.out.println("Book: " + title + ", Author: " + author);<br>
                &nbsp;&nbsp;}<br>
                }</code></p>
                <p>In this 'Book' class, 'title' and 'author' are fields, 'Book' is the constructor, and 'displayInfo' is a method.</p>
                <br>
            
                <h2><strong>this Keyword</strong></h2>
                <p>The 'this' keyword is a reference to the current object. It is commonly used in constructors and methods to refer to the current object's fields and methods.</p>
                <br>
                
                <p><em>Usage Example:</em></p>
                <p><code>public class Point {<br>
                &nbsp;&nbsp;int x, y;<br>
                <br>
                &nbsp;&nbsp;public Point(int x, int y) {<br>
                &nbsp;&nbsp;&nbsp;&nbsp;this.x = x; // this.x refers to the field x<br>
                &nbsp;&nbsp;&nbsp;&nbsp;this.y = y; // this.y refers to the field y<br>
                &nbsp;&nbsp;}<br>
                }</code></p>
                <p>This 'Point' class uses the 'this' keyword to differentiate the fields from the parameters with the same name.</p>
                <br>
            
                <p>Understanding class members, constructors, and the 'this' keyword is vital for Java developers to create well-structured and easily maintainable code.</p>
                <br>`,
          },
          {
            Id: 3,
            title: "Encapsulation and Access Modifiers",
            description: `<br>

            <h2><strong>Public, Private, Protected, Default</strong></h2>
            <p>Access modifiers in Java control the visibility of class members (fields, methods, constructors, etc.).</p>
            <ul>
                <li><strong>Public:</strong> Members are accessible from any other class.</li>
               <br><li><strong>Private:</strong> Members are accessible only within the declared class itself.</li>
               <br><li><strong>Protected:</strong> Members are accessible within the same package or subclasses in different packages.</li>
                <br><li><strong>Default:</strong> (no modifier) Members are accessible only within the same package.</li>
            </ul>
            <br>
        
            <h2><strong>Getters and Setters</strong></h2>
            <p>Getters and setters are methods used to access and modify private fields, adhering to the principle of encapsulation.</p>
            <br>
            
            <p><em>Example:</em></p>
            <p><code>public class Student {<br>
            &nbsp;&nbsp;private String name; // Private field<br>
            <br>
            &nbsp;&nbsp;public String getName() { // Getter<br>
            &nbsp;&nbsp;&nbsp;&nbsp;return name;<br>
            &nbsp;&nbsp;}<br>
            <br>
            &nbsp;&nbsp;public void setName(String name) { // Setter<br>
            &nbsp;&nbsp;&nbsp;&nbsp;this.name = name;<br>
            &nbsp;&nbsp;}<br>
            }</code></p>
            <p>The 'Student' class has a private field 'name' with its getter and setter methods, allowing controlled access to the field.</p>
            <br>
        
            <p>Encapsulation and the proper use of access modifiers ensure a better level of data protection and increased flexibility of the code.</p>
            <br>`,
          },
          {
            Id: 4,
            title: "Static Context",
            description: `
            <br>
        
            <h2><strong>Static Variables and Methods</strong></h2>
            <p>Static variables and methods belong to the class rather than any individual instance. They can be accessed directly by the class name without needing an object.</p>
            <br>
        
            <p><em>Example of Static Variable and Method:</em></p>
            <p><code>public class Calculator {<br>
            &nbsp;&nbsp;public static int add(int a, int b) {<br>
            &nbsp;&nbsp;&nbsp;&nbsp;return a + b;<br>
            &nbsp;&nbsp;}<br>
            }<br>
            <br>
            // Accessing static method<br>
            int result = Calculator.add(5, 10);</code></p>
            <p>In this example, the 'add' method is static and can be accessed directly using the class name 'Calculator'.</p>
            <br>
        
            <h2><strong>Static Blocks and Classes</strong></h2>
            <p>Static blocks are used for static initializations of a class. They are executed when the class is first loaded into the JVM. Static nested classes are nested classes that are declared static and can be accessed without an outer class instance.</p>
            <br>
            
            <p><em>Example of Static Block:</em></p>
            <p><code>public class DatabaseConnector {<br>
            &nbsp;&nbsp;static {<br>
            &nbsp;&nbsp;&nbsp;&nbsp;// Static block for initial setup<br>
            &nbsp;&nbsp;&nbsp;&nbsp;initializeConnection();<br>
            &nbsp;&nbsp;}<br>
            <br>
            &nbsp;&nbsp;private static void initializeConnection() {<br>
            &nbsp;&nbsp;&nbsp;&nbsp;// Connection setup code<br>
            &nbsp;&nbsp;}<br>
            }</code></p>
            <p>This static block in the 'DatabaseConnector' class is used for initializing a database connection when the class is first loaded.</p>
            <br>
        
            <p><em>Example of Static Nested Class:</em></p>
            <p><code>public class OuterClass {<br>
            &nbsp;&nbsp;static class NestedStaticClass {<br>
            &nbsp;&nbsp;&nbsp;&nbsp;// Static nested class<br>
            &nbsp;&nbsp;}<br>
            }<br>
            <br>
            // Accessing static nested class<br>
            OuterClass.NestedStaticClass nestedObject = new OuterClass.NestedStaticClass();</code></p>
            <p>The 'NestedStaticClass' is a static nested class and can be accessed without an instance of 'OuterClass'.</p>
            <br>
        
            <p>Understanding static context in Java is essential for managing class-wide states and behaviors effectively.</p>
            <br>
        `,
          },
        ],
      },
      {
        id: 4,
        title: "Digging Deeper into Object-Oriented Programming",
        subTopics: [
          {
            Id: 1,
            title: "Inheritence",
            description: `<br>

               <h2> <strong>Understanding Inheritence</strong></h2>
                <br><p>Inheritance is a cornerstone of object-oriented programming (OOP) that allows a new class, known as the derived or child class, to inherit properties and behaviors from an existing class, the base or parent class. This mechanism promotes code reusability and extensibility by enabling derived classes to use or modify existing functionalities of base classes. Inheritance facilitates the creation of a hierarchical class structure that reflects real-world relationships and promotes the efficient organization and maintenance of code by adhering to the DRY (Don't Repeat Yourself) principle. There are various types of inheritance, including single, multiple, multilevel, hierarchical, and hybrid, each allowing for different relationships between classes.</p>
                <br><h2><strong>Extending Classes</strong></h2>
                <p>Inheritance allows a class to inherit fields and methods from another class. In Java, inheritance is achieved using the 'extends' keyword. The class which inherits is called a subclass, and the class from which it inherits is called a superclass.</p>
                <br>
            
                <p><em>Example of Extending Classes:</em></p>
                <p><code>public class Animal {<br>
                &nbsp;&nbsp;public void eat() {<br>
                &nbsp;&nbsp;&nbsp;&nbsp;System.out.println("Eating...");<br>
                &nbsp;&nbsp;}<br>
                }<br>
                <br>
                public class Dog extends Animal {<br>
                &nbsp;&nbsp;public void bark() {<br>
                &nbsp;&nbsp;&nbsp;&nbsp;System.out.println("Barking...");<br>
                &nbsp;&nbsp;}<br>
                }</code></p>
                <p>Here, 'Dog' is a subclass of 'Animal' and inherits the 'eat' method from the 'Animal' superclass.</p>
                <br>
            
                <h2><strong>Method Overriding</strong></h2>
                <p>Method overriding occurs when a subclass provides a specific implementation for a method already defined in its superclass. This is used to give a subclass a distinct behavior.</p>
                <br>
            
                <p><em>Example of Method Overriding:</em></p>
                <p><code>public class Animal {<br>
                &nbsp;&nbsp;public void sound() {<br>
                &nbsp;&nbsp;&nbsp;&nbsp;System.out.println("Generic sound");<br>
                &nbsp;&nbsp;}<br>
                }<br>
                <br>
                public class Dog extends Animal {<br>
                &nbsp;&nbsp;@Override<br>
                &nbsp;&nbsp;public void sound() {<br>
                &nbsp;&nbsp;&nbsp;&nbsp;System.out.println("Woof woof");<br>
                &nbsp;&nbsp;}<br>
                }</code></p>
                <p>In this example, the 'Dog' class overrides the 'sound' method from the 'Animal' superclass.</p>
                <br>
            
                <p>Inheritance promotes code reuse and establishes a natural hierarchy between classes, making it a cornerstone of object-oriented programming.</p>
                <br>
            `,
          },
          {
            Id: 2,
            title: "Abstract Classes and Interfaces",
            description: `  <br>

                <h2><strong>Defining Abstract Classes</strong></h2>
                <p>Abstract classes in Java are classes that cannot be instantiated on their own and are declared with the 'abstract' keyword. They can have abstract methods (without an implementation) and non-abstract methods. Abstract classes are meant to be subclassed.</p>
                <br>
            
                <p><em>Example of an Abstract Class:</em></p>
                <p><code>public abstract class Shape {<br>
                &nbsp;&nbsp;public abstract void draw(); // Abstract method<br>
                <br>
                &nbsp;&nbsp;public void display() { // Non-abstract method<br>
                &nbsp;&nbsp;&nbsp;&nbsp;System.out.println("Displaying shape");<br>
                &nbsp;&nbsp;}<br>
                }</code></p>
                <br>
            
                <h2><strong>Implementing Interfaces</strong></h2>
                <p>Interfaces in Java are abstract types that allow the declaration of methods without implementations. Classes implement interfaces to provide the implementation of these methods. Interfaces are declared using the 'interface' keyword.</p>
                <br>
            
                <p><em>Example of an Interface and Its Implementation:</em></p>
                <p><code>public interface Drawable {<br>
                &nbsp;&nbsp;void draw(); // Abstract method<br>
                }<br>
                <br>
                public class Circle implements Drawable {<br>
                &nbsp;&nbsp;@Override<br>
                &nbsp;&nbsp;public void draw() {<br>
                &nbsp;&nbsp;&nbsp;&nbsp;System.out.println("Drawing a circle");<br>
                &nbsp;&nbsp;}<br>
                }</code></p>
                <br>
            
                <p>Abstract classes and interfaces are powerful OOP features in Java that promote abstraction and provide a way to achieve loose coupling in design.</p>
                <br>
            `,
          },
          {
            Id: 3,
            title: "Polymorphism",
            description: `<br>

                <h2><strong>Understanding Polymorphism</strong></h2>
                <p>Polymorphism is a fundamental concept in object-oriented programming (OOP) that allows objects to be treated as instances of their parent class rather than their actual class. The term 'polymorphism' originates from the Greek words 'poly' (many) and 'morph' (form), meaning many forms. In Java, polymorphism enables a single interface to be used for a general class of actions. The specific action is determined by the exact nature of the situation.</p>
                <br>
            
                <p>There are two main types of polymorphism in Java:</p>
                <ul>
                    <li><strong>Compile-time Polymorphism</strong>: This is achieved through method overloading and operator overloading.</li>
                    <li><strong>Runtime Polymorphism</strong>: This is implemented through method overriding and is achieved when a subclass overrides a method from its superclass.</li>
                </ul>
                <br>
            
                <p><em>Example of Polymorphism:</em></p>
                <p><code>class Animal {<br>
                &nbsp;&nbsp;void makeSound() {<br>
                &nbsp;&nbsp;&nbsp;&nbsp;System.out.println("Some sound");<br>
                &nbsp;&nbsp;}<br>
                }<br>
                <br>
                class Dog extends Animal {<br>
                &nbsp;&nbsp;void makeSound() {<br>
                &nbsp;&nbsp;&nbsp;&nbsp;System.out.println("Bark");<br>
                &nbsp;&nbsp;}<br>
                }<br>
                <br>
                public class TestPolymorphism {<br>
                &nbsp;&nbsp;public static void main(String[] args) {<br>
                &nbsp;&nbsp;&nbsp;&nbsp;Animal myAnimal = new Dog();<br>
                &nbsp;&nbsp;&nbsp;&nbsp;myAnimal.makeSound(); // Outputs "Bark"<br>
                &nbsp;&nbsp;}<br>
                }</code></p>
                <br>
            
                <h2><strong>Dynamic Method Dispatch</strong></h2>
                <p>Dynamic Method Dispatch is a mechanism by which a call to an overridden method is resolved at runtime rather than compile-time. This is a key aspect of runtime polymorphism. When an overridden method is called through a superclass reference, Java determines which version of the method to execute based on the type of the object instance.</p>
                <br>
            
                <p>The example provided above for polymorphism also demonstrates Dynamic Method Dispatch. The call to <code>myAnimal.makeSound()</code> is resolved at runtime. Although the reference type is <code>Animal</code>, the object being referred to is of type <code>Dog</code>. Therefore, the <code>makeSound()</code> method of the <code>Dog</code> class is called.</p>
                <br>
            
                <p>Polymorphism and Dynamic Method Dispatch are key concepts that contribute to the flexibility and extensibility of Java programs. They allow for the development of scalable and maintainable code, enabling developers to write more generic and abstract code that can handle various data types and behaviors.</p>
                <br>`,
          },
          {
            Id: 4,
            title: "Packages and Enum",
            description: `<br>

                <h2><strong>Organizing Classes into Packages</strong></h2>
                <p>In Java, a package is a namespace that organizes a set of related classes and interfaces. Conceptually you can think of packages as being similar to different folders on your computer. When Java programs are organized into packages, it becomes easier to manage the code, and it helps avoid name conflicts among classes. Packages can be considered as data encapsulation (or data-hiding).</p>
                <br>
            
                <p>Every class is part of some package. If no package is specified, the classes are placed into the default package. To create a package, use the 'package' keyword:</p>
                <p><em>Example:</em></p>
                <p><code>// File Name: Sweet.java<br>
                package dessert;<br>
                <br>
                public class Sweet {<br>
                &nbsp;&nbsp;// ...<br>
                }</code></p>
                <br>
            
                <p>To use a public class or interface from another package, either refer to them by their full name:</p>
                <p><code>dessert.Sweet myDessert = new dessert.Sweet();</code></p>
                <p>Or, use the 'import' keyword:</p>
                <p><code>import dessert.Sweet;<br>
                ...<br>
                Sweet myDessert = new Sweet();</code></p>
                <br>
            
                <h2><strong>Using Enumerations</strong></h2>
                <p>Enumerations (enums) are a feature of Java that allows the programmer to define a fixed set of constants. Enums restrict a variable to have one of only a few predefined values. The values in this enumerated list are called enums. With the use of enums, it is possible to reduce the number of bugs in your code.</p>
                <br>
            
                <p>For example, if we consider an application for a fresh juice shop, it would be possible to restrict the glass size to small, medium, and large. This would ensure that it would not allow anyone to order any size other than small, medium, or large.</p>
                <br>
            
                <p><em>Example:</em></p>
                <p><code>class FreshJuice {<br>
                &nbsp;&nbsp;enum FreshJuiceSize{ SMALL, MEDIUM, LARGE }<br>
                &nbsp;&nbsp;FreshJuiceSize size;<br>
                }<br>
                <br>
                public class FreshJuiceTest {<br>
                &nbsp;&nbsp;public static void main(String args[]) {<br>
                &nbsp;&nbsp;&nbsp;&nbsp;FreshJuice juice = new FreshJuice();<br>
                &nbsp;&nbsp;&nbsp;&nbsp;juice.size = FreshJuice.FreshJuiceSize.MEDIUM ;<br>
                &nbsp;&nbsp;&nbsp;&nbsp;System.out.println("Size: " + juice.size);<br>
                &nbsp;&nbsp;}<br>
                }</code></p>
                <br>
            
                <p>Using packages and enums effectively can greatly enhance the organization, maintainability, and clarity of your Java code.</p>
                <br>`,
          },
        ],
      },
      {
        id: 5,
        title: "Data Structures and Error Handling",
        subTopics: [
          {
            Id: 1,
            title: "Arrays and Strings",
            description: `<br>

                <h2><strong>Array Declaration, Initialization, Manipulation</strong></h2>
                <p>Arrays in Java are a homogeneous data structure that store elements of the same data type in contiguous memory locations. They are declared by specifying the type of the elements they will contain followed by square brackets.</p>
                <br>
            
                <p><em>Example of Array Declaration:</em></p>
                <p><code>int[] myArray;</code></p>
                <br>
            
                <p>Arrays can be initialized when they are declared or at a later time. Initialization is done using curly braces with a comma-separated list of values.</p>
                <br>
            
                <p><em>Example of Array Initialization:</em></p>
                <p><code>int[] myArray = {1, 2, 3, 4, 5};</code></p>
                <br>
            
                <p>Manipulation of arrays involves accessing and modifying elements using their index, which starts from zero.</p>
                <br>
            
                <p><em>Example of Array Manipulation:</em></p>
                <p><code>myArray[0] = 10; // Changes the first element to 10</code></p>
                <br>
            
                <h2><strong>String Class and Operations</strong></h2>
                <p>The String class in Java is used to store and manipulate sequences of characters. Strings are immutable in Java, which means once a String object is created, it cannot be changed.</p>
                <br>
            
                <p>Strings can be created using double quotes or by using the new keyword and the String constructor.</p>
                <br>
            
                <p><em>Example of String Creation:</em></p>
                <p><code>String greeting = "Hello, World!";<br>
                String farewell = new String("Goodbye, World!");</code></p>
                <br>
            
                <p>The String class provides a variety of methods for string manipulation such as length(), charAt(), substring(), concat(), replace(), and many others.</p>
                <br>
            
                <p><em>Example of String Operations:</em></p>
                <p><code>int length = greeting.length(); // Gets the length of the string<br>
                char character = greeting.charAt(1); // Gets the character at index 1<br>
                String sub = greeting.substring(0, 5); // Gets a substring from index 0 to 4<br>
                String combined = greeting.concat(farewell); // Combines two strings</code></p>
                <br>
            
                <p>Understanding arrays and strings and their operations is crucial in Java programming as they form the basis for handling data collections and text manipulation.</p>
                <br>`,
          },
          {
            Id: 2,
            title: "Collections Framework",
            description: `<br>

                <h2><strong>Lists, Sets, Maps</strong></h2>
                <p>The Collections Framework in Java is a unified architecture for representing and manipulating collections. It allows developers to handle groups of objects with predefined classes. The most commonly used collections are Lists, Sets, and Maps.</p>
                <br>
            
                <h3><strong>Lists:</strong></h3>
                <p>A List in Java is an ordered collection that can contain duplicate elements. It is an interface and is implemented by classes like ArrayList, LinkedList, and Vector. Lists can be dynamically resized, and they allow positional access and insertion of elements.</p>
                <br>
            
                <p><em>Example of using a List:</em></p>
                <p><code>List&lt;String&gt; names = new ArrayList&lt;&gt;();<br>
                names.add("Alice");<br>
                names.add("Bob");<br>
                names.add("Charlie");<br>
                String name = names.get(1); // Returns "Bob"</code></p>
                <br>
            
                <h3><strong>Sets:</strong></h3>
                <p>A Set is a collection that cannot contain duplicate elements. It models the mathematical set abstraction and is implemented by classes like HashSet, LinkedHashSet, and TreeSet. Sets are commonly used for membership tests, removing duplicates from a collection, and performing set operations like union and intersection.</p>
                <br>
            
                <p><em>Example of using a Set:</em></p>
                <p><code>Set&lt;String&gt; uniqueNames = new HashSet&lt;&gt;();<br>
                uniqueNames.add("Alice");<br>
                uniqueNames.add("Bob");<br>
                uniqueNames.add("Alice"); // Duplicate, not added<br>
                boolean isPresent = uniqueNames.contains("Bob"); // Returns true</code></p>
                <br>
            
                <h3><strong>Maps:</strong></h3>
                <p>Maps in Java are objects that map keys to values. A map cannot contain duplicate keys, and each key can map to at most one value. It is implemented by classes like HashMap, TreeMap, and LinkedHashMap.</p>
                <br>
            
                <p><em>Example of using a Map:</em></p>
                <p><code>Map&lt;String, Integer&gt; ageMap = new HashMap&lt;&gt;();<br>
                ageMap.put("Alice", 30);<br>
                ageMap.put("Bob", 25);<br>
                ageMap.put("Charlie", 35);<br>
                int age = ageMap.get("Alice"); // Returns 30</code></p>
                <br>
            
                <h2><strong>Iterating through Collections</strong></h2>
                <p>Iterating through collections is a common task in Java. It can be done using iterators, for-each loops, and streams (since Java 8).</p>
                <br>
            
                <p><em>Example of iterating through a List:</em></p>
                <p><code>for(String name : names) {<br>
                &nbsp;&nbsp;System.out.println(name);<br>
                }</code></p>
                <br>
            
                <p><em>Example of iterating through a Set using an iterator:</em></p>
                <p><code>Iterator&lt;String&gt; iterator = uniqueNames.iterator();<br>
                while(iterator.hasNext()) {<br>
                &nbsp;&nbsp;String name = iterator.next();<br>
                &nbsp;&nbsp;System.out.println(name);<br>
                }</code></p>
                <br>
            
                <p><em>Example of iterating through a Map:</em></p>
                <p><code>for(Map.Entry&lt;String, Integer&gt; entry : ageMap.entrySet()) {<br>
                &nbsp;&nbsp;String name = entry.getKey();<br>
                &nbsp;&nbsp;Integer age = entry.getValue();<br>
                &nbsp;&nbsp;System.out.println(name + " is " + age + " years old.");<br>
                }</code></p>
                <br>
            
                <p>The Collections Framework is a powerful tool for developers, providing a rich set of interfaces and classes for storing and manipulating groups of data efficiently.</p>
                <br>`,
          },
          {
            Id: 3,
            title: "Exception Handling",
            description: `<br>

                <h2><strong>try, catch, finally Blocks</strong></h2>
                <p>Exception handling in Java is managed via the use of try, catch, and finally blocks. This mechanism is used to handle runtime errors so that the normal flow of the application can be maintained even when errors occur.</p>
                <br>
            
                <h3><strong>try Block:</strong></h3>
                <p>The try block encloses the code that might throw an exception. If an exception occurs within the try block, it is thrown.</p>
                <br>
            
                <h3><strong>catch Block:</strong></h3>
                <p>The catch block is used to handle the exception. It must follow the try block. When an exception is thrown, the catch block that handles that specific exception or its superclass will execute.</p>
                <br>
            
                <h3><strong>finally Block:</strong></h3>
                <p>The finally block is optional and used for code that must execute irrespective of whether an exception occurs or not. It is commonly used to close or release resources like I/O streams or database connections.</p>
                <br>
            
                <p><em>Example of try, catch, finally:</em></p>
                <p><code>try {<br>
                &nbsp;&nbsp;int division = 10 / 0;<br>
                } catch (ArithmeticException e) {<br>
                &nbsp;&nbsp;System.out.println("An error occurred: " + e.getMessage());<br>
                } finally {<br>
                &nbsp;&nbsp;System.out.println("This will always execute.");<br>
                }</code></p>
                <br>
            
                <h2><strong>Custom Exception Classes</strong></h2>
                <p>Besides the standard exceptions provided by Java, developers can create their own custom exception classes. These are useful for handling specific error conditions in a more controlled manner.</p>
                <br>
            
                <p>Custom exceptions are created by extending the Exception class (for checked exceptions) or the RuntimeException class (for unchecked exceptions).</p>
                <br>
            
                <p><em>Example of a Custom Exception:</em></p>
                <p><code>class MyCustomException extends Exception {<br>
                &nbsp;&nbsp;public MyCustomException(String message) {<br>
                &nbsp;&nbsp;&nbsp;&nbsp;super(message);<br>
                &nbsp;&nbsp;}<br>
                }</code></p>
                <br>
            
                <p><em>Using the Custom Exception:</em></p>
                <p><code>try {<br>
                &nbsp;&nbsp;throw new MyCustomException("Custom Error Occurred");<br>
                } catch (MyCustomException e) {<br>
                &nbsp;&nbsp;System.out.println(e.getMessage());<br>
                }</code></p>
                <br>
            
                <p>Exception handling is a crucial aspect of robust application development in Java, enabling developers to manage unexpected conditions gracefully without crashing the program.</p>
                <br>`,
          },
          {
            Id: 4,
            title: "File and I/O",
            description: `<h2><strong>Reading from and Writing to Files</strong></h2>
                <p>File I/O (Input/Output) in Java is a fundamental concept used to read from and write to files. Java provides several classes for handling file operations, with java.io.FileReader and java.io.FileWriter being some of the simplest ways to read and write text files, respectively.</p>
                <br>
            
                <h3><strong>Reading from Files:</strong></h3>
                <p>To read from a file, you can use the FileReader class in conjunction with BufferedReader for efficient reading of text data.</p>
                <br>
            
                <p><em>Example of reading from a file:</em></p>
                <p><code>try (BufferedReader reader = new BufferedReader(new FileReader("example.txt"))) {<br>
                &nbsp;&nbsp;String line;<br>
                &nbsp;&nbsp;while ((line = reader.readLine()) != null) {<br>
                &nbsp;&nbsp;&nbsp;&nbsp;System.out.println(line);<br>
                &nbsp;&nbsp;}<br>
                } catch (IOException e) {<br>
                &nbsp;&nbsp;e.printStackTrace();<br>
                }</code></p>
                <br>
            
                <h3><strong>Writing to Files:</strong></h3>
                <p>For writing to files, the FileWriter class can be used. Similar to reading, BufferedWriter can be paired with FileWriter for efficient writing.</p>
                <br>
            
                <p><em>Example of writing to a file:</em></p>
                <p><code>try (BufferedWriter writer = new BufferedWriter(new FileWriter("output.txt"))) {<br>
                &nbsp;&nbsp;writer.write("Hello, World!");<br>
                } catch (IOException e) {<br>
                &nbsp;&nbsp;e.printStackTrace();<br>
                }</code></p>
                <br>
            
                <h2><strong>Try-with-Resources</strong></h2>
                <p>The try-with-resources statement is a try statement that declares one or more resources. A resource is an object that must be closed after completing its operations. Try-with-resources ensures that each resource is closed at the end of the statement, which helps to avoid potential resource leaks.</p>
                <br>
            
                <p><em>Example of try-with-resources for file reading:</em></p>
                <p><code>try (BufferedReader reader = new BufferedReader(new FileReader("example.txt"))) {<br>
                &nbsp;&nbsp;// Read from the file<br>
                } // The reader is automatically closed here</code></p>
                <br>
            
                <p>File I/O is a critical component of many applications, and understanding how to perform these operations is essential for any Java developer. The ability to read from and write to files, as well as manage resources effectively with try-with-resources, are fundamental skills in Java programming.</p>
                <br>
            `,
          },
        ],
      },
      {
        id: 6,
        title: "Advanced Topics and Final Project",
        subTopics: [
          {
            Id: 1,
            title: "Multithreading Basics",
            description: `  <br>

                <h2><strong>Creating and Managing Threads</strong></h2>
                <p>Multithreading in Java is a process of executing multiple threads simultaneously. A thread is a lightweight sub-process, the smallest unit of processing. Java provides two ways to create a thread: by implementing the Runnable interface or by extending the Thread class.</p>
                <br>
            
                <h3><strong>Implementing the Runnable Interface:</strong></h3>
                <p>One can create a new thread by implementing the Runnable interface and overriding its run method.</p>
                <br>
            
                <p><em>Example:</em></p>
                <p><code>class MyRunnable implements Runnable {<br>
                &nbsp;&nbsp;public void run() {<br>
                &nbsp;&nbsp;&nbsp;&nbsp;System.out.println("Thread is running...");<br>
                &nbsp;&nbsp;}<br>
                }<br>
                <br>
                public class ThreadExample {<br>
                &nbsp;&nbsp;public static void main(String[] args) {<br>
                &nbsp;&nbsp;&nbsp;&nbsp;MyRunnable myRunnable = new MyRunnable();<br>
                &nbsp;&nbsp;&nbsp;&nbsp;Thread thread = new Thread(myRunnable);<br>
                &nbsp;&nbsp;&nbsp;&nbsp;thread.start();<br>
                &nbsp;&nbsp;}<br>
                }</code></p>
                <br>
            
                <h3><strong>Extending the Thread Class:</strong></h3>
                <p>Another way to create a thread is by extending the Thread class and overriding its run method.</p>
                <br>
            
                <p><em>Example:</em></p>
                <p><code>class MyThread extends Thread {<br>
                &nbsp;&nbsp;public void run() {<br>
                &nbsp;&nbsp;&nbsp;&nbsp;System.out.println("Thread is running...");<br>
                &nbsp;&nbsp;}<br>
                }<br>
                <br>
                public class ThreadExample {<br>
                &nbsp;&nbsp;public static void main(String[] args) {<br>
                &nbsp;&nbsp;&nbsp;&nbsp;MyThread myThread = new MyThread();<br>
                &nbsp;&nbsp;&nbsp;&nbsp;myThread.start();<br>
                &nbsp;&nbsp;}<br>
                }</code></p>
                <br>
            
                <h2><strong>Synchronization</strong></h2>
                <p>Synchronization in Java is the capability to control the access of multiple threads to any shared resource. It is used to prevent thread interference and consistency problems.</p>
                <br>
            
                <p><em>Example of synchronized method:</em></p>
                <p><code>class Counter {<br>
                &nbsp;&nbsp;private int count = 0;<br>
                &nbsp;&nbsp;public synchronized void increment() {<br>
                &nbsp;&nbsp;&nbsp;&nbsp;count++;<br>
                &nbsp;&nbsp;}<br>
                }</code></p>
                <br>
            
                <p>Synchronization is essential in cases where multiple threads need access to a shared resource and we need to ensure that only one thread can access the resource at a given point in time.</p>
                <br>
            
                <p>Multithreading enhances the performance of complex applications and enables the efficient use of CPU resources. However, it requires careful management to avoid issues such as deadlocks and race conditions. Synchronization is a crucial tool in a developer's arsenal for managing such risks.</p>
                <br>
            `,
          },
          {
            Id: 2,
            title: "Java 8 Features Overview",
            description: `<br>

                <h2><strong>Lambda Expressions</strong></h2>
                <p>One of the most significant additions to Java 8 was the introduction of lambda expressions. These expressions brought a new syntax element that made it possible to treat functionality as a method argument or code as data. Lambda expressions are essentially anonymous methods (methods without a name) that are used to implement a method defined by a functional interface. A functional interface is an interface that contains only one abstract method.</p>
                <br>
            
                <p>Lambda expressions are characterized by the following syntax:</p>
                <p><code>(argument-list) -> { body }</code></p>
                <p>Where the argument-list is a list of parameters separated by commas, and the body is a single expression or a statement block.</p>
                <br>
            
                <p><em>Example of a lambda expression:</em></p>
                <p><code>List<String> list = Arrays.asList("Java", "C#", "Python");<br>
                list.forEach((n) -> System.out.println(n));</code></p>
                <br>
            
                <p>This feature not only simplifies the syntax for instances where an object of a functional interface is required but also encourages a functional programming approach, enabling cleaner, more maintainable code.</p>
                <br>
            
                <h2><strong>Stream API</strong></h2>
                <p>Another pivotal feature introduced in Java 8 is the Stream API, which marked a significant step toward bringing functional programming concepts into Java. The Stream API allows developers to process sequences of elements (streams) in a declarative way. A stream represents a sequence of elements and supports different kinds of operations to perform computations on those elements. Streams can be created from various data sources, such as collections and arrays.</p>
                <br>
            
                <p>Stream operations are divided into intermediate and terminal operations. Intermediate operations return a new stream and are always lazy, which means they are not executed until a terminal operation is invoked. Terminal operations, on the other hand, produce a result or a side-effect.</p>
                <br>
            
                <p><em>Example of using the Stream API:</em></p>
                <p><code>List<Integer> numbers = Arrays.asList(2, 3, 4, 5);<br>
                List<Integer> square = numbers.stream().map(x -> x*x).<br>
                &nbsp;&nbsp;collect(Collectors.toList());<br>
                System.out.println(square);</code></p>
                <br>
            
                <p>The Stream API is a powerful tool that simplifies the process of collection manipulation, supporting functional-style operations on streams of elements and providing a new abstraction for performing computation. It has become an integral part of modern Java programming and is widely used for data processing tasks.</p>
                <br>
            `,
          },
          {
            Id: 3,
            title: " Networking in Java",
            description: `<br>

                <h2><strong>Sockets and ServerSockets</strong></h2>
                <p>In Java, networking operations are primarily conducted through the use of sockets, which provide a mechanism for two-way communication over the network. A socket is one endpoint of a two-way communication link between two programs running on the network. A socket is bound to a port number so that the TCP layer can identify the application that data is destined to be sent to.</p>
                <br>
            
                <p><em>ServerSockets</em> act as a listener on the server side. When a <em>ServerSocket</em> is created, it binds to a specific port number and listens for incoming connection requests. When a client socket connects, the <em>ServerSocket</em> accepts the connection, creating a <em>Socket</em> object for two-way communication.</p>
                <br>
            
                <p><em>Sockets</em>, on the other hand, are on the client side of the communication. A client creates a socket object by specifying the server's name and port number. Upon establishing a connection, the client and server can exchange data.</p>
                <br>
            
                <h2><strong>Developing a Simple Client-Server Application</strong></h2>
                <p>To illustrate how to develop a simple client-server application in Java, let's create a server that waits for a client to connect and sends a greeting message, and a client that connects to the server and receives the message.</p>
                <br>
            
                <p><em>Server Code Example:</em></p>
                <p><code>import java.io.*;<br>
                import java.net.*;<br>
                <br>
                public class GreetingServer {<br>
                &nbsp;&nbsp;public static void main(String[] args) throws IOException {<br>
                &nbsp;&nbsp;&nbsp;&nbsp;ServerSocket serverSocket = new ServerSocket(1234);<br>
                &nbsp;&nbsp;&nbsp;&nbsp;System.out.println("Server started. Waiting for a client ...");<br>
                &nbsp;&nbsp;&nbsp;&nbsp;Socket socket = serverSocket.accept();<br>
                &nbsp;&nbsp;&nbsp;&nbsp;System.out.println("Client accepted.");<br>
                &nbsp;&nbsp;&nbsp;&nbsp;OutputStream out = socket.getOutputStream();<br>
                &nbsp;&nbsp;&nbsp;&nbsp;PrintWriter writer = new PrintWriter(out, true);<br>
                &nbsp;&nbsp;&nbsp;&nbsp;writer.println("Hello, client!");<br>
                &nbsp;&nbsp;&nbsp;&nbsp;socket.close();<br>
                &nbsp;&nbsp;&nbsp;&nbsp;serverSocket.close();<br>
                &nbsp;&nbsp;}<br>
                }</code></p>
                <br>
            
                <p><em>Client Code Example:</em></p>
                <p><code>import java.io.*;<br>
                import java.net.*;<br>
                <br>
                public class GreetingClient {<br>
                &nbsp;&nbsp;public static void main(String[] args) throws UnknownHostException, IOException {<br>
                &nbsp;&nbsp;&nbsp;&nbsp;Socket socket = new Socket("localhost", 1234);<br>
                &nbsp;&nbsp;&nbsp;&nbsp;InputStream in = socket.getInputStream();<br>
                &nbsp;&nbsp;&nbsp;&nbsp;BufferedReader reader = new BufferedReader(new InputStreamReader(in));<br>
                &nbsp;&nbsp;&nbsp;&nbsp;String message = reader.readLine();<br>
                &nbsp;&nbsp;&nbsp;&nbsp;System.out.println(message);<br>
                &nbsp;&nbsp;&nbsp;&nbsp;socket.close();<br>
                &nbsp;&nbsp;}<br>
                }</code></p>
                <br>
            
                <p>In the above example, the server listens on port 1234. When the client connects, the server accepts the connection and sends a greeting message. The client reads and prints this message before closing the connection. This simple example lays the foundation for more complex client-server applications, demonstrating Java's capabilities for handling network communication.</p>
                <br>`,
          },
          {
            Id: 4,
            title: "Final Project",
            description: `<h1><strong>Java Course Final Project: Chat Application</strong></h1>
                <br>
            
                <h2><strong>Overview</strong></h2>
                <p>A comprehensive Java-based chat application utilizing client-server architecture to encapsulate various Java concepts including OOP, multithreading, networking, file I/O, exception handling, and Java 8 features.</p>
                <br>
            
                <h2><strong>Objectives</strong></h2>
                <ul>
                    <li>Design and implement a multi-threaded server for handling multiple client connections.</li>
                    <li>Create client applications for message exchange in real-time.</li>
                    <li>Utilize Java networking, multithreading, and file I/O.</li>
                    <li>Develop a simple GUI for the client application.</li>
                </ul>
                <br>
            
                <h2><strong>Requirements</strong></h2>
                <ol>
                    <li>Networking with sockets and server sockets.</li>
                    <br><li>Multithreading for concurrent client management.</li>
                    <br><li>Graphical User Interface for the client application.</li>
                    <br><li>Server-side chat logs using file I/O.</li>
                    <br><li>Robust exception handling.</li>
                    <br><li>Incorporation of Java 8 features.</li>
                    <br><li>Adherence to OOP principles.</li>
                </ol>
                <br>
            
                <h2><strong>Project Structure</strong></h2>
                <ul>
                <br><li><strong>Server Module:</strong> Manages connections and broadcasts messages.</li>
                <br><li><strong>Client Module:</strong> Connects to server and handles user interactions.</li>
                <br><li><strong>Client Module:</strong> Connects to server and handles user interactions.</li>
                <br><li><strong>Common Module:</strong> Contains shared resources and utilities.</li>
                </ul>
                <br>
            
                <h2><strong>Milestones</strong></h2>
                <ol>
                    <li>Week 1: Project setup and architecture design.</li>
                    <br><li>Week 2: Server module implementation.</li>
                    <br><li>Week 3: Client module development with text interface.</li>
                    <br><li>Week 4: GUI development and server-side file I/O.</li>
                    <br><li>Week 5: Java 8 features integration and application refinement.</li>
                    <br><li>Week 6: Documentation and project presentation preparation.</li>
                </ol>
                <br>
            
                <h2><strong>Evaluation Criteria</strong></h2>
                <ul>
                    <li><strong>Code Quality:</strong> OOP adherence and Java best practices.</li>
                    <br><li><strong>Functionality:</strong> Requirement fulfillment and application robustness.</li>
                    <br><li><strong>User Interface:</strong> Client GUI usability and aesthetics.</li>
                    <br><li><strong>Documentation:</strong> Code comments, project report, and presentation quality.</li>
                </ul>
                <br>`,
          },
        ],
      },
    ],
  },
  {
    courseName: "Python",
    courseId: "python",
    description:
      "Explore Python from basics to advanced with our concise reading material. Elevate your coding skills effortlessly!",
    chapters: [
      {
        id: 1,
        title: "Intoduction",
        subTopics: [
          {
            Id: 1,
            title: "What is JAV?",
            description:
              "<h1 className={text-2xl}>Hello</h1> <p>Java is a programming language and a platform. Java is a high level, robust, object-oriented and </p>",
          },
          {
            Id: 2,
            title: "History Of Java",
            description:
              "The history of Java is very interesting. Java was originally designed for interactive television, but it was too advanced technology for the digital cable television industry at the time. The history of Java starts with the Green Team. Java team members (also known as Green Team), initiated this project to develop a language for digital devices such as set-top boxes, televisions, etc. However, it was best suited for internet programming. Later, Java technology was incorporated by Netscape.The principles for creating Java programming were Simple, Robust, Portable, Platform-independent, Secured, High Performance, Multithreaded, Architecture Neutral, Object-Oriented, Interpreted, and Dynamic Java was developed by James Gosling, who is known as the father of Java, in 1995. James Gosling and his team members started the project in the early '90s.",
          },
        ],
      },
      {
        id: 2,
        title: "Intoduction",
        subTopics: [
          {
            Id: 1,
            title: "What is JAV?",
            description:
              "Java is a programming language and a platform. Java is a high level, robust, object-oriented and secure programming language.Java was developed by Sun Microsystems (which is now the subsidiary of Oracle) in the year 1995. James Gosling is known as the father of Java. Before Java, its name was Oak. Since Oak was already a registered company, so James Gosling and his team changed the name from Oak to Java.Any hardware or software environment in which a program runs, is known as a platform. Since Java has a runtime environment (JRE) and API, it is called a platform.",
          },
          {
            Id: 2,
            title: "History Of Java",
            description:
              "The history of Java is very interesting. Java was originally designed for interactive television, but it was too advanced technology for the digital cable television industry at the time. The history of Java starts with the Green Team. Java team members (also known as Green Team), initiated this project to develop a language for digital devices such as set-top boxes, televisions, etc. However, it was best suited for internet programming. Later, Java technology was incorporated by Netscape.The principles for creating Java programming were Simple, Robust, Portable, Platform-independent, Secured, High Performance, Multithreaded, Architecture Neutral, Object-Oriented, Interpreted, and Dynamic Java was developed by James Gosling, who is known as the father of Java, in 1995. James Gosling and his team members started the project in the early '90s.",
          },
        ],
      },
      {
        id: 2,
        title: "Intoduction",
        subTopics: [
          {
            Id: 1,
            title: "What is JAV?",
            description:
              "Java is a programming language and a platform. Java is a high level, robust, object-oriented and secure programming language.Java was developed by Sun Microsystems (which is now the subsidiary of Oracle) in the year 1995. James Gosling is known as the father of Java. Before Java, its name was Oak. Since Oak was already a registered company, so James Gosling and his team changed the name from Oak to Java.Any hardware or software environment in which a program runs, is known as a platform. Since Java has a runtime environment (JRE) and API, it is called a platform.",
          },
          {
            Id: 2,
            title: "History Of Java",
            description:
              "The history of Java is very interesting. Java was originally designed for interactive television, but it was too advanced technology for the digital cable television industry at the time. The history of Java starts with the Green Team. Java team members (also known as Green Team), initiated this project to develop a language for digital devices such as set-top boxes, televisions, etc. However, it was best suited for internet programming. Later, Java technology was incorporated by Netscape.The principles for creating Java programming were Simple, Robust, Portable, Platform-independent, Secured, High Performance, Multithreaded, Architecture Neutral, Object-Oriented, Interpreted, and Dynamic Java was developed by James Gosling, who is known as the father of Java, in 1995. James Gosling and his team members started the project in the early '90s.",
          },
        ],
      },
      {
        id: 2,
        title: "Intoduction",
        subTopics: [
          {
            Id: 1,
            title: "What is JAV?",
            description:
              "Java is a programming language and a platform. Java is a high level, robust, object-oriented and secure programming language.Java was developed by Sun Microsystems (which is now the subsidiary of Oracle) in the year 1995. James Gosling is known as the father of Java. Before Java, its name was Oak. Since Oak was already a registered company, so James Gosling and his team changed the name from Oak to Java.Any hardware or software environment in which a program runs, is known as a platform. Since Java has a runtime environment (JRE) and API, it is called a platform.",
          },
          {
            Id: 2,
            title: "History Of Java",
            description:
              "The history of Java is very interesting. Java was originally designed for interactive television, but it was too advanced technology for the digital cable television industry at the time. The history of Java starts with the Green Team. Java team members (also known as Green Team), initiated this project to develop a language for digital devices such as set-top boxes, televisions, etc. However, it was best suited for internet programming. Later, Java technology was incorporated by Netscape.The principles for creating Java programming were Simple, Robust, Portable, Platform-independent, Secured, High Performance, Multithreaded, Architecture Neutral, Object-Oriented, Interpreted, and Dynamic Java was developed by James Gosling, who is known as the father of Java, in 1995. James Gosling and his team members started the project in the early '90s.",
          },
        ],
      },
      {
        id: 2,
        title: "Intoduction",
        subTopics: [
          {
            Id: 1,
            title: "What is JAV?",
            description:
              "Java is a programming language and a platform. Java is a high level, robust, object-oriented and secure programming language.Java was developed by Sun Microsystems (which is now the subsidiary of Oracle) in the year 1995. James Gosling is known as the father of Java. Before Java, its name was Oak. Since Oak was already a registered company, so James Gosling and his team changed the name from Oak to Java.Any hardware or software environment in which a program runs, is known as a platform. Since Java has a runtime environment (JRE) and API, it is called a platform.",
          },
          {
            Id: 2,
            title: "History Of Java",
            description:
              "The history of Java is very interesting. Java was originally designed for interactive television, but it was too advanced technology for the digital cable television industry at the time. The history of Java starts with the Green Team. Java team members (also known as Green Team), initiated this project to develop a language for digital devices such as set-top boxes, televisions, etc. However, it was best suited for internet programming. Later, Java technology was incorporated by Netscape.The principles for creating Java programming were Simple, Robust, Portable, Platform-independent, Secured, High Performance, Multithreaded, Architecture Neutral, Object-Oriented, Interpreted, and Dynamic Java was developed by James Gosling, who is known as the father of Java, in 1995. James Gosling and his team members started the project in the early '90s.",
          },
        ],
      },
      {
        id: 2,
        title: "Intoduction",
        subTopics: [
          {
            Id: 1,
            title: "What is JAV?",
            description:
              "Java is a programming language and a platform. Java is a high level, robust, object-oriented and secure programming language.Java was developed by Sun Microsystems (which is now the subsidiary of Oracle) in the year 1995. James Gosling is known as the father of Java. Before Java, its name was Oak. Since Oak was already a registered company, so James Gosling and his team changed the name from Oak to Java.Any hardware or software environment in which a program runs, is known as a platform. Since Java has a runtime environment (JRE) and API, it is called a platform.",
          },
          {
            Id: 2,
            title: "History Of Java",
            description:
              "The history of Java is very interesting. Java was originally designed for interactive television, but it was too advanced technology for the digital cable television industry at the time. The history of Java starts with the Green Team. Java team members (also known as Green Team), initiated this project to develop a language for digital devices such as set-top boxes, televisions, etc. However, it was best suited for internet programming. Later, Java technology was incorporated by Netscape.The principles for creating Java programming were Simple, Robust, Portable, Platform-independent, Secured, High Performance, Multithreaded, Architecture Neutral, Object-Oriented, Interpreted, and Dynamic Java was developed by James Gosling, who is known as the father of Java, in 1995. James Gosling and his team members started the project in the early '90s.",
          },
        ],
      },
    ],
  },
];
