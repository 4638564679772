import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
  } from "./ui/accordion"
  // import { FAQ_data } from "../data/FAQ"

  export function AccordionBox({data}:any) {
    // console.log('data')
    // console.log(data)
    return (
      <Accordion type="single" collapsible className="text-sm md:text-md lg:text-lg md:w-[650px] ">
        
    {data.map((faq : any, index :any) => (
        <AccordionItem key={index} value={`item-${index}`}>
          <AccordionTrigger>{faq.question}</AccordionTrigger>
          <AccordionContent>{faq.answer}</AccordionContent>
        </AccordionItem>
      ))}
      </Accordion>
    )
  }
  