import React from 'react'
import GradualSpacing from '../magicui/gradual-spacing'
const OurCompany = () => {
  return (
    <div className='bg-[#53389E] text-white text-center py-16 lg:py-20 px-8 lg:px-20 flex flex-col items-center justify-center gap-3'>
        <span className='text-lightwhite text-base mb-3'>Driven by Passion</span>
        <div className='flex flex-col md:flex-row gap-2'>

        <GradualSpacing
      className="font-display text-center text-4xl font-medium tracking-[-0.1em] text-white md:text-5xl md:leading-[5rem]"
      text="Made with Love,"
    />
        <GradualSpacing
      className="font-display text-center text-4xl font-medium tracking-[-0.1em] text-white md:text-5xl md:leading-[5rem]"
      text="right here in INDIA"
    />
      </div>
        <p className='text-[#E9D7FE] font-light tracking-wide pt-3 text-lg px-2'>Designed uniquely for Indian students</p>
    </div>
  )
}

export default OurCompany