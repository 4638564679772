export const Internhsips_FAQ_data = [
    {
      question: "What is KampKode?",
      answer:
        "KampKode is an edutech platform dedicated to empowering engineering students with the skills and knowledge they need to excel in their careers. We offer a range of programs, including Campus Recruitment Training, Internships, Projects, and more.",
    },
    {
      question: "Who can benefit from KampKode internships? ",
      answer:
        "KampKode is principally tailored for students, yet our programs welcome all individuals eager to enhance their skills and embark on a career in the technology sector. Whether you're looking to start or kickstart your journey in the tech industry, KampKode is the ideal platform for you.",
    },
    {
      question:
        "What kind of projects will I work on?",
      answer:
        "You'll work on real-world, industry-driven projects that are designed to build your skills and provide practical experience in your chosen field.",
    },
    {
      question:
        "Can I apply for multiple internships?",
      answer:
        "Yes, absolutely! Applying for multiple internships increases your chances of finding the perfect fit for your career goals. We encourage you to explore all opportunities that interest you.",
    },
    {
      question:
        "Will I receive a certificate upon completion of the internship?",
      answer:
        "Yes, all students who successfully complete their internship will receive a certificate of completion from KampKode .",
    },
    
    {
      question: "Who can apply for KampKode internships?",
      answer:
        "Students from colleges, both undergraduate and postgraduate, can apply for our internships.",
    },
    
  ];  
  