import React, { useState, memo, useEffect, useCallback, useRef } from "react";
import { Editor } from "@monaco-editor/react";
import { useFetchLanguages, compileCode } from "./CompileCode";
import { isLanguageUsing } from "../../uiHelper";
import Console from "./Console";
import { useSocket } from "./SocketProvider";
import copy from "../../assets/copy.png";

interface MonacoEditorProps {
  isTeacher?: boolean;
  classId?: string;
  name?: string;
}

interface Language {
  mode: string;
  language: number;
}

const MonacoEditor: React.FC<MonacoEditorProps> = ({
  isTeacher,
  classId,
  name,
}) => {
  const languages = useFetchLanguages();
  const socket = useSocket();

  const [teacherCode, setTeacherCode] = useState<string>(
    'console.log("Hello, world!");'
  );
  const [studentCode, setStudentCode] = useState<string>(
    'console.log("Hello, world!");'
  );
  const [teacherLanguage, setTeacherLanguage] = useState<Language>({
    mode: "cpp",
    language: 76,
  });
  const [studentLanguage, setStudentLanguage] = useState<Language>({
    mode: "cpp",
    language: 76,
  });

  const [teacherOutput, setTeacherOutput] = useState<string>("");
  const [studentOutput, setStudentOutput] = useState<string>("");

  const debounce = <T extends any[]>(
    fn: (...args: T) => void,
    delay: number
  ) => {
    let timer: NodeJS.Timeout;
    return (...args: T) => {
      clearTimeout(timer);
      timer = setTimeout(() => fn(...args), delay);
    };
  };

  useEffect(() => {
    const codeReceiver = (receivedCode: string) => {
      setTeacherCode(receivedCode);
    };

    socket.on("receive-code", codeReceiver);

    return () => {
      socket.off("receive-code", codeReceiver);
    };
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnChange = useCallback(
    debounce((newCode: string) => {
      setTeacherCode(newCode);
      if (isTeacher && socket) {
        socket.emit("send-code", { classId, newCode });
      }
    }, 300),
    // eslint-disable-next-line
    [isTeacher, socket, classId]
  );

  const teacherOnChangeHandler = (newCode: string | undefined) => {
    if (newCode !== undefined) {
      debouncedOnChange(newCode);
    }
  };

  const studentOnChangeHandler = (newCode: string | undefined) => {
    if (newCode) {
      setStudentCode(newCode);
    }
  };

  useEffect(() => {
    const languageReceiver = (receivedLanguage: Language) => {
      setTeacherLanguage(receivedLanguage);
    };

    socket.on("receive-language", languageReceiver);

    return () => {
      socket.off("receive-language", languageReceiver);
    };
    // eslint-disable-next-line
  }, []);

  const prevTeacherLanguageRef = useRef<Language>(teacherLanguage);

  useEffect(() => {
    const prevTeacherLanguage = prevTeacherLanguageRef.current;

    // Check if the language has actually changed
    if (
      teacherLanguage &&
      socket &&
      (teacherLanguage.mode !== prevTeacherLanguage.mode ||
        teacherLanguage.language !== prevTeacherLanguage.language)
    ) {
      const language = teacherLanguage;
      socket.emit("send-language", { classId, language });
    }

    // Update the ref with the new language for the next render
    prevTeacherLanguageRef.current = teacherLanguage;
  }, [teacherLanguage, socket, classId]);

  const handleTeacherLanguageChange = (e: any) => {
    // setTeacherLanguage(e.target.value);
    const langId = parseInt(e.target.value, 10); // parse to integer if it's a string
    console.log(langId);

    switch (langId) {
      case 63:
      case 93:
        setTeacherLanguage({ mode: "javascript", language: langId });
        break;
      case 70:
      case 92:
      case 71:
        setTeacherLanguage({ mode: "python", language: langId });
        break;
      case 91:
      case 62:
        setTeacherLanguage({ mode: "java", language: langId });
        break;
      case 76:
      case 52:
      case 53:
      case 54:
        setTeacherLanguage({ mode: "cpp", language: langId });
        break;
      case 11:
        setTeacherLanguage({ mode: "html", language: langId });
        break;
      case 12:
        setTeacherLanguage({ mode: "css", language: langId });
        break;
      default:
        setTeacherLanguage({ mode: "cpp", language: 76 });
    }
  };

  const handleStudentLanguageChange = (e: any) => {
    // setStudentLanguage(e.target.value);
    const langId = parseInt(e.target.value, 10); // parse to integer if it's a string

    console.log(langId);

    switch (langId) {
      case 63:
      case 93:
        setStudentLanguage({ mode: "javascript", language: langId });
        break;
      case 70:
      case 92:
      case 71:
        setStudentLanguage({ mode: "python", language: langId });
        break;
      case 91:
      case 62:
        setStudentLanguage({ mode: "java", language: langId });
        break;
      case 76:
      case 52:
      case 53:
      case 54:
        setStudentLanguage({ mode: "cpp", language: langId });
        break;
      case 11:
        setStudentLanguage({ mode: "html", language: langId });
        break;
      case 12:
        setStudentLanguage({ mode: "css", language: langId });
        break;
      default:
        setStudentLanguage({ mode: "cpp", language: 76 });
    }
  };

  useEffect(() => {
    const defaultSnippets: { [key: string]: string } = {
      javascript: 'console.log("Hello, world!");',
      python: 'print("Hello, world!")',
      html: "<h1>Hello, world!</h1>",
      css: "body { font-size: 16px; }",
      java: 'class Main {\n  public static void main(String[] args) {\n    System.out.println("Hello, world!");\n  }\n}',
      cpp: '#include <iostream>\n\nint main() {\n  std::cout << "Hello, world!" << std::endl;\n  return 0;\n}',
    };
    setTeacherCode(defaultSnippets[teacherLanguage.mode] || "");
  }, [teacherLanguage]);

  useEffect(() => {
    const defaultSnippets: { [key: string]: string } = {
      javascript: 'console.log("Hello, world!");',
      python: 'print("Hello, world!")',
      html: "<h1>Hello, world!</h1>",
      css: "body { font-size: 16px; }",
      java: 'class Main {\n  public static void main(String[] args) {\n    System.out.println("Hello, world!");\n  }\n}',
      cpp: '#include <iostream>\n\nint main() {\n  std::cout << "Hello, world!" << std::endl;\n  return 0;\n}',
    };
    setStudentCode(defaultSnippets[studentLanguage.mode] || "");
  }, [studentLanguage]);

  const clearConsoleHandler = () => {
    if (isTeacher) {
      setTeacherOutput("");
    } else {
      setStudentOutput("");
    }
  };

  useEffect(() => {
    socket.on("receive-output", (receivedOutput: string) => {
      setTeacherOutput(receivedOutput);
    });
    // eslint-disable-next-line
  }, []);

  const compileTeacherCodeHandler = async () => {
    const newOutput = await compileCode(teacherCode, teacherLanguage.language);
    setTeacherOutput((await newOutput) || "");
  };

  const compileStudentCodeHandler = async () => {
    const newOutput = await compileCode(studentCode, studentLanguage.language);
    setStudentOutput((await newOutput) || "");
  };

  useEffect(() => {
    if (isTeacher && socket) {
      const output = teacherOutput;
      socket.emit("send-output", { classId, output });
    }
    // eslint-disable-next-line
  }, [teacherOutput]);

  const copyCodeHandler = () => {
    setStudentCode(teacherCode);
  };

  return (
    <>
      <div className="flex">
        <div className="w-1/2 h-[100vh] p-4">
          <div className="flex items-center justify-between py-2 px-4 rounded-t-3xl border-t-2 shadow-lg">
            <div className="font-bold text-lg py-2 text-customLightPurple">
              Teacher's Editor {`{}`}
            </div>

            <div className="flex">
              <select
                onChange={handleTeacherLanguageChange}
                value={teacherLanguage.language}
                className="mr-10"
                disabled={!isTeacher}
              >
                {languages.map((lang) =>
                  isLanguageUsing(lang.id) ? (
                    <option key={lang.id} value={lang.id}>
                      {lang.name}
                    </option>
                  ) : null
                )}
                <option value="11">HTML</option>
                <option value="12">CSS</option>
              </select>
              {isTeacher ? (
                <button
                  onClick={compileTeacherCodeHandler}
                  className=" bg-customLightPurple hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Run
                </button>
              ) : (
                <button
                  onClick={copyCodeHandler}
                  className="flex items-center text-customLightPurple font-bold py-2 px-4 rounded border border-customLightPurple"
                >
                  <img src={copy} alt="copy" className="w-6 h-6" />
                  <div className="pl-2">Copy</div>
                </button>
              )}
            </div>
          </div>
          <Editor
            height="100%"
            width="100%"
            theme="vs-dark"
            defaultLanguage="cpp"
            language={teacherLanguage.mode}
            options={{ readOnly: !isTeacher }}
            onChange={teacherOnChangeHandler}
            value={teacherCode}
          />
        </div>
        {!isTeacher && (
          <div className="w-1/2 h-[100vh] p-4">
            <div className="flex items-center justify-between py-2 px-4 rounded-t-3xl border-t-2 shadow-lg">
              <div className="font-bold text-lg py-2 text-customLightPurple">
                Your Editor {`{}`}
              </div>
              {!isTeacher && (
                <div>
                  <select
                    onChange={handleStudentLanguageChange}
                    className="mr-10"
                    value={studentLanguage.language}
                  >
                    {languages.map((lang) =>
                      isLanguageUsing(lang.id) ? (
                        <option key={lang.id} value={lang.id}>
                          {lang.name}
                        </option>
                      ) : null
                    )}
                    <option value="11">HTML</option>
                    <option value="12">CSS</option>
                  </select>
                  <button
                    onClick={compileStudentCodeHandler}
                    className=" bg-customLightPurple hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Run
                  </button>
                </div>
              )}
            </div>
            <Editor
              height="100%"
              width="100%"
              theme="vs-dark"
              defaultLanguage="cpp"
              language={studentLanguage.mode}
              onChange={studentOnChangeHandler}
              value={studentCode}
            />
          </div>
        )}
        {isTeacher && (
          <div className="w-1/2 h-[100vh] p-4">
            <Console
              hasAccess={isTeacher}
              output={teacherOutput}
              clearConsoleHandler={clearConsoleHandler}
              role={"Teacher's"}
            />
          </div>
        )}
      </div>
      {!isTeacher && (
        <div className="flex pt-20">
          <div className="w-1/2 h-[100vh] p-4">
            <Console
              hasAccess={false}
              output={teacherOutput}
              clearConsoleHandler={clearConsoleHandler}
              role={"Teacher's"}
            />
          </div>
          <div className="w-1/2 h-[100vh] p-4">
            <Console
              hasAccess={true}
              output={studentOutput}
              clearConsoleHandler={clearConsoleHandler}
              role={"Your"}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default memo(MonacoEditor);
