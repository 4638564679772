import React from 'react'
import { GithubLogo,PPTLogo,WebHost,Cert } from './ProjectPerkLogos'

const ProjectPerks = () => {
  return (
    <div className='text-center'>
    <h1 className='text-[#101828] text-3xl font-semibold my-10'>Everything You Need for Your Project</h1>
    <div className='grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-y-16 gap-8 my-14  pt-10 '>
        <Perks logo={<GithubLogo />} title='Source Code'/>
        <Perks logo={<PPTLogo />} title='PPT & Documentation'/>
        <Perks logo={<WebHost />} title='Website Hosting'/>
        <Perks logo={<Cert />} title='Certificate'/>
    </div>

  </div>
  )
}

export default ProjectPerks

interface PerksProps {
    logo: React.ReactNode;
    title: string;
  }

const Perks = ({logo, title}:PerksProps) => (
    <div className='relative h-28 w-40 lg:w-[12vw] bg-[#F9F6FF] rounded-md my-auto mx-auto '>
    <div className='absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-16 w-16'>
    {logo}
    </div> 
    <span className='absolute bottom-7 w-full right-0 font-semibold'>
      {title}
    </span>
  </div>
  )