import React, { useState, useEffect } from "react";

const TaskCard = () => {
  const [hours, setHours] = useState(10);
  const [minutes, setMinutes] = useState(24);
  const [seconds, setSeconds] = useState(50);

  useEffect(() => {
    const countdown = setInterval(() => {
      if (seconds === 0) {
        if (minutes === 0) {
          setHours((prevHours) => prevHours - 1);
          setMinutes(59);
          setSeconds(59);
        } else {
          setMinutes((prevMinutes) => prevMinutes - 1);
          setSeconds(59);
        }
      } else {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }
    }, 1000);

    return () => clearInterval(countdown);
  }, [seconds, minutes]);

  return (
    <div
      style={{
        backgroundColor: "#7D6EEB",
        borderRadius: "10px",
        padding: "20px",
        color: "#FFFFFF",
        width: "800px",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        alignItems: "center"
      }}
    >
      <img
        src="https://res.cloudinary.com/dw2h36vj0/image/upload/v1698978513/target_ing_1_xw2w1s.png"
        alt="Target"
        style={{
          position: "absolute",
          left: "10px",
          top: "50%",
          transform: "translateY(-50%)",
          width: "20%",
          height: "95%"
        }}
      />
      <h2 style={{ fontSize: "24px", fontWeight: "bold", textAlign: "center", marginBottom: "20px" }}>Today's Tasks</h2>
      
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "70%",
          marginLeft: "20%"
        }}
      >
        {[
          { number: 2, text: "Quizzes" },
          { number: 1, text: "Skill Assignment" },
          { number: 2, text: "Reading Materials" }
        ].map((task, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ color: "#F8F803", fontSize: "30px", fontWeight: "bold", marginRight: '10px' }}>{task.number}</span>
            <div>
              <p style={{ color: "white", marginBottom: "0" }}>{task.text}</p>
              <p style={{ color: "#1E003D" }}>to complete</p>
            </div>
          </div>
        ))}
      </div>
      
      <div
        style={{
          position: "absolute",
          right: "20px",
          top: "20px",
          color: "#F8F803",
          fontSize: "18px"
        }}
      >
        Time Left: {`${hours}:${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`} min
      </div>
    </div>
  );
};

export default TaskCard;
