import React from 'react';
// import { CircleArrowLeft, CircleArrowRight } from 'lucide-react';

interface CategoryScrollProps {
  categories: string[];
  selectedCategory: string;
  onCategoryClick: (category: string) => void;
}

const CategoryScroll: React.FC<CategoryScrollProps> = ({ categories, selectedCategory, onCategoryClick }) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  // console.log('CATEGORY SCROLL')
  // console.log(categories)
  // const scroll = (direction: 'left' | 'right') => {
  //   if (containerRef.current) {
  //     containerRef.current.scrollBy({ left: direction === 'left' ? -200 : 200, behavior: 'smooth' });
  //   }
  // };

  return (
    <div className="relative mb-4 mx-4">
      {/* <button
        className="absolute left-1 top-1/2 transform -translate-y-1/2 bg-white shadow-md h-8 rounded-full px-1 z-10 font-bold text-xl"
        onClick={() => scroll('left')}
      >
        <CircleArrowLeft />
      </button> */}
      <div
        className="border rounded-md py-2 px-3 flex gap-2 overflow-x-auto no-scrollbar whitespace-nowrap"
        ref={containerRef}
      >
        {categories.map((category) => (
          <div
            key={category}
            className={`px-2 py-1 lg:px-4 lg:py-2 border rounded-lg cursor-pointer ${
              selectedCategory === category
                ? 'bg-white shadow-md font-semibold'
                : 'hover:bg-white hover:shadow-sm'
            }`}
            onClick={() => onCategoryClick(category)}
          >
            {category}
          </div>
        ))}
      </div>
      {/* <button
        className="absolute right-1 top-1/2 transform -translate-y-1/2 bg-white shadow-md h-8 rounded-full px-1 z-10 font-bold text-xl"
        onClick={() => scroll('right')}
      >
        <CircleArrowRight />
      </button> */}
    </div>
  );
};

export default CategoryScroll;
