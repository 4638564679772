import React, { useEffect, useState } from "react";
import { getAllQuizzesMetaData } from "../../services/quizzes.services";
import { quizzesMetaData } from "../../types/types";
import styles from "./Quizzes.module.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Quizzes = () => {
  const [quizzes, setQuizzes] = useState<quizzesMetaData[]>([]);
  const user = useSelector((state: any) => state.currentUserReducer);

  const navigate = useNavigate();

  const handleQuizLinkClick = (quiz: string, quizTopic: string) => {
    if (!user) {
      // User is not logged in, show an alert
      alert("You need to log in to continue");
    } else {
      // User is logged in, navigate to the quiz page
      navigate(`/quizzes/${quiz}?quizId=${quizTopic}`);
    }
  };

  useEffect(() => {
    const getQuizzes = async () => {
      try {
        const response = await getAllQuizzesMetaData();
        if (response.status === 1) {
          setQuizzes(response.data);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getQuizzes();
  }, []);

  return (
    <div className="mt-[80px]">
      <div className="w-full text-center bg-customThinPurple">
        <div className="font-semibold text-8xl pt-12">Quiz</div>
        <div className="py-12 lg:px-40 md:px-10 px-10 font-medium">
        Quizzes can be a fun and informative way to challenge yourself, learn something new, or simply have a good time.
        </div>
      </div>
      <div className="flex flex-col flex-wrap w-full mt-5 px-20">
        {quizzes.map((quiz, index) => {
          return (
            <div
              key={index}
              className="w-full my-5 rounded-2xl border border-customLightPurple"
            >
              <div className="text-center text-4xl font-bold text-customLightPurple py-4">
                {quiz.quizTitle}
              </div>
              <div className="text-center font-bold pb-4">
                {quiz.description}
              </div>
              <div
                className={`mx-10 pb-5 pt-5 overflow-auto whitespace-nowrap ${styles.quiz}`}
              >
                {quiz.quizzes.map((quizTopic, index) => {
                  return (
                    <div
                      key={quizTopic.quizId}
                      className="bg-customThinPurple cursor-pointer rounded-lg px-4 py-8 w-[300px] h-full text-center mx-5 inline-block overflow-hidden"
                      style={{ whiteSpace: "normal" }}
                      onClick={() =>
                        handleQuizLinkClick(quiz.id, quizTopic.quizId)
                      }
                    >
                      {`Quiz on ${quizTopic.quizTopic}`}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Quizzes;
