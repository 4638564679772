import React from "react";
import ProfileCard from "./ProfileCard";
import TaskCard from "./TaskCard";
import ProgressCard from "./ProgressCard"; 

const Dashboard = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "20px"
      }}
    >
      <div style={{ flex: 1 }}>
        <ProfileCard />
      </div>
      <div style={{ flex: 2, marginLeft: "20px" }}>
        <TaskCard />
        <div style={{ marginTop: "20px" }}>
          <ProgressCard />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
