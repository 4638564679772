import React, { useState } from "react";

const ProfileCard = () => {
    const [profilePic] = useState<string>("https://res.cloudinary.com/dw2h36vj0/image/upload/v1698977077/Untitled_1_ngqmrv.png");
    const [linkedinLink, setLinkedinLink] = useState<string>('');
    const [githubLink, setGithubLink] = useState<string>('');
    const [showPopup, setShowPopup] = useState<string>('');

    const handleIconClick = (platform: string) => {
        if (platform === 'linkedin' && linkedinLink) {
            window.open(linkedinLink, '_blank');
        } else if (platform === 'github' && githubLink) {
            window.open(githubLink, '_blank');
        } else {
            setShowPopup(platform);
        }
    };

    const handleSaveLink = (platform: string, link: string) => {
        if (platform === 'linkedin') {
            setLinkedinLink(link);
        } else if (platform === 'github') {
            setGithubLink(link);
        }
        setShowPopup(''); // close the popup
    };

    return (
        <div style={{ backgroundColor: "#D4CFFF", padding: "20px", borderRadius: "15px", width: "300px", height: "500px", textAlign: "center", color: "#1E003D", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <div>
                <div style={{ borderRadius: "50%", width: "120px", height: "120px", margin: "0 auto", marginBottom: "20px", overflow: "hidden" }}>
                    <img src={profilePic} alt="Profile" style={{ width: "100%", height: "100%" }} />
                </div>
                <h2 style={{ color: "#000000", fontWeight: "bold" }}>Muzammil Shaik</h2>
                <p style={{ color: "#7D6EEB" }}>Student</p>

                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                    <div onClick={() => handleIconClick('linkedin')}>
                        <img src="https://res.cloudinary.com/dw2h36vj0/image/upload/v1698977410/linked_in_x4cw5y.png" alt="LinkedIn" style={{ width: "30px", height: "30px" }} />
                    </div>
                    {showPopup === 'linkedin' && (
                        <div>
                            <input type="text" placeholder="Enter LinkedIn URL" onChange={(e) => setLinkedinLink(e.target.value)} />
                            <button onClick={() => handleSaveLink('linkedin', linkedinLink)}>Save</button>
                        </div>
                    )}
                    <div onClick={() => handleIconClick('github')}>
                        <img src="https://res.cloudinary.com/dw2h36vj0/image/upload/v1698977410/github_icon_cggrvy.png" alt="GitHub" style={{ width: "30px", height: "30px" }} />
                    </div>
                    {showPopup === 'github' && (
                        <div>
                            <input type="text" placeholder="Enter GitHub URL" onChange={(e) => setGithubLink(e.target.value)} />
                            <button onClick={() => handleSaveLink('github', githubLink)}>Save</button>
                        </div>
                    )}
                </div>
            </div>

            <div style={{ backgroundColor: "#7D6EEB", padding: "10px", borderRadius: "10px" }}>
                <img src="https://res.cloudinary.com/dw2h36vj0/image/upload/v1698977476/streak_2_1_v3ufqv.png" alt="Streak" style={{ width: "60px", height: "60px", borderRadius: "50%", margin: "0 auto", display: "block" }} />
                <span style={{ fontSize: "18px", color: "#F8F803", display: "block", marginTop: "10px" }}>13 day's</span>
                <p style={{ color: "#FFFFFF", fontWeight: "bold" }}>KodeStreak!</p>
            </div>
        </div>
    );
};

export default ProfileCard;
