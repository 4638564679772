import React from "react";
import AboutHero from "./AboutHero";
import OurValues from "./OurValues";
import OurMission from "./OurMission";
import OurCompany from "./OurCompany";
import OutTeam from "./OutTeam";
import FAQ from "../FAQ";
import { About_FAQ_data } from "../../data/aboutFAQ";
import GridPattern from '../magicui/animated-grid-pattern'
import { cn } from '../magicui/lib/utils'
import { BackgroundBeams } from "../ui/background-beams";
import { TimelineComponent } from './TimeLineComponent'
// import styles from "./About.module.css";
// import Person1 from "./assets/Person1.png";
// import Person2 from "./assets/Person2.png";
// import Person3 from "./assets/Person3.png";
// import Person4 from "./assets/Person4.png";
// import Ranjith from "./AboutUs/assets/Ranjith.png";
// import LinkedInIcon from "./assets/linkedin-icon.png";
// import WhatsAppIcon from "./assets/whatsapp-icon.png";
// import GmailIcon from "./assets/gmail-icon.png";

const About = () => {
  return (
    <div className="font-Inter">
      <div className="relative flex  w-screen overflow-hidden">
      <AboutHero/>
      
      <GridPattern
        numSquares={100}
        maxOpacity={0.15}
        duration={3}
        repeatDelay={1}
        className={cn(
          "[mask-image:radial-gradient(800px_circle_at_center,white,transparent)]",
          "inset-x-0   skew-y-0 opacity-50 ",
        )}
      />
    </div>
      <TimelineComponent/>
    <div>
      <div className=" w-full rounded-md bg-neutral-950 relative flex flex-col items-center justify-center antialiased">
        <div className="relative ">
            <OurValues/>  
        </div>
        <BackgroundBeams />
      </div>
    </div>
      <OurMission/>
      <OurCompany/>
      <OutTeam/>
      <FAQ data={About_FAQ_data}/>
    </div>
    // <div className={`h-[480px] ${styles.back}`}>
    //   <div className={styles.heading}>
    //     <p>About Us</p>
    //   </div>
    //   <div
    //     className={`${styles.text} lg:px-40 md:px-10 px-10 py-3 font-medium`}
    //   >
    //     <p>
    //       At KodeKamp, our purpose is clear: to empower engineering students
    //       with essential technical skills and real-world experiences. We are
    //       committed to achieving this mission through comprehensive programs
    //       that include Campus Recruitment Training (CRT), Internships, and
    //       Real-world projects.
    //     </p>
    //   </div>
    //   {/* <div className={`${styles.team} pt-7 pb-10`}>
    //     <p>Our Team</p>
    //   </div>
    //   <div className={styles.maincard}>
    //     <div className={styles.card}>
    //       <div className={styles.box1}>
    //         <div className={styles.rectangle1}>
    //           <p>Founder & CEO</p>
    //         </div>
    //       </div>
    //       <div className={styles.box}>
    //         <div className={styles.rectangle} />
    //       </div>
    //       <div className={styles.imageBack}>
    //         <img
    //           src={Person1}
    //           alt="Person 1"
    //           className={styles.cardimage}
    //         ></img>
    //       </div>
    //       <div className={styles.details}>
    //         <h3>Sivasai Mundlapati</h3>
    //         <ul>
    //           <li>
    //             <a
    //               href="https://www.linkedin.com/in/sivasai-mundlapati-727b77158/"
    //               className={styles.iconlink}
    //             >
    //               <img
    //                 src={LinkedInIcon}
    //                 alt="LinkedIn"
    //                 className={styles.customIcon}
    //               />
    //             </a>
    //           </li>
    //           <li>
    //             <a
    //               href="https://wa.me/+918790709912"
    //               className={styles.iconlink}
    //             >
    //               <img
    //                 src={WhatsAppIcon}
    //                 alt="WhatsApp"
    //                 className={styles.customIcon}
    //               />
    //             </a>
    //           </li>
    //           <li>
    //             <a
    //               href="mailto:sivaaliassai1435@gmail.com"
    //               className={styles.iconlink}
    //             >
    //               <img
    //                 src={GmailIcon}
    //                 alt="Gmail"
    //                 className={styles.customIcon}
    //               />
    //             </a>
    //           </li>
    //         </ul>
    //       </div>
    //     </div>

    //     <div className={styles.card}>
    //       <div className={styles.box1}>
    //         <div className={styles.rectangle1}>
    //           <p>Sales & CFO</p>
    //         </div>
    //       </div>
    //       <div className={styles.box}>
    //         <div className={styles.rectangle} />
    //       </div>
    //       <div className={styles.imageBack}>
    //         <img
    //           src={Person2}
    //           alt="Person 2"
    //           className={styles.cardimage}
    //         ></img>
    //       </div>
    //       <div className={styles.details}>
    //         <h3>Balaji Mutyala</h3>
    //         <ul>
    //           <li>
    //             <a
    //               href="https://www.linkedin.com/in/balaji-mutyala-7b9a3916b/"
    //               className={styles.iconlink}
    //             >
    //               <img
    //                 src={LinkedInIcon}
    //                 alt="LinkedIn"
    //                 className={styles.customIcon}
    //               />
    //             </a>
    //           </li>
    //           <li>
    //             <a
    //               href="https://wa.me/+916309538237"
    //               className={styles.iconlink}
    //             >
    //               <img
    //                 src={WhatsAppIcon}
    //                 alt="WhatsApp"
    //                 className={styles.customIcon}
    //               />
    //             </a>
    //           </li>
    //           <li>
    //             <a
    //               href="mailto:balajimutyala2606@gmail.com"
    //               className={styles.iconlink}
    //             >
    //               <img
    //                 src={GmailIcon}
    //                 alt="Gmail"
    //                 className={styles.customIcon}
    //               />
    //             </a>
    //           </li>
    //         </ul>
    //       </div>
    //     </div>

    //     <div className={styles.card}>
    //       <div className={styles.box1}>
    //         <div className={styles.rectangle1}>
    //           <p>Developer</p>
    //         </div>
    //       </div>
    //       <div className={styles.box}>
    //         <div className={styles.rectangle} />
    //       </div>
    //       <div className={styles.imageBack}>
    //         <img
    //           src={Person3}
    //           alt="Person 3"
    //           className={styles.cardimage}
    //         ></img>
    //       </div>{" "}
    //       <div className={styles.details}>
    //         <h3>Ranjith Seekolu</h3>
    //         <ul>
    //           <li>
    //             <a
    //               href="https://www.linkedin.com/in/ranjith-kumar-66731615a/"
    //               className={styles.iconlink}
    //             >
    //               <img
    //                 src={LinkedInIcon}
    //                 alt="LinkedIn"
    //                 className={styles.customIcon}
    //               />
    //             </a>
    //           </li>
    //           <li>
    //             <a
    //               href="https://wa.me/+919182176879"
    //               className={styles.iconlink}
    //             >
    //               <img
    //                 src={WhatsAppIcon}
    //                 alt="WhatsApp"
    //                 className={styles.customIcon}
    //               />
    //             </a>
    //           </li>
    //           <li>
    //             <a
    //               href="mailto:ranjithkumarseekolu@gmai.com"
    //               className={styles.iconlink}
    //             >
    //               <img
    //                 src={GmailIcon}
    //                 alt="Gmail"
    //                 className={styles.customIcon}
    //               />
    //             </a>
    //           </li>
    //         </ul>
    //       </div>
    //     </div>

    //     <div className={styles.card}>
    //       <div className={styles.box1}>
    //         <div className={styles.rectangle1}>
    //           <p>UI/UX Designer</p>
    //         </div>
    //       </div>
    //       <div className={styles.box}>
    //         <div className={styles.rectangle} />
    //       </div>
    //       <div className={styles.imageBack}>
    //         <img
    //           src={Person4}
    //           alt="Person 4"
    //           className={styles.cardimage}
    //         ></img>
    //       </div>
    //       <div className={styles.details}>
    //         <h3>Muzammil Shaik</h3>
    //         {"\n"}
    //         {"\n"}
    //         <ul>
    //           <li>
    //             <a
    //               href="https://www.linkedin.com/in/muzammil-shaik-183569277/"
    //               className={styles.iconlink}
    //             >
    //               <img
    //                 src={LinkedInIcon}
    //                 alt="LinkedIn"
    //                 className={styles.customIcon}
    //               />
    //             </a>
    //           </li>
    //           <li>
    //             <a
    //               href="https://wa.me/+917032371104"
    //               className={styles.iconlink}
    //             >
    //               <img
    //                 src={WhatsAppIcon}
    //                 alt="WhatsApp"
    //                 className={styles.customIcon}
    //               />
    //             </a>
    //           </li>
    //           <li>
    //             <a
    //               href="mailto:imshaikmuzammil@gmail.com"
    //               className={styles.iconlink}
    //             >
    //               <img
    //                 src={GmailIcon}
    //                 alt="Gmail"
    //                 className={styles.customIcon}
    //               />
    //             </a>
    //           </li>
    //         </ul>
    //       </div>
    //     </div>
    //   </div> */}
    // </div>
  );
};

export default About;
