const  GlobeIcon = () => (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="4" y="4" width="48" height="48" rx="24" fill="#7F56D9"/>
<rect x="4" y="4" width="48" height="48" rx="24" stroke="#6941C6" stroke-width="8"/>
<path d="M38 28C38 33.5228 33.5228 38 28 38M38 28C38 22.4772 33.5228 18 28 18M38 28H18M28 38C22.4772 38 18 33.5228 18 28M28 38C30.5013 35.2616 31.9228 31.708 32 28C31.9228 24.292 30.5013 20.7384 28 18M28 38C25.4987 35.2616 24.0772 31.708 24 28C24.0772 24.292 25.4987 20.7384 28 18M18 28C18 22.4772 22.4772 18 28 18" stroke="white" stroke-width="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>


)
const InstantIcon = () => (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="4" y="4" width="48" height="48" rx="24" fill="#7F56D9"/>
<rect x="4" y="4" width="48" height="48" rx="24" stroke="#6941C6" stroke-width="8"/>
<path d="M29.0001 18L20.0936 28.6879C19.7448 29.1064 19.5703 29.3157 19.5677 29.4925C19.5654 29.6461 19.6338 29.7923 19.7534 29.8889C19.8909 30 20.1633 30 20.7081 30H28.0001L27.0001 38L35.9067 27.3121C36.2555 26.8936 36.4299 26.6843 36.4325 26.5075C36.4348 26.3539 36.3664 26.2077 36.2468 26.1111C36.1094 26 35.8369 26 35.2921 26H28.0001L29.0001 18Z" stroke="white" stroke-width="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

)

const ReportIcon = () => (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="4" y="4" width="48" height="48" rx="24" fill="#7F56D9"/>
<rect x="4" y="4" width="48" height="48" rx="24" stroke="#6941C6" stroke-width="8"/>
<path d="M27 19H23.8C22.1198 19 21.2798 19 20.638 19.327C20.0735 19.6146 19.6146 20.0735 19.327 20.638C19 21.2798 19 22.1198 19 23.8V32.2C19 33.8802 19 34.7202 19.327 35.362C19.6146 35.9265 20.0735 36.3854 20.638 36.673C21.2798 37 22.1198 37 23.8 37H32.2C33.8802 37 34.7202 37 35.362 36.673C35.9265 36.3854 36.3854 35.9265 36.673 35.362C37 34.7202 37 33.8802 37 32.2V29M28 24H32V28M31.5 19.5V18M35.4393 20.5607L36.5 19.5M36.5103 24.5H38.0103M19 29.3471C19.6519 29.4478 20.3199 29.5 21 29.5C25.3864 29.5 29.2653 27.3276 31.6197 24" stroke="white" stroke-width="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>


)
const ConnectIcon = () => (
<svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="4" y="4" width="48" height="48" rx="24" fill="#7F56D9"/>
<rect x="4" y="4" width="48" height="48" rx="24" stroke="#6941C6" stroke-width="8"/>
<path d="M32 24V21L35 18L36 20L38 21L35 24H32ZM32 24L28 27.9999M38 28C38 33.5228 33.5228 38 28 38C22.4772 38 18 33.5228 18 28C18 22.4772 22.4772 18 28 18M33 28C33 30.7614 30.7614 33 28 33C25.2386 33 23 30.7614 23 28C23 25.2386 25.2386 23 28 23" stroke="white" stroke-width="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>


)

const ConnectToolsIcon = () => (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="4" y="4" width="48" height="48" rx="24" fill="#7F56D9"/>
<rect x="4" y="4" width="48" height="48" rx="24" stroke="#6941C6" stroke-width="8"/>
<path d="M25 25V22C25 20.3431 23.6569 19 22 19C20.3431 19 19 20.3431 19 22C19 23.6569 20.3431 25 22 25H25ZM25 25V31M25 25H31M25 31V34C25 35.6569 23.6569 37 22 37C20.3431 37 19 35.6569 19 34C19 32.3431 20.3431 31 22 31H25ZM25 31H31M31 31H34C35.6569 31 37 32.3431 37 34C37 35.6569 35.6569 37 34 37C32.3431 37 31 35.6569 31 34V31ZM31 31V25M31 25V22C31 20.3431 32.3431 19 34 19C35.6569 19 37 20.3431 37 22C37 23.6569 35.6569 25 34 25H31Z" stroke="white" stroke-width="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

)

const OurPeopleIcon = () => (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="4" y="4" width="48" height="48" rx="24" fill="#7F56D9"/>
<rect x="4" y="4" width="48" height="48" rx="24" stroke="#6941C6" stroke-width="8"/>
<path d="M36.9996 27.5C36.9996 32.1944 33.194 36 28.4996 36C27.4228 36 26.3928 35.7998 25.4448 35.4345C25.2714 35.3678 25.1848 35.3344 25.1159 35.3185C25.0481 35.3029 24.999 35.2963 24.9295 35.2937C24.8588 35.291 24.7813 35.299 24.6262 35.315L19.5052 35.8444C19.0169 35.8948 18.7728 35.9201 18.6288 35.8322C18.5034 35.7557 18.4179 35.6279 18.3952 35.4828C18.3691 35.3161 18.4857 35.1002 18.7191 34.6684L20.3547 31.6408C20.4894 31.3915 20.5568 31.2668 20.5873 31.1469C20.6174 31.0286 20.6247 30.9432 20.6151 30.8214C20.6053 30.6981 20.5512 30.5376 20.443 30.2166C20.1555 29.3636 19.9996 28.45 19.9996 27.5C19.9996 22.8056 23.8052 19 28.4996 19C33.194 19 36.9996 22.8056 36.9996 27.5Z" stroke="white" stroke-width="2" strokeLinecap="round" strokeLinejoin="round"/>
<path fillRule="evenodd" clip-rule="evenodd" d="M28.4965 24.9493C27.5968 23.9104 26.0965 23.631 24.9692 24.5822C23.842 25.5335 23.6833 27.124 24.5685 28.2491C25.117 28.9461 26.4935 30.2191 27.4616 31.087C27.8172 31.4057 27.995 31.5651 28.2084 31.6293C28.3914 31.6844 28.6017 31.6844 28.7847 31.6293C28.9981 31.5651 29.1759 31.4057 29.5315 31.087C30.4996 30.2191 31.8761 28.9461 32.4246 28.2491C33.3098 27.124 33.1705 25.5235 32.0238 24.5822C30.8772 23.641 29.3963 23.9104 28.4965 24.9493Z" stroke="white" stroke-width="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

)
export { GlobeIcon, InstantIcon,ReportIcon,ConnectIcon,ConnectToolsIcon,OurPeopleIcon }