import React from 'react'
import BenifitsCard from './BenifitsCard'
import { BenifitsData } from './BenifitsData'


const InternshipBenefits = () => {
  return (
    <div className='h-auto w-screen font-Inter px-7 lg:px-20 py-12'>
        <div className='flex flex-col justify-center items-center gap-3 py-12'>
          <h1 className='text-sm font-normal text-[#6941C6]'>What you will get</h1>
          <h1 className='text-2xl  lg:text-4xl font-semibold text-[#101828] text-center'>Empower Your Growth with KAMPKODE Internship</h1>
          <p className='text-[#475467] text-center font-normal text-xl'>Dive into impactful projects that enhance your professional skills and broaden your knowledge </p>
        </div>
        <div className='grid grid-cols-1 lg:grid-cols-3 gap-4  content-center w-full'>
            {BenifitsData.map((item,index) =>
            <BenifitsCard key={index} Icon={item.Icon} title={item.title} description={item.description}/>
            )}
            
        </div>  
    </div>
  )
}

export default InternshipBenefits