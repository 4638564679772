// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseProdConfig = {

// };

const firebaseProdConfig = {
  apiKey: "AIzaSyB4UzHKq9ESHqxkhWb11ys1ELKpkmdfv48",
  authDomain: "kampkode-ecb6c.firebaseapp.com",
  projectId: "kampkode-ecb6c",
  storageBucket: "kampkode-ecb6c.appspot.com",
  messagingSenderId: "1034287864408",
  appId: "1:1034287864408:web:aac901cd5b26fe46f0482d",
  measurementId: "G-3546QKPDW1",
};

const firebaseTestConfig = {
  apiKey: "AIzaSyB4UzHKq9ESHqxkhWb11ys1ELKpkmdfv48",
  authDomain: "kampkode-ecb6c.firebaseapp.com",
  projectId: "kampkode-ecb6c",
  storageBucket: "kampkode-ecb6c.appspot.com",
  messagingSenderId: "1034287864408",
  appId: "1:1034287864408:web:aac901cd5b26fe46f0482d",
  measurementId: "G-3546QKPDW1",
};
// const firebaseTestConfig = {
//   apiKey: "AIzaSyBWYaIFxlMwOEnlx-qPTrnrwy16Own4Ngs",
//   authDomain: "kodekamp-dev-7f75c.firebaseapp.com",
//   projectId: "kodekamp-dev-7f75c",
//   storageBucket: "kodekamp-dev-7f75c.appspot.com",
//   messagingSenderId: "628860363229",
//   appId: "1:628860363229:web:7ac2ee21cfbb951a1c9634",
//   measurementId: "G-ZSQ95YQJ9F",
// };

// Initialize Firebase
const app = initializeApp(
  process.env.REACT_APP_ENV ? firebaseProdConfig : firebaseTestConfig
);
// const analytics = process.env.REACT_APP_ENV ? getAnalytics(app) : {};

// Initialize Firebase
const db = getFirestore(app);

// Initialize Authentication
const auth = getAuth(app);

// Create a GoogleAuthProvider instance
const provider = new GoogleAuthProvider();

export { db, auth, provider };
