import React from "react";

const ProgressCard = () => {
  return (
    <div style={{ color: "#1E003D", width: "800px" }}>
    <h2 style={{ fontSize: "24px", marginBottom: "20px" }}>Today's Progress</h2>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "30px"
        }}
      >
        {["2 Quizzes", "3 SkillAssignments", "6 ReadingMaterial"].map(
          (text) => (
            <div
              style={{
                backgroundColor: "#FFFFFF",
                border: "2px solid #7D6EEB",
                padding: "15px",
                borderRadius: "10px",
                width: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <span
                style={{
                  fontSize: "24px",
                  color: "#7D6EEB",
                  fontFamily: "Poppins",
                  marginRight: "10px"
                }}
              >
                {text.split(" ")[0]}
              </span>
              <div>
                <p
                  style={{
                    color: "#7D6EEB",
                    fontFamily: "Poppins",
                    margin: "0"
                  }}
                >
                  {text.split(" ")[1]}
                </p>
                <p
                  style={{
                    color: "#1E003D",
                    fontFamily: "Poppins",
                    margin: "0"
                  }}
                >
                  completed
                </p>
              </div>
            </div>
          )
        )}
      </div>

      <h2 style={{ fontSize: "24px", marginBottom: "20px" }}>Total Progress</h2>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        {["15 Quizzes", "9 SkillAssignments", "21 ReadingMaterial"].map(
          (text) => (
            <div
              style={{
                backgroundColor: "#FFFFFF",
                border: "2px solid #7D6EEB",
                padding: "15px",
                borderRadius: "10px",
                width: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <span
                style={{
                  fontSize: "24px",
                  color: "#7D6EEB",
                  fontFamily: "Poppins",
                  marginRight: "10px"
                }}
              >
                {text.split(" ")[0]}
              </span>
              <div>
                <p
                  style={{
                    color: "#7D6EEB",
                    fontFamily: "Poppins",
                    margin: "0"
                  }}
                >
                  {text.split(" ")[1]}
                </p>
                <p
                  style={{
                    color: "#1E003D",
                    fontFamily: "Poppins",
                    margin: "0"
                  }}
                >
                  completed
                </p>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ProgressCard;
