import React from "react";
import Navbar from "./Navbar";
import { Outlet } from "react-router-dom";
import FooterPage from "./Footer/Footerpage";

const LayoutWithoutFooter = () => {
  return (
    <div>
      <Navbar />
      <Outlet />
      <FooterPage/>
    </div>
  );
};

export default LayoutWithoutFooter;
