import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { isUserAdmin } from "../../uiHelper";
import MonacoEditor from "./MonacoEditor";

const Class = () => {
  const [isTeacher, setIsTeacher] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const classId = queryParams.get("classId");

  const user = useSelector((state) => state.currentUserReducer);

  useEffect(() => {
    setIsTeacher(user && isUserAdmin(user.email));
  }, [user]);

  return (
    <div>
      <div className="w-full text-center bg-customThinPurple">
        <div className="font-semibold text-6xl pt-6">Live Classes</div>
        <div className="py-8 lg:px-40 md:px-10 px-10 font-medium">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s,and scrambled it to make a type specimen book.
        </div>
      </div>
      <div className="text-center">
        <h1 className="text-center bg-customLightPurple text-white rounded-md p-2 relative bottom-4 inline-block">
          Class Id: {classId}
        </h1>
      </div>
      {/* <h1 className="text-center font-bold">Class Id: {classId}</h1> */}
      <MonacoEditor isTeacher={isTeacher} classId={classId} />
    </div>
  );
};

export default Class;
