import React from "react";
// import OrbitComponent from "./HeroSection/OrbitComponent";
import { OrbitComponent } from "./HeroSection/OrbitComponent";
import { VelocityScroll } from "./magicui/scroll-based-velocity";
import { cn } from "./magicui/lib/utils";
import DotPattern from "./magicui/dot-pattern";

// import KampKodeVideo from "../assets/landing_page.mp4";

const MainSection = () => {
  return (
    <>
      {/* <video autoPlay loop muted className="w-full relative -top-20">
        <source src={KampKodeVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video> */}
      <div className="">
        <OrbitComponent />
      </div>

      <div className="relative flex h-fit w-full flex-col items-center justify-center overflow-hidden rounded-lg  bg-background pb-2">
      
      <VelocityScroll
      text="THRIVE Together TRUST Like Family LEARN Effortlessly"
      default_velocity={2}
      className=" relative z-10  font-display text-center text-2xl font-bold tracking-[-0.02em] text-[#7e6fea] drop-shadow-sm dark:text-white md:text-4xl md:leading-[3rem]"
      
      /> 
      <DotPattern
        className={cn(
          "absolute inset-0 z-0  [mask-image:radial-gradient(circle_at_center,white,transparent)]",
        )}
      />
      <div className="z-10 pointer-events-none absolute inset-y-0 left-0 w-1/3 bg-gradient-to-r from-white dark:from-background"></div>
      <div className="z-10 pointer-events-none absolute inset-y-0 right-0 w-1/3 bg-gradient-to-l from-white dark:from-background"></div>
      <div className=" pointer-events-none absolute inset-x-0 top-0 h-5 bg-gradient-to-b from-white dark:from-background"></div>
      <div className=" pointer-events-none absolute inset-x-0 bottom-0 h-5 bg-gradient-to-t from-white dark:from-background"></div>
    </div>
    
    </>
  );
};

export default MainSection;
