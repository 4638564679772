import React, { useState, ChangeEvent, FormEvent } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { postJob } from "../../services/job.service";
import { Flag,X } from "lucide-react";

interface PostJobFormProps {
    closePopup: () => void;
}

interface FormData {
    applyLink: string;
    companyLogo: string;
    companyName: string;
    employment: string;
    jobDescription: string;
    jobTitle: string;
    location: string;
}

const PostJobForm: React.FC<PostJobFormProps> = ({ closePopup }) => {
    const [formData, setFormData] = useState<FormData>({
        jobTitle: "",
        companyName: "",
        applyLink: "",
        location: "",
        employment: "Full time",
        companyLogo: "",
        jobDescription: "",
    });

    const handleChange = (
        e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleEditorChange = (content: string) => {
        setFormData({ ...formData, jobDescription: content });
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            await postJob(formData);
        } catch (error) {
            console.error("Error submitting form:", error);
        }
        closePopup();
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-xl">
                <div className="flex justify-between items-center mb-2">
                    <div className="flex items-center">
                        {/* <img src="/path-to-your-flag-icon.png" alt="flag icon" className="mr-2" /> */}
                        <Flag/>
                    </div>
                    <button
                        className="text-gray-500 hover:text-gray-700"
                        onClick={closePopup}
                    >
                        <X/>
                    </button>
                </div>
                <h1 className="text-xl font-semibold">Post a Job Opportunity</h1>
                <p className="text-gray-600 mb-4">Easily share new job openings with our community.</p>
                <form onSubmit={handleSubmit}>
                    <div className="mb-1">
                        <label className="block text-sm font-medium text-gray-700">Job Title*</label>
                        <input
                            type="text"
                            name="jobTitle"
                            value={formData.jobTitle}
                            onChange={handleChange}
                            required
                            className="mt-1 block w-full p-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </div>
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 mb-1">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Company Name*</label>
                            <input
                                type="text"
                                name="companyName"
                                value={formData.companyName}
                                onChange={handleChange}
                                required
                                className="mt-1 block w-full p-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Apply link*</label>
                            <input
                                type="url"
                                name="applyLink"
                                value={formData.applyLink}
                                onChange={handleChange}
                                required
                                className="mt-1 block w-full p-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 mb-1">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Location*</label>
                            <input
                                type="text"
                                name="location"
                                value={formData.location}
                                onChange={handleChange}
                                required
                                className="mt-1 block w-full p-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Employment*</label>
                            <select
                                name="employment"
                                value={formData.employment}
                                onChange={handleChange}
                                className="mt-1 block w-full p-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            >
                                <option value="Full time">Full time</option>
                                <option value="Part time">Part time</option>
                                <option value="Contract">Contract</option>
                            </select>
                        </div>
                    </div>
                    <div className="mb-1">
                        <label className="block text-sm font-medium text-gray-700">Company logo link*</label>
                        <input
                            type="url"
                            name="companyLogo"
                            value={formData.companyLogo}
                            onChange={handleChange}
                            required
                            className="mt-1 block w-full p-1.5 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">Job Description*</label>
                        <Editor
                            apiKey="v0dsmhnm9byper8j2wmznz009nc36bj6r9id55d81rmwafqf"
                            init={{
                                height: 300,
                                menubar: false,
                                plugins: [
                                    "advlist autolink lists link image charmap print preview anchor",
                                    "searchreplace visualblocks code fullscreen",
                                    "insertdatetime media table paste code help wordcount",
                                ],
                                toolbar:
                                    "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help",
                            }}
                            onEditorChange={handleEditorChange}
                        />
                    </div>
                    <div className="flex justify-between">
                        <button
                            type="button"
                            onClick={closePopup}
                            className="mr-3 max-w-m inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Back
                        </button>
                        <button
                            type="submit"
                            className="inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Post Job
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PostJobForm;
