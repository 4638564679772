"use client";

import React, { ReactNode } from 'react';
import { motion, Variants } from "framer-motion";
import { cn } from "../magicui/lib/utils";

interface WordFadeInProps {
  words: ReactNode;
  className?: string;
  delay?: number;
  variants?: Variants;
}

export default function WordFadeIn({
  words,
  delay = 0.15,
  variants = {
    hidden: { opacity: 0 },
    visible: (i: any) => ({
      y: 0,
      opacity: 1,
      transition: { delay: i * delay },
    }),
  },
  className,
}: WordFadeInProps) {
  const _words = React.Children.toArray(words).reduce<ReactNode[]>((acc, word) => {
    if (typeof word === 'string') {
      return acc.concat(word.split(' ').map((w, idx) => <span key={`${w}-${idx}`}>{w} </span>));
    }
    return acc.concat(word);
  }, []);

  return (
    <motion.h1
      variants={variants}
      initial="hidden"
      animate="visible"
      className={cn(
        "font-display text-center text-2xl md:text-3xl lg:text-4xl font-bold tracking-[-0.02em] text-black drop-shadow-sm dark:text-white md:leading-[5rem]",
        className,
      )}
    >
      {_words.map((word, i) => (
        <motion.span key={i} variants={variants} custom={i} className='inline-block'>
          {word as ReactNode}
        </motion.span>
      ))}
    </motion.h1>
  );
}
