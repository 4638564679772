import { GlobeIcon,ConnectIcon,InstantIcon,ConnectToolsIcon,ReportIcon, OurPeopleIcon } from "../assets/ourValuesIcons" 
const ourValuesData = [
    {
        Icon : ConnectToolsIcon,
        tittle : "Interest-Driven Education",
        description : "We focus on each student's passions, guiding them in their chosen domain to build a brighter future."
    },
    {
        Icon : OurPeopleIcon,
        tittle : 'Student-Centered Care',
        description : 'We treat every student as our own, providing personalized attention and support.'
    },
    {
        Icon : InstantIcon,
        tittle : 'Cultivating a Growth Mindset',
        description : 'We instill a mindset of continuous improvement, inspiring students to always strive for excellence and growth.'
    },
    {
        Icon : ConnectIcon,
        tittle : 'Developing Practical Skills',
        description : 'We equip students with real-world skills that go beyond academics, preparing them for lasting success.'
    },
    {
        Icon : GlobeIcon,
        tittle : 'Inspiring Creative Thinking',
        description : 'We encourage students to think outside the box, boosting their confidence to take on new challenges.'
    },
    {
        Icon : ReportIcon,
        tittle : 'Empowering Uniqueness',
        description : 'We help students stand out from the crowd by nurturing their individual strengths and talents.'
    },
]
export default ourValuesData