import React from 'react';
import Card from './Card';
import { Project } from '@/types/types';
import { ArrowLeft, ArrowRight } from 'lucide-react';

interface DisplayCardsProps {
  Projects: Project[];
  category : string;
}

const DisplayCards: React.FC<DisplayCardsProps> = ({ Projects, category }) => {
  // console.log('display CARD DATA')
  // console.log(Projects)
  const containerRef = React.useRef<HTMLDivElement>(null);

  if (Projects.length === 0) {
    return <div className="text-center">Coming Soon</div>;
  }
  const scroll = (direction: 'left' | 'right') => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: direction === 'left' ? -200 : 200, behavior: 'smooth' });
    }
  };
  return (
    <div className='relative md:px-5'>

    
      <button
        className="absolute left-1 top-48 transform -translate-y-1/2 bg-white shadow-md h-8 rounded-full px-2 z-10 font-bold text-xl hidden md:block"
        onClick={() => scroll('left')}
      > <ArrowLeft/> </button>
    <div className=" flex gap-4 overflow-x-auto no-scrollbar py-3" ref={containerRef}>
      {Projects.map((project, index) => (
        <Card
        category={category}
        
          key={index}
          {...project}
          action="View Project"
        />
      ))}
    </div>
      <button
        className="absolute right-1 top-48 transform -translate-y-1/2 bg-white shadow-md h-8 rounded-full px-2 z-10 font-bold text-xl hidden md:block"
        onClick={() => scroll('right')}
      > <ArrowRight/> </button>
    </div>
  );
};

export default DisplayCards;
