import React from 'react';

const PrivacyPolicy = () => {
  const sections = [
    
    { title: 'How do we use the information?', content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla amet, fugit accusantium magni aliquam nostrum dolor praesentium sit perspiciatis facilis.' },
    { title: 'How do we use cookies and tracking?', content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores culpa fugit obcaecati eligendi illum eum doloremque porro tenetur eos unde!' },
    { title: 'How long do we keep your information?', content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores culpa fugit obcaecati eligendi illum eum doloremque porro tenetur eos unde!' },
    { title: 'How do we keep your information safe?', content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores culpa fugit obcaecati eligendi illum eum doloremque porro tenetur eos unde!' },
    { title: 'What are your privacy rights?', content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores culpa fugit obcaecati eligendi illum eum doloremque porro tenetur eos unde!' },
    { title: 'How can you contact us about this policy?', content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores culpa fugit obcaecati eligendi illum eum doloremque porro tenetur eos unde!' },
  ];

  return (
    <div>
      <div className='bg-themepruple text-white h-80 w-screen flex'>
        <div className='m-auto text-center'>
          <p className='text-base font-medium'>Current as of 20 Jan 2022</p>
          <h1 className='text-xl md:text-3xl lg:text-5xl font-semibold my-2'>Privacy Policy</h1>
          <p className='text-base pt-3 font-light'>We use cookies to improve your experience. Here's more info.</p>
        </div>
      </div>

      <div className='py-24 px-80'>
        <p className='mb-5'>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Id tenetur debitis voluptatibus quos quo ipsam corrupti illo...
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet ab error laboriosam minima dolore dignissimos at quas...
        </p>
        <div className='mt-10'>

<h1 className='font-medium text-3xl my-3'>What Information do we collect?</h1>
<p className='mb-5'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores culpa fugit obcaecati eligendi illum eum doloremque porro tenetur eos unde!</p>
<p className='mb-5'> Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla amet, fugit accusantium magni aliquam nostrum dolor praesentium sit perspiciatis facilis. Tempora molestiae tenetur incidunt exercitationem! Culpa non harum recusandae facere similique earum vitae aliquid eos, consectetur qui! Eum esse vel odit aut minus fugit voluptas, error earum quas officia impedit?</p>
<p className='mb-5'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio, laudantium assumenda nesciunt reiciendis quibusdam eaque sapiente maxime animi alias possimus, magnam delectus cum commodi illo?</p>
</div>

        {sections.map((section, index) => (
          <div className='mt-10' key={index}>
            <h1 className='font-medium text-3xl my-3'>{section.title}</h1>
            <p className='mb-5'>{section.content}</p>
          </div>
        ))}

       
        <ol className='list-decimal ml-5'>
          <li className='mb-2'>Step 1: Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
          <li className='mb-2'>Step 2: Nulla euismod felis ut sapien aliquet, at laoreet velit suscipit.</li>
          <li className='mb-2'>Step 3: Vivamus auctor sapien ac dui hendrerit, sit amet pulvinar nisi aliquam.</li>
          <li>Step 4: In tincidunt felis a leo suscipit vehicula.</li>
        </ol>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
