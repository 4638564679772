import React from "react";
import Dashboard from "./Dashboard/Dashboard";

const MyDashboard = () => {
  return (
    <div>
    <h1 className="text-white" style={{ color: '#ffffff' }}>Welcome to the Dashboard</h1>
    <h1 className="text-white" style={{ color: '#ffffff' }}>Welcome to the Dashboard</h1>
    <h1 className="text-white" style={{ color: '#ffffff' }}>Welcome to the Dashboard</h1>
      <Dashboard />
    </div>
  );
};

export default MyDashboard;
