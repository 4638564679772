import React, { useRef } from "react";
import styles from "./Card.module.scss";
import { motion, MotionValue, useScroll,useTransform } from "framer-motion";
import CustomButton from "./CustomButton";

interface CardProps {
  title: string;
  description: string;
  src: string;
  link: string;
  color: string;
  cta:string;
  i: number;
  progress:MotionValue<number>;
  range:number[];
  targetScale:number;

}

const Card = ({ title, description, src, link, color,cta, i, progress,range,targetScale }: CardProps) => {
  const containerRef = useRef<null | HTMLDivElement >(null);
  const { scrollYProgress } = useScroll({

    target: containerRef,

    offset: ['start end', 'start start']

  })
  const scale = useTransform(progress, range, [1, targetScale]);

  const imageScale = useTransform(scrollYProgress, [0, 1], [2, 1])
  return (
    <div className={styles.cardContainer} ref={containerRef}>
      <motion.div
        className={styles.card}
        style={{ backgroundColor: color,scale, top: `calc(-5vh + ${i * 25}px)` }}
      >
        <h2>{title}</h2>

        <div className={styles.body}>
          <div className={styles.description}>
            <p>{description}</p>

            <span>
              <CustomButton title={cta} link={link}/>
            </span>
          </div>

          <div className={styles.imageContainer}>
            <motion.div className={styles.inner} style={{scale:imageScale}}>
              <img src={`${src}`} alt="loading failed.."/>
            </motion.div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Card;
