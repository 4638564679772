export const About_FAQ_data = [
    {
      question: "How can I get in touch with KampKode?",
      answer:
        "You can connect with us through our contact form on the website, email us directly, or reach out via our social media channels. Visit the \"Contact Us\" section for more details..",
    },
    {
      question: "What are the core values of KampKode? ",
      answer:
        "KampKode is principally tailored for students, yet our programs welcome all individuals eager to enhance their skills and embark on a career in the technology sector. Whether you're looking to start or kickstart your journey in the tech industry, KampKode is the ideal platform for you.",
    },
    {
      question:
        "How does KampKode support students with different learning styles?",
      answer:
        "You'll work on real-world, industry-driven projects that are designed to build your skills and provide practical experience in your chosen field.",
    },
    {
      question:
        "How does KampKode ensure the quality of its programs?",
      answer:
        "Yes, absolutely! Applying for multiple internships increases your chances of finding the perfect fit for your career goals. We encourage you to explore all opportunities that interest you.",
    },
    {
      question:
        "How can I apply for an internship or training program?",
      answer:
        "Yes, all students who successfully complete their internship will receive a certificate of completion from KampKode .",
    },
    
    {
      question: "What types of programs does KampKode offer?",
      answer:
        "Students from colleges, both undergraduate and postgraduate, can apply for our internships.",
    },
    
  ];  
  