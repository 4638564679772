import React, { useEffect, useRef } from 'react'
import { projects } from './data'
import './Card.css'
import Card from './Card'
import styles from './Scroll.module.scss'
import { useScroll } from 'framer-motion'
import Lenis from '@studio-freight/lenis'

const CustomStickyScroll = () => {


  useEffect(() => {
    const lenis = new Lenis({
      duration: 1.2, // Adjust duration for smoothness
    });

    const raf = (time: number) => {
      lenis.raf(time);
      requestAnimationFrame(raf);
    };

    requestAnimationFrame(raf);

  }, []);

  const container = useRef(null);

  const { scrollYProgress } = useScroll({

    target: container,

    offset: ['start start', 'end end']

  })
  return (
       <main  ref={container} className={styles.main} >

      {

        projects.map( (project, i) => {
          const targetScale = 1 - ( (projects.length - i) * 0.05);
          return <Card key={`p_${i}`} i={i} {...project} progress={scrollYProgress} range={[i * .25, 1]} targetScale={targetScale}/>

        })

      }

    </main>
  )
}

export default CustomStickyScroll