export const projects = [
    {
      title: "Campus Recruitment Training (CRT) with KampKode",
      description: "Boost your career with KampKode's Campus Recruitment Training (CRT) program. Our expert-led curriculum prepares you to excel in campus placements with practical insights, progress tracking.",
      src: "./tarin.svg",
      link: "/our-services",
      cta:"Join Now",
      color: "#5133a0"
    },
    {
      title: "Internships with KampKode",
      description: "Kickstart your career with KampKode's internship opportunities. Gain hands-on experience, learn from industry experts, and track your progress in real time. Our affordable and effective internships help you develop the skills employers value most.",
      src: "./ssc1.svg",
      cta:"Apply Now",
      link: "/internships",
      color: "#7251bc"
    },
    {
      title: "Real-World Projects with KampKode",
      description: "Enhance your skills with KampKode's real-world project opportunities. Work on practical projects that prepare you for the challenges of the professional world. Learn from experts, monitor your progress, and build a portfolio that stands out in real world.",
      src: "./ssc2.svg",
      cta:"Start Now",
      link:"/projects",
      color: "#4930ad"
    },
    
  ]