import { useState, useEffect } from "react";
import axios from "axios";

const baseUrl = process.env.REACT_APP_ENV
  ? "https://asia-south1-kampkode-ecb6c.cloudfunctions.net/kampkodeCodeEditor-api"
  : "http://127.0.0.1:5001/kampkode-ecb6c/asia-south1/kampkodeCodeEditor-api";

interface Languages {
  id: number;
  name: string;
}

export const useFetchLanguages = () => {
  const [languages, setLanguages] = useState<Languages[]>([]);

  useEffect(() => {
    // Fetch languages from the backend
    const fetchLanguages = async () => {
      try {
        console.log("code editor");
        const response = await axios.get(`${baseUrl}/languages`);
        console.log(response);
        setLanguages(response.data);
      } catch (error) {
        console.error("Failed to fetch languages:", error);
      }
    };
    fetchLanguages();
  }, []);

  return languages;
};

// Add a delay function
const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const compileCode = async (code: string, language: number) => {
  try {
    const response = await axios.post(`${baseUrl}/compile`, { code, language });

    if (response.status === 200 && response.data.token) {
      let token = response.data.token;
      let result;

      // Polling with a delay of 2 seconds
      do {
        await delay(2000); // Wait for 2 seconds
        const resp = await axios.get(
          `${baseUrl}/getSubmission/?token=${token}`
        );
        result = resp.data;
        console.log("submissing : ", resp);
      } while (result.status_id === 1 || result.status_id === 2); // 1: In Queue, 2: Processing

      if (result.status_id === 6) {
        return `Compilation Error: ${result.compile_output}`;
      }
      if (result.stderr) {
        console.error("Error:", result.stderr);
        return `Runtime Error: ${result.stderr}`;
      } else {
        return result.stdout || "No output";
      }
    } else {
      throw new Error("Compilation failed or no token received.");
    }
  } catch (error) {
    console.error("Compilation failed:", error);
    return "Compilation failed";
  }
};
