import React from 'react'
import { Logos } from '../../../data/Logos'; 
import { DockComponent } from './DockComponent';
// import {
//     IconBrandTailwind,
//     IconBrandReact,
//     IconBrandAngular,
//     IconBrandVue,
//     IconBrandPython,
//   } from "@tabler/icons-react";

// const Technologies =[
//     {
//         name: 'Tailwind CSS',
//         icon: <IconBrandTailwind />
//     },
//     {
//         name: 'React',
//         icon: <IconBrandReact />
//     },
//     {
//         name: 'Angular',
//         icon: <IconBrandAngular />
//     },
//     {
//         name: 'Vue',
//         icon: <IconBrandVue />
//     },
//     {
//         name: 'Python',
//         icon: <IconBrandPython />
//     }
// ]
type TechStakProps = {
    technologies: string[];
}
const TechStack = ({technologies}:TechStakProps) => {
  return (
        <div className='min-w-64 border-t h-fit  px-1 sticky top-20'>
            <h1 className='text-[#7F56D9] font-medium text-xl my-6 '> Technologies used</h1>
                <div className='grid grid-cols-2 gap-5 px-2 mb-8'>
                    {technologies.map((tech,idx) => (
                        <div key={idx} className='flex gap-4 mx-3'>
                            <div className='h-6 w-6  scale-110 p-1 transform -translate-y-1'>
                            <Logos.DefaultIcon />
                            </div>
                            <span className='text-base min-w-fit'>{tech}</span>
                        </div>
                    ))}
                </div>
        </div>    
  )
}

export default TechStack


export const ShareSection = () =>{
    return (
        <div className='relative h-fit py-1 mt-4 md:hidden'>
            <h1 className='absolute -top-3  z-20 text-[#7F56D9] text-base font-semibold my-4 capitalize'>Share this project</h1>
            <DockComponent />
        </div>
    )
}
export const ContactSection = () => {
    return (
        <div className='px-1 text-center py-2 border-t md:hidden'>
            <h1 className='text-[#7F56D9] text-left text-base font-semibold my-2 capitalize'>Curious About Our Projects?</h1>
            <button className='w-full border border-[#344054] rounded-lg py-3 text-[#344054]  '>Email </button>
            <button className='w-full border rounded-lg py-3 bg-themepruple text-white my-4'>Chat with our Team</button>
        </div>
    )
}