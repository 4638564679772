import React from "react";
import { Link } from "react-router-dom";

const SkillAssignmentsData = [
  {
    title: "SQL",
    description:
      "Our SQL assignments will help you sharpen your database management skills and become a SQL expert.",
  },
  {
    title: "Java",
    description:
      "Excel in Java programming, from OOP to application development, with our tailored assignments.",
  },
  {
    title: "JavaScript",
    description:
      "Enhance skills, work with the DOM, and create dynamic web interfaces using our JavaScript assignments.",
  },
  {
    title: "DSA",
    description:
      "Optimize problem-solving skills and learn to design efficient algorithms with our DSA assignments.",
  },
  {
    title: "Python",
    description:
      "Enhance your programming skills through practical problem-solving with our Python assignments.",
  },
  {
    title: "C++",
    description:
      "Excel in C++ with our skill assignments and become a coding expert.",
  },
];

const SkillAssignments = () => {
  return (
    <div className="pt-[80px]">
      <div className="w-full text-center bg-customThinPurple pt-6">
        <div className="font-semibold text-6xl pt-8">Skill Assignments</div>
        <div className="py-8 lg:px-40 md:px-10 px-10 font-medium">
          Unlock the power of programming with our skill assignments. These
          assignments are carefully crafted to enhance your expertise and
          empower you with the knowledge and confidence you need to excel in
          programming languages.
        </div>
      </div>
      <div className="flex flex-wrap justify-around px-10 lg:px-20 py-10">
        {SkillAssignmentsData.map((skillAssignment, index) => {
          return (
            <Link
              to={`/skill-assignment/${skillAssignment.title}`}
              state={{
                title: skillAssignment.title,
                description: skillAssignment.description,
              }}
              key={index}
            >
              <div className="p-6 w-[400px] border border-customLightPurple rounded-2xl m-6 hover:bg-[#E4E4E4] cursor-pointer">
                <div className="text-center font-bold text-customLightPurple text-2xl pb-2">
                  {skillAssignment.title}
                </div>
                <div className="text-center text-[#808080]">
                  {skillAssignment.description}
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default SkillAssignments;
