import { Dispatch } from "redux";
import { CurrentUser } from "../types/types";

export const setUser = (user: CurrentUser, dispatch: Dispatch) => {
  dispatch(setCurrentUser(user));
  return {
    type: "AUTH_USER",
    payload: user,
  };
};

export const setCurrentUser = (data: CurrentUser) => {
  return {
    type: "FETCH_CURRENT_USER",
    payload: data,
  };
};
