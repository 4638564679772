import React, { useState, useRef } from 'react';

import SubCategoryList from './SubCategoryList';
import DisplayCards from './DisplayCards';
import {  ProjectDataType, Project, } from '@/types/types';
import { ArrowLeft, ArrowRight, ChevronUpIcon } from 'lucide-react';

const MobileCarousel = ({ Data }: { Data: ProjectDataType }) => {
  const subCategoriesContainerRef = useRef<HTMLDivElement>(null);
  // console.log('data in mobile carousel')
  // console.log(Data)

  const [selectedCategory, setSelectedCategory] = useState<string>('WebDevelopment');
  const [selectedSubCategory, setSelectedSubCategory] = useState<string | null>(null);

  const categories = Object.keys(Data); // Get top-level categories
  // console.log('KEYS OF CATEGORIES')
  // console.log(categories)
  const subCategories = selectedCategory ? Object.keys(Data[selectedCategory]) : [];

  const handleCategoryClick = (category: string) => {
    setSelectedCategory(category);
    setSelectedSubCategory(null); // Reset selected subcategory when category changes
  };

  // Gather all projects from the selected category and subcategory
  const getProjects = (): Project[] => {
    if (selectedSubCategory) {
      // If a subcategory is selected, return only its projects
      return Object.values(Data[selectedCategory][selectedSubCategory]);
    } else {
      // If no subcategory is selected, return all projects from the selected category
      return Object.values(Data[selectedCategory]).flatMap(subCat => Object.values(subCat));
    }
  };

  const projects = getProjects();
  const scroll = (ref: React.RefObject<HTMLDivElement>, direction: 'left' | 'right') => {
    if (ref.current) {
      ref.current.scrollBy({ left: direction === 'left' ? -320 : 320, behavior: 'smooth' });
    }
  };


  return (
    <div>
      {categories.map((category) => (
        <div key={category}>
          <div
            className="flex mx-3 justify-between items-center my-1 cursor-pointer py-2"
            onClick={() => handleCategoryClick(category)}
          >
            <h1 className="text-[#101828] text-xl font-semibold capitalize">
              {category}
            </h1>
            <ChevronUpIcon
              className={`transition-transform duration-300 ${selectedCategory === category ? 'rotate-180' : ''}`}
            />
          </div>
          <div>
            {selectedCategory === category && (
              <>
                <SubCategoryList
                  subCategories={subCategories}
                  selectedSubCategory={selectedSubCategory}
                  onSubCategoryClick={setSelectedSubCategory}
                />
                <div className="relative">
                  <button
                    className="absolute left-1 top-48 transform  -translate-y-1/2 bg-white shadow-md h-10 rounded-full p-1 z-10 font-bold text-xl"
                    onClick={() => scroll(subCategoriesContainerRef, 'left')}
                  >
                    <ArrowLeft />
                  </button>
                  <div
                    className="px-2 flex gap-2 overflow-x-auto no-scrollbar"
                    ref={subCategoriesContainerRef}
                  >
                    <DisplayCards category={selectedCategory} Projects={projects}  />
                  </div>
                  <button
                    className="absolute right-1 top-48 transform -translate-y-1/2 bg-white shadow-md rounded-full p-1 z-10 h-10 font-bold text-xl"
                    onClick={() => scroll(subCategoriesContainerRef, 'right')}
                  >
                    <ArrowRight />
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MobileCarousel;
