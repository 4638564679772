import React from "react";
import { CheckIcon, ChevronRightIcon } from "lucide-react";
import { AnimatedSubscribeButton } from "../magicui/animated-subscribe-button";

const NewsLetter = () => {
  return (
<div className="bg-[#3e1c96] text-white px-10 lg:px-24 py-8 flex md:justify-between md:items-center flex-col md:flex-row gap-4 md:gap-10 text-start w-full">
  <div>
    <h2 className="font-semibold text-[20px] py-1 text-[#F4F3FF]">Join Our Kamp</h2>
    <p className="font-light text-[#E9D7FE] py-1 mb-2 text-[16px]">
    We'll send you a weekly update with job opportunities, interview tips, and tricks. No spam, just valuable content!
    </p>
  </div>

  <div className="flex gap-2 flex-col md:flex-row w-full md:w-auto">
    <input
      type="email"
      name="email"
      id="email"
      placeholder="Enter your email"
      className="px-2 rounded-sm h-11 w-full md:w-64 py-1 text-black"
      required
    />
      <AnimatedSubscribeButton
      buttonColor="#7f70ed"
      buttonTextColor="#ffffff"
      subscribeStatus={false}
      initialText={
        <span className="group inline-flex items-center">
          Subscribe{" "}
          <ChevronRightIcon className="ml-1 h-4 w-4 transition-transform duration-300 group-hover:translate-x-1" />
        </span>
      }
      changeText={
        <span className="group inline-flex items-center">
          <CheckIcon className="mr-2 h-4 w-4" />
          Subscribed{" "}
        </span>
      }
    />
  </div>
</div>

  );
};

export default NewsLetter;
