
import React from 'react'
import InternshipHero from './heroSection/InternshipHero'
import InternshipsInfo from './Intershipcount/InternshipsInfo'
import InternshipsExplore from './ExploreInternships/InternshipsExplore'
import GridPattern from '../magicui/animated-grid-pattern'
import { cn } from '../magicui/lib/utils'
import InternshipBenefits from './internshipBenefits/InternshipBenefits'
import MarqueeSection from './MarqueeSection'
import FAQ from '../FAQ'
import { Internhsips_FAQ_data } from '../../data/InternshipFAQ'
import InternshipCertificate from './InternshipCertificate'
const IntershipsPage = () => {
  return (
    <div className="mt-16">
          
      
          <div className="relative flex  w-screen">
      <p className="z-10 whitespace-pre-wrap ">
      <InternshipHero />
      </p>
      <GridPattern
        numSquares={100}
        maxOpacity={0.1}
        duration={3}
        repeatDelay={1}
        className={cn(
          "[mask-image:radial-gradient(800px_circle_at_center,white,transparent)]",
          "inset-x-0 inset-y-[-30%]  skew-y-0 overflow-hidden h-[650px]",
        )}
      />
    </div>
    <InternshipsInfo/>
      
      <InternshipsExplore/>
      <div>
    <InternshipBenefits/>

      </div>

     <div className='h-fit bg-[#5133A0] py-16'>
      <MarqueeSection/>
      </div>
      <InternshipCertificate/>
      <FAQ data={Internhsips_FAQ_data}/>

    </div>
  )
}

export default IntershipsPage
