import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Clock, MapPin } from "lucide-react";
import { RootState, AppDispatch } from '@/actions/store'; // Adjust the import path as necessary
import { fetchJobDetails } from '../../actions/jobActions'; // Adjust the import path as necessary

const JobDetails: React.FC = () => {
    const { jobId } = useParams<{ jobId: string }>();
    const dispatch = useDispatch<AppDispatch>();
  
    const { job, loading, error } = useSelector((state: RootState) => state.job);

    useEffect(() => {
        // Only fetch if the job is not already in the state or if it's a different job
        if (jobId && (!job || job.id !== jobId)) {
          dispatch(fetchJobDetails(jobId));
        }
      }, [dispatch, jobId, job]);
  
    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;
    if (!job) return <div>Job not found</div>;
    
    return (
      <div className="container my-20 mx-auto p-6">
        <div className="border rounded-md p-6 shadow-md bg-white">
          <div className="flex items-center mb-6">
            <img src={job.companyLogo} alt={`${job.companyName} logo`} className="w-16 h-16 mr-4" />
            <div>
              <h1 className="text-2xl font-bold">{job.jobTitle}</h1>
              <p className="text-sm text-gray-600">{job.companyName}</p>
            </div>
          </div>
          <div className="text-sm text-gray-500 mb-6">
            <span className="flex items-center mb-2">
              <MapPin className="mr-2 text-slate-400" />
              {job.location}
            </span>
            <span className="flex items-center">
              <Clock className="mr-2 text-slate-400" />
              {job.employment}
            </span>
          </div>
          <div className="mb-6">
            <h2 className="text-lg font-semibold mb-2">Role Overview</h2>
            <div className="text-gray-700" dangerouslySetInnerHTML={{ __html: job.jobDescription }} />
          </div>
          <a href={job.applyLink} target="_blank" rel="noopener noreferrer" className="inline-block bg-purple-600 text-white px-6 py-3 rounded-md">
            Apply Now
          </a>
        </div>
      </div>
    );
  };
  
  export default JobDetails;
