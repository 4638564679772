import React from "react";
import Rating from "./Rating";
// import styles from "./TestimonialsSlide.module.css";
import image1 from "./assets/image1.png";
import image2 from "./assets/image2.png";
import image3 from "./assets/Image3.png";
import image4 from "./assets/Image4.png";
import image5 from "./assets/Image5.png";
import { Card, CardContent } from "../ui/card"
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../ui/carousel"
import Autoplay from "embla-carousel-autoplay"
const testimonialsData = [
  {
    id: 1,
    name: "K.Sandhya Rani",
    company: "IIIT, RGUKT RK Valley.",
    rating: 5,
    text: "KampKode's CRT program has significantly improved our students' employability skills. We've witnessed a remarkable increase in the number of students placed in top companies.",
    profession: "Director",
    image: image1,
  },
  {
    id: 2,
    name: "S.V.Prasad",
    rating: 4.5,
    company: "GMR Institute of Technology.",
    text: "Working with Kamp Kode has been a rewarding experience. Their internship programs have connected our students with industry-leading companies, giving them a taste of the real-world challenges.",
    profession: "Chairman",
    image: image2,
  },
  {
    id: 3,
    name: "Dr.Vanki Penchalaiah",
    rating: 4.5,
    company: "Aadisankara College of Engineering.",
    text: "KampKode's commitment to enhancing engineering education is impressive. The hands-on project experiences they offer have boosted our students' confidence and technical abilities.",
    profession: "Principal",
    image: image3,
  },
  {
    id: 4,
    name: "Dr.Prasanna Kumar",
    rating: 5,
    company: "Sanketika Vidya Parishad Engineering College.",
    text: "KampKode has brought a new level of convenience to our campus. Their on-campus training sessions have allowed our students to access high-quality education without the need to travel.",
    profession: "Dean of academics",
    image: image4,
  },
  {
    id: 5,
    name: "A.Rajendra",
    rating: 5,
    company: "Balaji Institute of Technology & Science.",
    text: "KampKode's tech assessments have been invaluable in assessing our students' knowledge and skills. KampKode's resources are a must-have for any engineering department.",
    profession: "CSE HOD",
    image: image5,
  },
];
const Testimonials = () => {
 

  return (
    <div className="py-10 bg-[#5133A0] my-6 font-Inter overflow-hidden">
      <div className="flex justify-center">
        <Carousel
          className="w-96 md:w-1/2 "
          opts={{ loop: true }}
          plugins={[
            Autoplay({
              delay: 4000,
            }),
          ]}
        >
          <CarouselContent>
            {testimonialsData.map((testimonial) => (
              <CarouselItem key={testimonial.id}>
                <Card className="p-1">
                  <CardContent className="rounded-3xl  py-10 px-6 text-white mb-3">
                    <Rating value={testimonial.rating} />
                    <div className="font-medium md:font-semibold text-md md:text-xl  py-4">
                      {testimonial.text}
                    </div>
                    <div className="flex pt-3 items-center">
                      <img
                        src={testimonial.image}
                        alt={testimonial.name}
                        className="w-16 h-16 rounded-full border  mr-3 mb-2"
                      />
                      <div>
                        <div className=" font-bold text-md md:text-xl">
                          {testimonial.name}
                        </div>
                        <div className=" font-bold text-lg">
                          {testimonial.profession}
                        </div>
                        <div className="">
                          {testimonial.company}
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselPrevious />
          <CarouselNext />

        </Carousel>

      </div>

    </div>
  );
};

export default Testimonials;
