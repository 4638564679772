import React, { useState } from 'react';
import axios from 'axios';

interface CertificateData {
  name: string;
  course: string;
  date: string;
}

const CertificateVerification: React.FC = () => {
  const [certificateId, setCertificateId] = useState('');
  const [certificateData, setCertificateData] = useState<CertificateData | null>(null);
  const [error, setError] = useState('');

  const handleVerify = async () => {
    try {
      const response = await axios.get<CertificateData>(
        `https://us-central1-your-project-id.cloudfunctions.net/getCertificateData`, 
        { params: { userId: certificateId } }
      );
      setCertificateData(response.data);
      setError(''); // Clear any previous error
    } catch (err) {
      setError('Certificate not found. Please check the Certificate ID.');
      setCertificateData(null); // Clear any previous certificate data
    }
  };

  return (
    <div className="min-h-screen bg-purple-50 flex flex-col items-center justify-center">
      <div className="bg-purple-700 text-white py-16 px-8 w-full flex flex-col items-center">
        <h1 className="text-4xl font-bold mb-4">Verify Your Kampkode Certification</h1>
        <p className="text-lg mb-8 text-center">
          The sweat and hard work you’ve put into yourself is just a click away
        </p>
        <div className="flex flex-col items-center">
          <input
            type="text"
            className="p-4 w-full max-w-md rounded-md text-gray-900 mb-4 focus:outline-none"
            placeholder="Enter Certificate ID"
            value={certificateId}
            onChange={(e) => setCertificateId(e.target.value)}
          />
          <button
            className="bg-purple-900 text-white px-8 py-3 rounded-md"
            onClick={handleVerify}
          >
            Verify Certificate
          </button>
        </div>
        {error && (
          <p className="text-red-500 mt-4">{error}</p>
        )}
        <p className="text-sm mt-4">
          We care about the protection of your data. Read our{' '}
          <a href="/privacy" className="underline">
            privacy policy
          </a>.
        </p>
      </div>

      {certificateData && (
        <div className="mt-12">
          <div className="border border-purple-300 rounded-lg p-8 bg-white shadow-lg">
            <h2 className="text-2xl font-bold text-center mb-4">Certificate of Completion</h2>
            <p className="text-lg text-center mb-2">
              This certifies that
            </p>
            <h3 className="text-xl font-semibold text-center text-purple-900">
              {certificateData.name}
            </h3>
            <p className="text-lg text-center mt-2">
              has successfully completed the course
            </p>
            <h4 className="text-lg font-medium text-center mt-2">
              {certificateData.course}
            </h4>
            <p className="text-lg text-center mt-2">
              on {certificateData.date}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CertificateVerification;
