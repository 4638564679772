import { useEffect, useState } from "react";
import { flashcardCategories } from "../../data/flashcards";
import { NavLink } from "react-router-dom";

const Flashcards = () => {
  const [flashcards, setFlashcards] = useState<any[]>([]);

  useEffect(() => {
    setFlashcards(flashcardCategories);
  }, []);

  return (
    <div className="pt-[80px]">
      <div className="w-full text-center bg-customThinPurple pt-6">
        <div className="font-semibold text-6xl pt-8">Flash Cards</div>
        <div className="py-8 lg:px-40 md:px-10 px-10 font-medium">
          Flashcards are simple, versatile tools for effective learning and
          memory reinforcement, ideal for subjects ranging from language to
          science.
        </div>
      </div>

      <div>
        <div className="flex flex-wrap justify-around px-10 lg:px-20 py-10">
          {flashcards.map((flashcard: any) => (
            <NavLink
              key={flashcard.category}
              to={`/flashcards/${flashcard.category}`}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <div
                className="p-6 w-[400px] border border-customLightPurple rounded-2xl m-6 hover:bg-[#E4E4E4] cursor-pointer"
                style={{ fontFamily: "Poppins" }}
              >
                <div className="text-center font-bold text-customLightPurple text-2xl pb-2">
                  {flashcard.title}
                </div>
                <div className="text-center text-[#808080]">
                  {flashcard.description}
                </div>
              </div>
            </NavLink>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Flashcards;
