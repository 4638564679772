export const FAQ_data = [
  {
    question: "What is KampKode?",
    answer:
      "KampKode is an edutech platform dedicated to empowering engineering students with the skills and knowledge they need to excel in their careers. We offer a range of programs, including Campus Recruitment Training, Internships, Projects, and more.",
  },
  {
    question: "Who can benefit from KampKode?",
    answer:
      "KampKode is principally tailored for students, yet our programs welcome all individuals eager to enhance their skills and embark on a career in the technology sector. Whether you're looking to start or kickstart your journey in the tech industry, KampKode is the ideal platform for you.",
  },
  {
    question:
      "Are the trainers at KampKode experienced?",
    answer:
      "Yes, our trainers are experts in their respective fields with extensive industry experience. They bring practical knowledge and insights to our training programs.",
  },
  {
    question:
      "Is the learning material accessible online?",
    answer:
      "Yes, all course materials and resources are available digitally on our platform. You can access them at any time, making learning flexible and convenient.",
  },
  {
    question:
      "What makes KampKode affordable?",
    answer:
      "We believe in making quality education accessible to everyone. KampKode's pricing structure is designed to be budget-friendly, ensuring that you receive excellent training without breaking the bank.",
  },
  // {
  //   question: "Can I track my progress and performance on your platform?",
  //   answer:
  //     "Yes, we provide tools for tracking your progress, such as viewing quiz scores and completion rates. This helps users monitor their learning journey.",
  // },
  // {
  //   question: "What subjects or topics are covered in your Flashcards section?",
  //   answer:
  //     "Our Flashcards cover a wide range of subjects, allowing you to review and reinforce your knowledge in areas such as 'CSS', 'JAVA', 'Python'.",
  // },
  // {
  //   question: "Is the content on your platform updated regularly?",
  //   answer:
  //     "Yes, we regularly update our content to ensure it remains relevant and aligned with current educational standards. Users can usually receive updates when they have an internet connection.",
  // },
  {
    question: "What types of assessments are offered?",
    answer:
      "We offer a variety of assessments, including quizzes and tech assessments. These tools help you evaluate your knowledge and skills and receive valuable feedback.",
  },
  // {
  //   question: "How can I enroll in a program at KampKode?",
  //   answer:
  //     "Enrolling in a KampKode program is easy. Simply visit our website, browse our offerings, select your desired program, and follow the enrollment instructions. Our team is also available to assist you with any questions.",
  // },
  // {
  //   question: "How do I contact KampKode for further information?",
  //   answer:
  //     "You can reach out to our friendly support team through the contact information provided on our website. We're here to assist you with any inquiries or concerns.",
  // },
];  
