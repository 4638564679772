export const isUserAdmin = (email: string) => {
  return [
    "balajimutyala2606@gmail.com",
    "sivaaliassai1435@gmail.com",
  ].includes(email);
};

export const isLanguageUsing = (id: number) => {
  return [63, 93, 70, 92, 71, 91, 62, 52, 53, 54].includes(id);
};
