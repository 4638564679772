import React from 'react';
import data from './internships.json'
import CarouselComponent from '../../customCarousel/CarouselComponent';

const InternshipsExplore: React.FC = () => {

  return (
    <div className="h-fit font-Inter bg-[#F9FAFB] pb-10">
      <div className="mx-3 lg:mx-14 py-10 px-6">
        <h1 className="text-[#101828] text-4xl font-medium mb-3">Explore Our Internship Opportunities</h1>
        <p className="text-[#475467] font-normal">Build the Future of Technology</p>
      </div>

      <div className='lg:mx-16 px-2'>
        <CarouselComponent Data={data}/>
      </div>

    </div>
  );
};

export default InternshipsExplore;
