import NumberTicker from '../../magicui/number-ticker'
import React from 'react'

const InternshipsInfo = () => {
  return (
    <div className='bg-[#5133A0] h-fit flex flex-col items-center  justify-center gap-12 py-14 '
    >
      <div className='flex flex-col items-center justify-center gap-6'>
        <div>
          <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="5" y="5" width="56" height="56" rx="28" fill="#7F56D9" />
            <rect x="5" y="5" width="56" height="56" rx="28" stroke="#6941C6" stroke-width="10" />
            <path d="M29.5002 39.4167H23.0835M26.5835 33H21.3335M29.5002 26.5833H23.6668M38.8335 22.5L31.1377 33.2742C30.797 33.7511 30.6267 33.9895 30.6341 34.1883C30.6405 34.3614 30.7235 34.5226 30.8606 34.6285C31.0181 34.75 31.3111 34.75 31.8971 34.75H37.6668L36.5002 43.5L44.196 32.7258C44.5366 32.2489 44.7069 32.0105 44.6996 31.8117C44.6932 31.6386 44.6102 31.4774 44.473 31.3715C44.3156 31.25 44.0225 31.25 43.4365 31.25H37.6668L38.8335 22.5Z" stroke="white" stroke-width="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
        <p className='text-4xl font-medium text-white text-center mx-2'>
          Unleash your full power through our Internships
        </p>
        <p className='font-light text-xl text-[#E9D7FE] text-center mx-1'>
          Everything you need Offer letter, Completion Certificate, and LOR.
        </p>
      </div>
      <div>
        <div className='flex flex-col lg:flex-row gap-8 text-white text-center justify-center'>
          <div className='flex flex-col items-center justify-center '>
            <NumberTicker value={400} />
            <p className='text-[#E9D7FE] text-base font-light mt-4'>Internship Success Stories</p>
          </div>
          <span className='hidden lg:block border-r border-[#7F56D9] h-24 mx-2 my-0.5'></span>
          <div >

            <NumberTicker value={200} />
            <p className='text-[#E9D7FE] text-base font-light mt-4'>Internship Opportunities</p>
          </div>
          <span className='hidden lg:block border-r border-[#7F56D9] h-24 mx-2 my-0.5'></span>
          <div >
            <NumberTicker value={1000} />
            <p className='text-[#E9D7FE] text-base font-light mt-4'>Registrations</p>
          </div>

        </div>
      </div>


    </div>
  )
}

export default InternshipsInfo