import * as React from "react"
import * as AccordionPrimitive from "@radix-ui/react-accordion"
import { PlusCircleIcon, MinusCircleIcon } from "lucide-react"
import { useState } from "react"; // Add this import

import { cn } from "../magicui/lib/utils"

const Accordion = AccordionPrimitive.Root

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item
    ref={ref}
    className={cn("border-b", className)}
    {...props}
  />
))
AccordionItem.displayName = "AccordionItem"

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger> & { "data-state"?: string }
>(({ className, children, ...props }, ref) => {
  const [isOpen, setIsOpen] = useState(false); // Add state for toggling

  const handleToggle = () => {
    setIsOpen((prev) => !prev); // Toggle state
  };

  return (
    <AccordionPrimitive.Header className="flex">
      <AccordionPrimitive.Trigger
        ref={ref}
        onClick={handleToggle} // Add onClick handler
        data-state={isOpen ? "open" : "closed"} // Set data-state based on isOpen
        className={cn(
          "flex flex-1 text-[12px] md:text-md lg:text-lg items-center justify-between py-4 font-semibold transition-all  duration-400 text-start",
          {
            "[&[data-state=open]>svg]:rotate-180": isOpen,
          },
          className
        )}
        {...props}
      >
        {children}
        {isOpen ? (
          <MinusCircleIcon className="h-4 w-4 shrink-0  transition-all  duration-400" />
        ) : (
          <PlusCircleIcon className="h-4 w-4 shrink-0 transition-all duration-400" />
        )}
      </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
  );
})
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className="overflow-hidden text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down"
    {...props}
  >
    <div className={cn("pb-4 pt-0", className)}>{children}</div>
  </AccordionPrimitive.Content>
))

AccordionContent.displayName = AccordionPrimitive.Content.displayName

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent }