import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchJobs, deleteJob } from '../../actions/jobActions';
import AdminJobCard from './AdminJobCard';
import PostJobForm from './PostJobForm'; // Import the PostJobForm component
import { RootState, AppDispatch } from '@/actions/store';
import EditJobForm from './EditJobForm';
import { Upload } from "lucide-react";

interface Job {
  id: string;
  date: string;
  companyLogo: string;
  companyName: string;
  jobTitle: string;
  jobDescription: string;
  location: string;
  employment: string;
  applyLink: string;
}

const AdminPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { jobs, loading, hasMore, page } = useSelector((state: RootState) => state.job);
  const [selectedJob, setSelectedJob] = useState<Job | null>(null);
  const [isPostJobOpen, setIsPostJobOpen] = useState(false); // State to control PostJobForm visibility

  useEffect(() => {
    if (!loading && hasMore) {
      dispatch(fetchJobs(page));
    }
  }, [dispatch, page, loading, hasMore]);

  const handleDeleteJob = async (job: Job) => {
    try {
      await dispatch(deleteJob(job.id));
      console.log(`Job ${job.id} deleted successfully.`);
    } catch (error) {
      console.error('Failed to delete job:', error);
    }
  };

  const handleEditJob = (job: Job) => {
    setSelectedJob(job); // Opens the edit modal with the selected job
  };

  const handleJobEdited = (updatedJob: Job) => {
    setSelectedJob(null); // Close the modal after editing
    dispatch({ 
      type: 'job/fetchJobsSuccess', 
      payload: { 
        jobs: jobs.map((job: Job) => job.id === updatedJob.id ? updatedJob : job), 
        hasMore 
      } 
    });
  };

  const loadMoreJobs = useCallback(() => {
    if (hasMore && !loading) {
      dispatch(fetchJobs(page + 1));
    }
  }, [dispatch, hasMore, loading, page]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop
        >= document.documentElement.offsetHeight - 50
      ) {
        loadMoreJobs();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loadMoreJobs]);

  return (
    <div className="relative my-16 mx-4 lg:mx-[20%]">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6">
        <h1 className="text-2xl lg:text-3xl font-semibold my-4 sm:mb-0">
          Job Alerts & Opportunities
        </h1>
        <button
          onClick={() => setIsPostJobOpen(true)} // Open the PostJobForm popup
          className="flex items-center bg-purple-500 text-white my-4 p-2 rounded-lg hover:bg-purple-600"
        >
          <Upload className="w-4 h-4 mr-2" />
          Post Job
        </button>
      </div>
      <div className="space-y-6">
        {jobs.map((job: Job) => ( // Ensure job is typed as Job
          <AdminJobCard 
            key={job.id} 
            job={job} 
            onDelete={() => handleDeleteJob(job)} 
            onEdit={() => handleEditJob(job)} 
          />
        ))}
        {loading && <p className="text-center">Loading more jobs...</p>}
      </div>
      {!hasMore && <p className="text-center">No more jobs available.</p>}

      {isPostJobOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <PostJobForm closePopup={() => setIsPostJobOpen(false)} />
        </div>
      )}

      {selectedJob && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <EditJobForm 
            jobId={selectedJob.id} 
            formData={selectedJob} 
            closePopup={() => setSelectedJob(null)} 
            onJobEdited={handleJobEdited} //test
          />
        </div>
      )}
    </div>
  );
};

export default AdminPage;
