import React from 'react';

import ProjectPerks from './ProjectPerks';
import { DockComponent } from './DockComponent';
import TechStack, { ContactSection, ShareSection } from './TechStack';
import { Logos } from '../../../data/Logos'; 

interface Features {
    [key :string] : string;
}

interface ProjectIntroProps {
    intro : string;
    techStack : string[];
    conclusion : string;
    projectFeatures : Features;
    userExperience : Features;
    softwareAndTools  : Features;
}

const ProjectIntro = ({ intro, projectFeatures, userExperience, softwareAndTools, techStack, conclusion }: ProjectIntroProps ) => {
    // console.log('projecCT FEATURES ARE : ')
    // console.log(projectFeatures)
    return (
        <div className='flex flex-col md:flex-row gap-8 md:gap-16'>
            {/* Sidebar (visible only on medium and above) */}
            <div className='hidden md:block md:min-w-[20rem] border-t h-fit px-2 sticky top-20'>
                <h1 className='text-[#7F56D9] font-medium text-xl my-4 md:my-6'>Technologies used</h1>
                <div className='flex flex-col gap-4 px-2 mb-6'>
                    {techStack?.map((tech, idx) => (
                        <div key={idx} className='flex gap-3 items-center'>
                            <div className='h-6 w-6 scale-110 p-1'>
                                <Logos.DefaultIcon />
                            </div>
                            <span className='text-base'>{tech}</span>
                        </div>
                    ))}
                </div>
                <div className='px-1 text-center py-3 border-y'>
                    <h1 className='text-[#7F56D9] text-left text-base font-semibold my-2 capitalize'>Curious About Our Projects?</h1>
                    <button className='w-full border  border-[#323c4dda] rounded-lg py-3 text-[#344054]'>Email us</button>
                    <button className='w-full border rounded-lg py-3 bg-themepruple text-white mt-4'>Chat with our Team</button>
                </div>
                <div className='relative h-fit py-1'>
                    <h1 className='absolute -top-3  z-20 text-[#7F56D9] text-base font-semibold my-4 capitalize'>Share this project</h1>
                    <DockComponent />
                </div>
            </div>

            {/* Main Content */}
            <div className='border-t px-4 md:w-[55vw] lg:w-[65vw]'>
                <h1 className='text-[#101828] font-semibold text-2xl md:text-3xl my-4 md:my-6'>Introduction</h1>
                <p className='text-[#475467] font-normal text-lg px-1'>
                    {intro}
                </p>
                <img src="/projectPage/vscode.jpeg" alt="Project" className='w-full my-4' />

                {/* TechStack for mobile */}
                <div className='md:hidden'>
                    <TechStack technologies={techStack}/>
                </div>

                <DisplayFeatures title='Project Features' features={projectFeatures} />
                
                <DisplayFeatures title='User Experience' features={userExperience} />
                <ProjectPerks />
                <DisplayFeatures title='Software and Tools' features={softwareAndTools} />

                <img src="/projectPage/vscode.jpeg" alt="Project" className='w-full my-4' />
                <ContactSection />
                
                {/* Conclusion */}
                <div className='bg-[#F9F6FF] rounded-md p-6'>
                    <h1 className='text-2xl md:text-3xl font-semibold mb-4'>Conclusion</h1>
                    <p className='text-[#475467] text-lg'>
                        {conclusion}
                    </p>
                </div>
                <ShareSection />
            </div>
        </div>
    );
};

interface DisplayFeaturesProps {
    title : string;
    features: { [key: string]: string }; // An object with string keys and string values
  }
  
  const DisplayFeatures: React.FC<DisplayFeaturesProps> = ({ title, features }) => {
    return (
        <div>
        <h1 className='text-[#101828] font-semibold text-3xl my-6'>{title}</h1>
      <ul className='px-7'>
        {Object.entries(features).map(([key, value]) => (
          <li key={key} className='list-disc text-[#475467] font-normal text-lg '>
            <strong>{key}</strong>: {value}
          </li>
        ))}
      </ul>
        </div>
    );
  };

export default ProjectIntro;
