import { GlobeIcon, LinkedInLogoIcon, TwitterLogoIcon } from '@radix-ui/react-icons'
import React from 'react'
// import { LampContainer } from '../ui/lamp'
// import { motion } from 'framer-motion'
import BlurFade from '../magicui/blur-fade'
import tm1 from './assets/ourTeam/tm1.png'
import tm2 from './assets/ourTeam/tm2.jpeg'
import tm3 from './assets/ourTeam/tm3.jpeg'
import tm4 from './assets/ourTeam/tm4.jpeg'
import tm5 from './assets/ourTeam/tm5.jpeg'
import { SparklesCore } from '../ui/sparkles'

const teamMembers = [
  {
    id: 'TM-1',
    name: 'Siva Sai',
    role: 'Founder & CEO',
    about: 'Drives the company vision, strategy, and overall success.',
    backgroundImage: tm1,
  },
  {
    id: 'TM-2',
    name: 'Balaji',
    role: 'Product Manager and CTO',
    about: 'Leads product strategy and technology development.',
    backgroundImage: tm2,
  },
  {
    id: 'TM-3',
    name: 'Ishaaq Ahamed',
    role: 'UI Designer',
    about: 'Designs engaging user experiences that blend aesthetics with functionality.',
    backgroundImage: tm3,
  },
  {
    id: 'TM-4',
    name: 'Moksha Nirugutti',
    role: 'Full Stack Developer',
    about: 'Builds and maintains front-end and back-end for seamless performance.',
    backgroundImage: tm4,
  },
  {
    id: 'TM-5',
    name: 'Vishal',
    role: 'Backend Developer',
    about: 'Specializes in server-side logic to power efficient and scalable applications.',
    backgroundImage: tm5,
  },
];


const TeamMemberCard = ({ member }: { member: { id: string; name: string; role: string; about: string; backgroundImage: string } }) => (
  <BlurFade key={member.id} delay={0.25} inView>
    <div className='h-[400px] lg:h-[480px] w-[340px]  lg:w-[43vw] border person-card  backdrop-blur-sm' id={member.id}
      style={{ backgroundImage: `url(${member.backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
    >
        <div className='backdrop-blur-md h-full w-full relative bg-white bg-opacity-25'>

      <div className='pt-16 px-9'>
        <h1 className='pb-4 font-semibold text-3xl'>{member.name}</h1>
        <p className='pb-1 font-medium'>{member.role}</p>
        <p className='font-light'>{member.about}</p>
      </div>
      <div className='flex justify-between  pt-28 px-9'>
        <p className='font-medium tracking-wide'>Follow us to face reveal</p>
        <span className='transform -translate-y-2'>
          <Arrow />
        </span>
      </div>
      <div className='flex gap-3 pt-3 px-9' id="social-icons">
        <TwitterLogoIcon />
        <LinkedInLogoIcon />
        <GlobeIcon />
      </div>
      <span className='absolute bottom-4 right-4 font-medium tracking-wide'>61% to go</span>
        </div>
    </div>
  </BlurFade>
);

const OutTeam = () => {
  return (
    <div>
        <div className="h-[25rem] w-full  flex flex-col items-center justify-center overflow-hidden rounded-md">
      <h1 className="md:text-7xl text-3xl lg:text-5xl font-bold text-center text-black relative z-20">
        Our Team
      </h1>
      <div className="w-[20rem] lg:w-[40rem]  h-60 lg:h-40 relative mt-1 ">
        {/* Gradients */}
        <div className="absolute inset-x-[42px] md:inset-x-20 top-0 bg-gradient-to-r from-transparent via-indigo-500 to-transparent h-[2px] w-3/4 blur-sm" />
        <div className="absolute inset-x-[42px] md:inset-x-20 top-0 bg-gradient-to-r from-transparent via-indigo-500 to-transparent h-px w-3/4" />
        <div className="absolute inset-x-[130px] md:inset-x-60 top-0 bg-gradient-to-r from-transparent via-[#7f6efc] to-transparent h-[5px] w-1/4 blur-sm" />
        <div className="absolute inset-x-[130px] md:inset-x-60 top-0 bg-gradient-to-r from-transparent via-[#7f6efc] to-transparent h-px w-1/4" />
        <p className='absolute text-base lg:text-lg text-center w-full lg:w-[70%] mt-4 text-black font-medium tracking-normal  lg:mx-20 z-30'>
        Get to know the inspiring individuals behind our vision. We’re committed to helping you achieve your goals with personalized support.
            </p>
        <div className='absolute flex flex-col lg:flex-row gap-3 pt-5 w-full lg:justify-center py-1 bottom-1 lg:-bottom-3 z-30 lg:-ml-3'>
              <button className='border rounded-md py-3 px-5 shadow-sm text-black lg:w-fit text-base font-normal tracking-normal bg-white'>See What's New</button>
              <button className='bg-themepruple text-white rounded-md py-3 px-5 shadow-sm lg:w-fit text-base font-normal tracking-normal'>See Our Programs</button>
            </div>
        {/* Core component */}
        <SparklesCore
          background="transparent"
          minSize={0.4}
          maxSize={1}
          particleDensity={1200}
          className="w-full h-full"
          particleColor="#333"
        />
 
        {/* Radial Gradient to prevent sharp edges */}
        <div className="absolute inset-0 w-full h-full bg-white [mask-image:radial-gradient(350px_400px_at_top,transparent_20%,white)]"></div>
      </div>
    </div>
      <div className='pb-16 flex flex-col justify-center w-full items-center'>
        {/* top 2 cards */}
        <div className='flex flex-col lg:flex-row gap-7 justify-center mb-7 lg:mb-16 '>
          {teamMembers.slice(0, 2).map(member => (
            <BlurFade key={member.id} delay={0.25} inView>

                <TeamMemberCard member={member} />
            </BlurFade>
          ))}
        </div>
        {/* bottom 3 cards */}
        <div className='flex gap-7 flex-col lg:flex-row justify-center w-full  items-center'>
          {teamMembers.slice(2).map(member => (
            <BlurFade key={member.id} delay={0.25} inView>
              <div className='h-[400px] lg:h-[450px] w-[340px] lg:w-[28vw] border person-card ' id={member.id}
                style={{ backgroundImage: `url('${member.backgroundImage}')` }}
              >
                <div className='backdrop-blur-md h-full w-[105%] pr-4 relative bg-white bg-opacity-25'>
                <div className='pt-16 px-9'>
                  <h1 className='pb-4 font-semibold text-3xl'>{member.name}</h1>
                  <p className='pb-1 font-medium'>{member.role}</p>
                  <p className='font-light'>{member.about}</p>
                </div>
                <div className='flex justify-between pt-14 px-9'>
                  <p className='font-medium tracking-wide'>Follow us to face reveal</p>
                  <span className='transform -translate-y-2'>
                    <Arrow />
                  </span>
                </div>
                <div className='flex gap-3 pt-3 px-9' id="social-icons">
                  <TwitterLogoIcon />
                  <LinkedInLogoIcon />
                  <GlobeIcon />
                </div>
                <span className='absolute bottom-4 right-12 font-medium tracking-wide'>61% to go</span>
                </div>
              </div>
            </BlurFade>
          ))}
        </div>
      </div>
    </div>
  )
}

const Arrow = () => (
  <svg width="24" height="31" viewBox="0 0 24 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 23.5L17 13.5M17 13.5H7M17 13.5V23.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default OutTeam