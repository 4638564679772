import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Clock, MapPin } from "lucide-react";

interface Job {
  id: string;
  date: string;
  companyLogo: string;
  companyName: string;
  jobTitle: string;
  jobDescription: string;
  location: string;
  employment: string;
  applyLink: string;
}

interface JobCardProps {
  job: Job;
}

const ClientJobCard: React.FC<JobCardProps> = ({ job }) => {
  const navigate = useNavigate();

  const handleJobUpdateClick = (jobId: string) => {
    navigate(`/job-updates/${jobId}`);
  };

  return (
    <div className="border flex flex-col md:flex-row items-center w-full md:w-[1080px] h-auto md:h-[184px] rounded-md p-4">
      <div className="w-[87px] h-[87px] flex-shrink-0 flex items-center mb-4 p-2 md:mb-0">
        <img src={job.companyLogo} alt="company logo" className="w-full" />
      </div>
      <div className="flex flex-col justify-around w-full p-4">
        <div className="flex flex-col md:flex-row justify-between w-full">
          <div>
            <div className="text-lg font-bold">{job.jobTitle}</div>
            <div className="text-sm font-semibold text-purple-600">
              {job.companyName}
            </div>
          </div>
          <div className="mt-2 md:mt-0">
            <button
              className="bg-purple-600 text-white px-4 py-2 rounded-md"
              onClick={() => handleJobUpdateClick(job.id)}
            >
              View & Apply
            </button>
          </div>
        </div>
        <div className="text-sm text-gray-600 mt-2" style={{
          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }} dangerouslySetInnerHTML={{ __html: job.jobDescription }} />

        <div className="flex flex-wrap gap-4 mt-4">
          <div className="flex gap-2 text-gray-600 font-bold">
            <MapPin className="text-slate-400" />
            {job.location}
          </div>
          <div className="flex gap-2 text-gray-600 font-bold">
            <Clock className="text-slate-400" />
            {job.employment}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientJobCard;
