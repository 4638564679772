import React, { useState, useEffect, useRef } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { getQuiz } from "../../services/quizzes.services";
import { QuizType, questions } from "../../types/types";
import { useSelector } from "react-redux";
import { SubmitQuiz } from "../../services/quizzes.services";
import ConfirmationModal from "../ProjectSection/ProjectDetails/Modals/ConfirmationModal";

const Quiz = () => {
  const params = useParams();
  const id = params.id;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const quizId = queryParams.get("quizId");
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);

  const [timeLeft, setTimeLeft] = useState(600);
  const [quiz, setQuiz] = useState<QuizType>({
    quizTopic: "",
    quizId: "",
    questions: [],
  });

  const [selectedQuestion, setSelectedQuestion] = useState<questions>({
    question: "",
    correctAnswer: "",
    explanation: "",
    submitted: false,
    answer: false,
    options: [],
  });
  const [questionNumber, setSelectedQuestionNumber] = useState(1);
  const [selectedOption, setSelectedOption] = useState("");
  const [totalScore, setTotalScore] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState(
    Array(quiz.questions.length).fill("")
  );

  const [showModal, setShowModal] = useState(false);

  const user = useSelector((state: any) => state.currentUserReducer);

  useEffect(() => {
    const getQuizHandler = async () => {
      try {
        const payload = { id, quizId };
        const response = await getQuiz(payload);
        if (response.status === 1) {
          const quizData = response.data;
          setQuiz(quizData);
          setSelectedQuestion(quizData.questions[0]);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getQuizHandler();
  }, [id, quizId]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (timeLeft > 0) {
        setTimeLeft((prev) => prev - 1);
      } else {
        clearInterval(timer);
        submitQuizHandler();
      }
    }, 1000);
    return () => clearInterval(timer);
    // eslint-disable-next-line
  }, [timeLeft]);

  const formatTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const selectedQuestionHandler = (question: any, questionNumber: number) => {
    setSelectedQuestion(question);
    setSelectedQuestionNumber(questionNumber);
  };

  useEffect(() => {
    setSelectedOption("");
  }, [selectedQuestion]);

  const handleOptionChange = (option: string, questionIndex: number) => {
    const updatedSelectedOptions = [...selectedOptions];
    updatedSelectedOptions[questionIndex] = option;
    setSelectedOption(option);
    setSelectedOptions(updatedSelectedOptions);
  };

  const submitQuestionHandler = () => {
    let answer = false;
    if (selectedOption === selectedQuestion.correctAnswer) {
      answer = true;
    }

    const updatedSelectedQuestion = {
      ...selectedQuestion,
      answer: answer,
      submitted: true,
    };

    setQuiz((prevQuiz) => {
      const updatedQuestions = prevQuiz.questions.map((question) => {
        if (question === selectedQuestion) {
          return updatedSelectedQuestion;
        }
        return question;
      });

      return {
        ...prevQuiz,
        questions: updatedQuestions,
      };
    });
    setSelectedQuestion(updatedSelectedQuestion);
  };

  const AllAnswersSubmitted = () => {
    return quiz.questions.every((question) => question.submitted);
  };

  useEffect(() => {
    if (AllAnswersSubmitted()) {
      let correctAnswersCount = 0;
      quiz.questions.forEach((question) => {
        if (question.answer) {
          correctAnswersCount++;
        }
      });
      const totalScore = (correctAnswersCount / quiz.questions.length) * 100;

      setTotalScore(totalScore);
    }
    // eslint-disable-next-line
  }, [quiz]);

  const submitQuizHandler = async () => {
    try {
      const payload = {
        email: user.email,
        quizTitle: id,
        quizId: quiz.quizId,
        quizTopic: quiz.quizTopic,
        totalScore: totalScore,
      };

      const response = await SubmitQuiz(payload);
      if (response.status === 1) {
        alert("submitted successfully");
        navigate("/quizzes");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleExit = () => {
    navigate("/quizzes");
  };

  const requestFullscreen = () => {
    const element = ref.current;
    if (element) {
      // Check for different browser implementations of the fullscreen API
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if ((element as any).mozRequestFullScreen) {
        /* Firefox */
        (element as any).mozRequestFullScreen();
      } else if ((element as any).webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        (element as any).webkitRequestFullscreen();
      } else if ((element as any).msRequestFullscreen) {
        /* IE/MS Edge */
        (element as any).msRequestFullscreen();
      }
    }
  };

  const exitFullscreen = () => {
    if (document.fullscreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if ((document as any).mozCancelFullScreen) {
        /* Firefox */
        (document as any).mozCancelFullScreen();
      } else if ((document as any).webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        (document as any).webkitExitFullscreen();
      } else if ((document as any).msExitFullscreen) {
        /* IE/MS Edge */
        (document as any).msExitFullscreen();
      }
    }
  };

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        exitFullscreen();
      }
    };

    document.addEventListener("keydown", handleEscape);
    return () => document.removeEventListener("keydown", handleEscape);
  }, []);

  useEffect(() => {
    requestFullscreen();
    return () => exitFullscreen();
  }, []);

  return (
    <div ref={ref} className="bg-white">
      <ConfirmationModal
        title="Are you sure you want to exit?"
        showModal={showModal}
        onConfirm={() => {
          handleExit();
          setShowModal(false);
        }}
        onCancel={() => setShowModal(false)}
      />
      <div className="bg-customThinPurple pt-10 pb-28">
        <div className="absolute top-6 left-6 flex items-center text-customLightPurple font-bold">
          <span className="w-1 h-1 mr-1 rounded-full bg-customLightPurple inline-block"></span>
          Quiz
        </div>
        <div className="flex absolute top-6 right-8">
          <div className="m-2 flex items-center">
            <img
              width="20"
              height="20"
              src="https://img.icons8.com/ios-filled/100/time.png"
              alt="time"
            />{" "}
            <span>{formatTime(timeLeft)}</span>
          </div>
          {true ? (
            <button
              onClick={() => submitQuizHandler()}
              className=" px-4 py-2 rounded-md bg-customLightPurple text-white mr-2"
            >
              Submit Quiz
            </button>
          ) : null}
          <button
            onClick={() => setShowModal(true)}
            style={{
              padding: "0rem 1rem",
              textAlign: "center",
              fontSize: "1rem",
              letterSpacing: "1px",
              textDecoration: "none",
              color: "#FF0000",
              background: "transparent",
              cursor: "pointer",
              transition: "ease-out 0.5s",
              border: "2px solid #FF0000",
              borderRadius: "8px",
              boxShadow: "inset 0 0 0 0 #FF0000",
            }}
            onMouseOver={(e) => {
              e.currentTarget.style.color = "white";
              e.currentTarget.style.boxShadow = "inset 0 -100px 0 0 #FF0000";
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.color = "#FF0000";
              e.currentTarget.style.boxShadow = "inset 0 0 0 0 #FF0000";
            }}
            onMouseDown={(e) => {
              e.currentTarget.style.transform = "scale(0.9)";
            }}
            onMouseUp={(e) => {
              e.currentTarget.style.transform = "scale(1)";
            }}
          >
            X Exit
          </button>
        </div>
        <div className="text-center text-7xl font-bold pt-7">
          {quiz.quizTopic}
        </div>
      </div>
      <div className="flex">
        <div className="box-border w-[300px] m-10">
          <div className="grid grid-cols-3 gap-1 ">
            {quiz.questions.map((question, index) => {
              return (
                <div
                  key={index}
                  onClick={() => selectedQuestionHandler(question, index + 1)}
                  className={`cursor-pointer border border-customLightPurple p-4 w-12 text-center h-12 text-customLightPurple  font-bold rounded-lg m-1 ${
                    question === selectedQuestion
                      ? "text-white bg-customLightPurple"
                      : "bg-customThinPurple"
                  } ${
                    question.answer
                      ? "bg-green-500 text-white"
                      : question.submitted
                      ? "bg-red-500 text-white"
                      : ""
                  }`}
                >
                  {index + 1}
                </div>
              );
            })}
          </div>
          <div className="py-5 px-2">
            <div className="flex items-center pb-2">
              <span className="w-3 h-3 mr-2 rounded-sm bg-customThinPurple inline-block font-semibold"></span>
              Not Answered
            </div>
            <div className="flex items-center pb-2">
              <span className="w-3 h-3 mr-2 rounded-sm bg-customLightPurple inline-block font-semibold"></span>
              Selected Question
            </div>
            <div className="flex items-center pb-2">
              <span className="w-3 h-3 mr-2 rounded-sm bg-green-500 inline-block font-semibold"></span>
              Correct Answer
            </div>
            <div className="flex items-center pb-2">
              <span className="w-3 h-3 mr-2 rounded-sm bg-red-500 inline-block font-semibold"></span>
              Incorrect Answer
            </div>
          </div>
        </div>
        <div className="border border-customLightPurple rounded-xl p-8 w-full mr-20 ml-14 relative bottom-16 bg-white">
          <div className="flex justify-between">
            <div className="text-customLightPurple font-semibold">
              Quesion {questionNumber}/{quiz.questions.length}
            </div>
            <div className="flex items-center">
              <span
                className={`w-2 h-2 mr-2 rounded-full inline-block ${
                  selectedQuestion.submitted
                    ? selectedQuestion.answer
                      ? "bg-green-500"
                      : "bg-red-500"
                    : "bg-yellow-600"
                }`}
              ></span>
              <span
                className={`${
                  selectedQuestion.submitted
                    ? selectedQuestion.answer
                      ? "text-green-500"
                      : "text-red-500"
                    : "text-yellow-600"
                }`}
              >
                {selectedQuestion.submitted
                  ? selectedQuestion.answer
                    ? "Correct Answer"
                    : "Wrong Answer"
                  : "Not Answered"}
              </span>
            </div>
          </div>
          <div className="py-10 border-b-2 border-customThinPurple font-bold">
            {selectedQuestion.question}
          </div>
          <div className="py-5 border-b-2 border-customThinPurple px-5">
            {selectedQuestion.options.map((option, index) => {
              return (
                <div key={index} className="mb-2">
                  <label className="flex py-2 items-center">
                    <input
                      type="radio"
                      name="option"
                      value={option}
                      disabled={selectedQuestion.submitted}
                      checked={selectedOptions[questionNumber - 1] === option}
                      onChange={() =>
                        handleOptionChange(option, questionNumber - 1)
                      }
                      className="form-radio h-5 w-5 text-customLightPurple"
                    />
                    <span className="ml-2">{option}</span>
                  </label>
                </div>
              );
            })}
          </div>

          {selectedQuestion.submitted && (
            <div className="px-5 pt-5">
              <div className="pb-2">
                <span className="text-green-500 font-semibold">
                  Correct Answer :
                </span>
                <span>{selectedQuestion.correctAnswer}</span>
              </div>
              <div className="pb-2 text-customLightPurple font-semibold">
                Explanation :
              </div>
              <div>{selectedQuestion.explanation}</div>
            </div>
          )}

          <div className="flex justify-between px-5 pt-5">
            <button className=" px-5 py-2 border border-customLightPurple rounded-md">
              Report Problem
            </button>
            {!selectedQuestion.submitted && (
              <button
                className={`${
                  selectedOption === "" ? "opacity-40" : "opacity-100"
                } px-5 py-2 rounded-md text-white bg-customLightPurple`}
                onClick={submitQuestionHandler}
                disabled={selectedOption === ""}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quiz;
