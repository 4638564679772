import React from "react";
import styles from "./Services.module.css";
import logo from "../assets/kampkode_icon.png";

const OurServices = () => {
  return (
    <div className={styles.parentDiv}>
      <div className={styles.backframe}>
        <img
          src={logo}
          className="rotate-[16.127deg] opacity-10 shrink-0 mix-blend-luminosity absolute z-50 w-[300px] h-[300px] mt-[6%] left-[-6%]"
          alt="logo at top left"
        />
        <img
          src={logo}
          className="rotate-[16.127deg] opacity-10 shrink-0 mix-blend-luminosity absolute z-5 w-[300px] h-[300px] mt-[72%] left-[-6%]"
          alt="logo at bottom left"
        />
      </div>
      <div className={styles.mainframe}>
        <div className={styles.heading}>
          <p>Services</p>
        </div>
        <div>
          <p className={styles.program}>CRT</p>
          <div className={styles.plans}>
            <div className={styles.card2}>
              <div className="text-[black] text-center pt-8 pb-4 px-8 text-[x-large]">
                <h1>Basic</h1>
                <p className="text-[medium]">Plan</p>
              </div>
              <div className={styles.line}>
                <svg fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1 1H263"
                    stroke="url(#paint0_radial_671_636)"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <defs>
                    <radialGradient
                      id="paint0_radial_671_636"
                      cx="0"
                      cy="0"
                      r="1"
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(132 1.00014) rotate(-15.5809) scale(107.968 28287.5)"
                    >
                      <stop stopColor="#1E003D" />
                      <stop offset="1" stopColor="#1E003D" stopOpacity="0" />
                    </radialGradient>
                  </defs>
                </svg>
              </div>
              <div className="text-black pl-[10%] pr-[0%] pt-[5%] pb-[30%]">
                <div>&#8227; 100+ hours of live training </div>
                <div>&#8227; Aptitude & reasoning </div>
                <div>&#8227; One programming language </div>
                <div>&#8227; Communication Skills </div>
              </div>
              <div className="pl-[30%] pr-[30%] pb-[10%] mt-[82%]">
                <button className="bg-[#E4E4E4] text-[#1E003D] px-[10%] py-[8%] rounded-xl border-[none] font-[bold] hover:bg-[#7D6EEB]">
                  Get Started
                </button>
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.cardHeading}>
                <h1>Premium</h1>
                <p>Plan</p>
              </div>
              <div className={styles.line}>
                <svg fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1 1H263"
                    stroke="url(#paint0_radial_671_696)"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <defs>
                    <radialGradient
                      id="paint0_radial_671_696"
                      cx="0"
                      cy="0"
                      r="1"
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(132 1.00014) rotate(-15.5809) scale(107.968 28287.5)"
                    >
                      <stop stopColor="white" />
                      <stop offset="1" stopColor="white" stopOpacity="0" />
                    </radialGradient>
                  </defs>
                </svg>
              </div>
              <div className="text-white pl-[10%] pr-[0%] pt-[5%] pb-[50%]">
                <div>&#8227; 100+ hours of live training </div>
                <div>&#8227; Aptitude & reasoning </div>
                <div>&#8227; One programming language </div>
                <div>&#8227; Communication Skills </div>
                <div>&#8227; Linked In optimization </div>
                <div>&#8227; Resume Building with LaTex </div>
                <div>&#8227; Regular job alerts </div>
                <div>&#8227; Guaranteed internship for best performers </div>
              </div>
              <div className="pl-[30%] pr-[30] pb-[10%] pt-[12%]">
                <button className="bg-white text-purple px-[10%] py-[5%] rounded-xl border-[none] font-[bold] hover:bg-[#E4E4E4]">
                  Get Started
                </button>
              </div>
            </div>
          </div>
        </div>
        <div>
        <p className={styles.program}>Internship</p>
          <div className={styles.plans}>
            <div className={styles.card}>
              <div className={styles.cardHeading}>
                <h1>Basic</h1>
                <p>Plan</p>
              </div>
              <div className={styles.line}>
                <svg fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1 1H263"
                    stroke="url(#paint0_radial_671_696)"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <defs>
                    <radialGradient
                      id="paint0_radial_671_696"
                      cx="0"
                      cy="0"
                      r="1"
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(132 1.00014) rotate(-15.5809) scale(107.968 28287.5)"
                    >
                      <stop stop-color="white" />
                      <stop offset="1" stop-color="white" stop-opacity="0" />
                    </radialGradient>
                  </defs>
                </svg>
              </div>
              <div className="text-white pl-[10%] pr-[0%] pt-[5%] pb-[50%]">
                <div>&#8227; 100+ hours of live training </div>
                <div>&#8227; Real world projects building </div>
                <div>&#8227; Certifications </div>
                <div>&#8227; Real world experience</div>
              </div>
              <div className="pl-[30%] pr-[30] pb-[10%] pt-[12%]">
                <button className="bg-white text-purple px-[10%] py-[5%] rounded-xl border-[none] font-[bold] hover:bg-[#E4E4E4]">
                  Get Started
                </button>
              </div>
            </div>
            <div className={styles.card2}>
              <div className="text-[black] text-center pt-8 pb-4 px-8 text-[x-large]">
                <h1>Premium</h1>
                <p className="text-[medium]">Plan</p>
              </div>
              <div className={styles.line}>
                <svg fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1 1H263"
                    stroke="url(#paint0_radial_671_636)"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <defs>
                    <radialGradient
                      id="paint0_radial_671_636"
                      cx="0"
                      cy="0"
                      r="1"
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(132 1.00014) rotate(-15.5809) scale(107.968 28287.5)"
                    >
                      <stop stop-color="#1E003D" />
                      <stop offset="1" stop-color="#1E003D" stop-opacity="0" />
                    </radialGradient>
                  </defs>
                </svg>
              </div>
              <div className="text-black pl-[10%] pr-[0%] pt-[5%] pb-[30%]">
                <div>&#8227; 100+ hours of live training </div>
                <div>&#8227; Real world projects building </div>
                <div>&#8227; GIT basics </div>
                <div>&#8227; Website Hosting </div>
                <div>&#8227; LOR's </div>
                <div>&#8227; Certifications </div>
                <div>&#8227; Guaranteed internship for</div>
                <div>{"  "} best performers</div>
              </div>
              <div className="pl-[30%] pr-[30%] pb-[10%]">
                <button className="bg-[#E4E4E4] text-[#1E003D] px-[10%] py-[8%] rounded-xl border-[none] font-[bold] hover:bg-[#7D6EEB]">
                  Get Started
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="pb-10">
          <p className={styles.program}>Project</p>
          <div className={styles.plans}>
            <div className={styles.card2}>
              <div className="text-[black] text-center pt-8 pb-4 px-8 text-[x-large]">
                <h1>Basic</h1>
                <p className="text-[medium]">Plan</p>
              </div>
              <div className={styles.line}>
                <svg fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1 1H263"
                    stroke="url(#paint0_radial_671_636)"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <defs>
                    <radialGradient
                      id="paint0_radial_671_636"
                      cx="0"
                      cy="0"
                      r="1"
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(132 1.00014) rotate(-15.5809) scale(107.968 28287.5)"
                    >
                      <stop stopColor="#1E003D" />
                      <stop offset="1" stopColor="#1E003D" stopOpacity="0" />
                    </radialGradient>
                  </defs>
                </svg>
              </div>
              <div className="text-black pl-[10%] pr-[0%] pt-[5%] pb-[30%]">
                <div>&#8227; Live guidance </div>
                <div>&#8227; Real world projects building </div>
                <div>&#8227; Certifications </div>
              </div>
              <div className="pl-[30%] pr-[30%] pb-[10%] mt-[82%]">
                <button className="bg-[#E4E4E4] text-[#1E003D] px-[10%] py-[8%] rounded-xl border-[none] font-[bold] hover:bg-[#7D6EEB]">
                  Get Started
                </button>
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.cardHeading}>
                <h1>Premium</h1>
                <p>Plan</p>
              </div>
              <div className={styles.line}>
                <svg fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1 1H263"
                    stroke="url(#paint0_radial_671_696)"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <defs>
                    <radialGradient
                      id="paint0_radial_671_696"
                      cx="0"
                      cy="0"
                      r="1"
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(132 1.00014) rotate(-15.5809) scale(107.968 28287.5)"
                    >
                      <stop stop-color="white" />
                      <stop offset="1" stop-color="white" stop-opacity="0" />
                    </radialGradient>
                  </defs>
                </svg>
              </div>
              <div className="text-white pl-[10%] pr-[0%] pt-[5%] pb-[50%]">
                <div>&#8227; Live guidance </div>
                <div>&#8227; Real world projects building </div>
                <div>&#8227; GIT basics </div>
                <div>&#8227; Website Hosting </div>
                <div>&#8227; Resume Building with LaTex </div>
                <div>&#8227; Regular job alerts </div>
                <div>&#8227; Certifications </div>
                <div>&#8227; Guaranteed internship for</div>
                <div>{"  "} best performers</div>
              </div>
              <div className="pl-[30%] pr-[30] pb-[10%] pt-[12%]">
                <button className="bg-white text-purple px-[10%] py-[5%] rounded-xl border-[none] font-[bold] hover:bg-[#E4E4E4]">
                  Get Started
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurServices;
