import React, {
  createContext,
  useContext,
  useRef,
  useEffect,
  useState,
} from "react";
import io from "socket.io-client";

const SocketContext = createContext();

export function useSocket() {
  return useContext(SocketContext);
}

// const baseUrl = "https://kampkode-socket-io.onrender.com/";
// const baseUrl = "https://expresssocket.onrender.com";
// const baseUrl = "http://localhost:3001";

const baseUrl = process.env.REACT_APP_ENV
  ? "https://expresssocket.onrender.com"
  : "http://localhost:3001";

export function SocketProvider({ children }) {
  const socketRef = useRef();
  const [isReady, setIsReady] = useState(false); // New state to track if socket is ready

  useEffect(() => {
    socketRef.current = io(baseUrl, { reconnection: false });

    // Add this to handle connection errors
    socketRef.current.on("connect_error", (err) => {
      console.log(`Connect Error: ${err.message}`);
      return;
    });

    setIsReady(true);

    socketRef.current.on("connect", () => {
      console.log("user connected successfully");
    });

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, []);

  // Conditionally render children only if socket is ready
  return (
    <SocketContext.Provider value={socketRef.current}>
      {isReady && children}
    </SocketContext.Provider>
  );
}
