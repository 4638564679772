import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSocket } from "./SocketProvider";

const LiveClasses = () => {
  const [className, setClassName] = useState("");
  const [classId, setClassId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [copied, setCopied] = useState(false);
  const navigate = useNavigate();

  const socket = useSocket();

  const joinRoom = async () => {
    if (socket) {
      socket.emit("join-class", classId);
      navigate(`/class?classId=${classId}`);
    } else {
      console.error("Socket is not initialized");
    }
  };

  const generateRandomCode = () => {
    return Math.random().toString(36).toUpperCase().substr(2, 8);
  };

  const handleCreateAndJoin = async () => {
    try {
      // Send a request to the server to join the class using the class identifier
      const newClassId = await generateRandomCode();
      setClassId(newClassId);
      setShowModal(false);
      if (socket) {
        socket.emit("join-class", newClassId);
        navigate(`/class?classId=${newClassId}`);
      } else {
        console.error("Socket is not initialized");
      }
    } catch (error) {
      console.error("Failed to join the class:", error);
    }
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(classId);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <div className="pt-[80px]">
      <div className="w-full text-center bg-customThinPurple">
        <div className="font-semibold text-6xl pt-12">Live Classes</div>
        <div className="py-12 lg:px-40 md:px-10 px-10 font-medium">
          Experience the future of coding education with our live classes! Our
          live classes are designed to make coding education more engaging,
          interactive, and hands-on. Don't miss out on this incredible
          opportunity to enhance your coding skills.
        </div>
      </div>
      <div className="flex">
        <div className="w-1/2">
          <img
            src="https://res.cloudinary.com/dw2h36vj0/image/upload/v1697163828/JavaScript_frameworks-cuate_1_dhzgjm.png"
            alt="Description"
            className="w-full h-full"
          />
        </div>
        <div className="w-1/2 p-4">
          <h2 className="text-4xl font-semibold" style={{ color: "#7D6EEB" }}>
            Highlights of our Live Classes
          </h2>
          <hr className="border-t-2 my-4" style={{ borderColor: "#7D6EEB" }} />
          <div>
            <p>
              <span
                style={{
                  color: "#7D6EEB",
                  marginRight: "6px",
                  fontSize: "23px",
                }}
              >
                •
              </span>
              Highly interactive sessions.
            </p>
            <p>
              <span
                style={{
                  color: "#7D6EEB",
                  marginRight: "6px",
                  fontSize: "23px",
                }}
              >
                •
              </span>
              Real-time Coding Instruction.
            </p>
            <p>
              <span
                style={{
                  color: "#7D6EEB",
                  marginRight: "6px",
                  fontSize: "23px",
                }}
              >
                •
              </span>
              Hands-on coding exercises and projects.
            </p>
            <p>
              <span
                style={{
                  color: "#7D6EEB",
                  marginRight: "6px",
                  fontSize: "23px",
                }}
              >
                •
              </span>
              Best experience in learning.
            </p>
          </div>
          <div className="mt-8">
            {true && (
              <button
                onClick={() => setShowModal(true)}
                className="bg-[#7D6EEB] text-white font-bold py-2 px-4 rounded mr-4"
              >
                + Create Class
              </button>
            )}
            <div className="mt-4 flex items-center">
              <input
                type="text"
                placeholder="Enter Code"
                value={classId}
                onChange={(e) => setClassId(e.target.value)}
                className="p-2 border border-[#7D6EEB] focus:outline-none focus:border-[#7D6EEB] rounded mr-4 w-1/4"
              />
              <button
                onClick={() => joinRoom()}
                className={`${classId ? "text-[#7D6EEB]" : "text-[#D9D9D9]"}`}
              >
                Join
              </button>
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <div className="fixed top-0 left-0 w-full h-full bg-opacity-50 bg-black flex items-center justify-center">
          <div className="relative bg-white p-8 rounded shadow-lg w-96">
            <h2
              className="text-2xl font-bold mb-4"
              style={{ color: "#7D6EEB" }}
            >
              Create a Class
            </h2>
            <button
              onClick={() => setShowModal(false)}
              className="absolute top-2 right-3 text-red-500"
            >
              x
            </button>

            <input
              type="text"
              placeholder="Enter Class Name"
              value={className}
              onChange={(e) => setClassName(e.target.value)}
              className="p-1 border-b border-b-[#B4B4B4] focus:outline-none focus:border-b-[#7D6EEB] w-full mb-4"
            />

            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center">
                <span className="text-[#7D6EEB] mr-2">Code:</span>
                <span className="border p-2">{classId}</span>
                <button onClick={handleCopyToClipboard} className="ml-2">
                  {copied ? "Copied" : "📋"}
                </button>
              </div>
            </div>

            <button
              onClick={handleCreateAndJoin}
              className="bg-[#7D6EEB] text-white font-bold py-2 px-4 rounded"
            >
              Create & Join
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LiveClasses;
