import React from 'react'
import ourValuesData from './values/ourValuesData'
import BlurFade from '../magicui/blur-fade'
const OurValues = () => {
  return (
    <div className='w-screen flex flex-col justify-center items-center bg-[#5133A0] text-white  gap-7 py-16  lg:py-24 font-Inter px-8 lg:px-11'  >
        <div className='lg:w-[60%] text-center lg:mx-8'>
            <span className='text-base  text=[#E9D7FE] font-normal text-[#E9D7FE]'>Our values</span>
            <h1 className='text-3xl lg:text-4xl font-medium my-3'>Aligned by Strong Values, Driven by a Passion for Progress</h1>
            <p className='text-lg font-light text-[#E9D7FE]'>Our values keep us connected and guide us as one team.</p>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 lg:mx-4 gap-10 md:gap-14 pt-12 lg:pt-16'>

            {ourValuesData.map((data,idx) => (
                <div key={idx} className='flex flex-col text-center gap-1  lg:w-[27vw] lg:h-44 '>
                    
                        <BlurFade key={idx} delay={0.25 + idx * 0.2} inView>
                            <div className=' flex justify-center'>

                                <data.Icon />
                            </div>
                        <h1 className='font-medium text-xl mt-5 mb-2'>{data.tittle}</h1>
                        <p className='text-base font-light text-[#E9D7FE] px-2'>{data.description}</p>
                        
                        </BlurFade>
                  </div>
            ))}
            
            
            


        </div>

    </div>
  )
}

export default OurValues