import React from 'react'

const AboutHero = () => {
    return (
        <div className='lg:h-[70vh] flex flex-col justify-center gap-4 mx-10 lg:mx-20 '>
            <div className='flex gap-3 flex-col lg:flex-row'>
                <div className='flex-3 relative'>

                    <span className='text-themepruple text-md pt-4'> About us </span>
                    <h1 className='text-5xl font-semibold py-2'>We do things differently...</h1>
                    <UnderLine />
                </div>
                <div className='flex-1 lg:ml-16 leading-7 text-[#475467] font-normal text-xl mt-10'>
                    <p>At KampKode, we don’t just follow the path to success—we redefine it.</p>
                    <p className='my-3'>More than a company, we’re a thriving community dedicated to your growth.</p>
                    <p>Our goal is clear: to equip engineering students with essential technical skills and invaluable real-world experience. We accomplish this through our dynamic programs, which include Campus Recruitment Training (CRT), internships, and hands-on projects.</p>
                </div>
            </div>

        </div>
    )
}

export default AboutHero

const UnderLine = () => (
    <div className='absolute bottom-0 md:right-44 md:top-16 lg:-right-7 -z-10'>

    <svg width="350" height="24" viewBox="0 0 350 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 8H260.845L80.591 16H342" stroke="#F4EBFF" stroke-width="16" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
    </div>

)