export const CardLogos = {
    ChatSalesIcon: () => (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="48" height="48" rx="10" fill="#7F70ED"/>
        <path d="M20.9996 26C20.9996 26 22.3121 27.5 24.4996 27.5C26.6871 27.5 27.9996 26 27.9996 26M27.2496 21H27.2596M21.7496 21H21.7596M24.4996 32C29.194 32 32.9996 28.1944 32.9996 23.5C32.9996 18.8056 29.194 15 24.4996 15C19.8052 15 15.9996 18.8056 15.9996 23.5C15.9996 24.45 16.1555 25.3636 16.443 26.2166C16.5512 26.5376 16.6053 26.6981 16.6151 26.8214C16.6247 26.9432 16.6174 27.0286 16.5873 27.1469C16.5568 27.2668 16.4894 27.3915 16.3547 27.6408L14.7191 30.6684C14.4857 31.1002 14.3691 31.3161 14.3952 31.4828C14.4179 31.6279 14.5034 31.7557 14.6288 31.8322C14.7728 31.9201 15.0169 31.8948 15.5052 31.8444L20.6262 31.315C20.7813 31.299 20.8588 31.291 20.9295 31.2937C20.999 31.2963 21.0481 31.3029 21.1159 31.3185C21.1848 31.3344 21.2714 31.3678 21.4448 31.4345C22.3928 31.7998 23.4228 32 24.4996 32ZM27.7496 21C27.7496 21.2761 27.5258 21.5 27.2496 21.5C26.9735 21.5 26.7496 21.2761 26.7496 21C26.7496 20.7239 26.9735 20.5 27.2496 20.5C27.5258 20.5 27.7496 20.7239 27.7496 21ZM22.2496 21C22.2496 21.2761 22.0258 21.5 21.7496 21.5C21.4735 21.5 21.2496 21.2761 21.2496 21C21.2496 20.7239 21.4735 20.5 21.7496 20.5C22.0258 20.5 22.2496 20.7239 22.2496 21Z" stroke="white" stroke-width="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    ),
    ChatSupportIcon : () => (
<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="48" height="48" rx="10" fill="#7F70ED"/>
<path d="M18.0944 23.2288C18.0322 22.8282 18 22.4179 18 22C18 17.5817 21.6052 14 26.0526 14C30.4999 14 34.1052 17.5817 34.1052 22C34.1052 22.9981 33.9213 23.9535 33.5852 24.8345C33.5154 25.0175 33.4804 25.109 33.4646 25.1804C33.4489 25.2512 33.4428 25.301 33.4411 25.3735C33.4394 25.4466 33.4493 25.5272 33.4692 25.6883L33.8717 28.9585C33.9153 29.3125 33.9371 29.4895 33.8782 29.6182C33.8266 29.731 33.735 29.8205 33.6211 29.8695C33.4911 29.9254 33.3146 29.8995 32.9617 29.8478L29.7765 29.3809C29.6101 29.3565 29.527 29.3443 29.4512 29.3448C29.3763 29.3452 29.3245 29.3507 29.2511 29.3661C29.177 29.3817 29.0823 29.4172 28.893 29.4881C28.0097 29.819 27.0524 30 26.0526 30C25.6344 30 25.2237 29.9683 24.8227 29.9073M19.6316 34C22.5965 34 25 31.5376 25 28.5C25 25.4624 22.5965 23 19.6316 23C16.6667 23 14.2632 25.4624 14.2632 28.5C14.2632 29.1106 14.3603 29.6979 14.5395 30.2467C14.6153 30.4787 14.6532 30.5947 14.6657 30.6739C14.6786 30.7567 14.6809 30.8031 14.6761 30.8867C14.6714 30.9668 14.6514 31.0573 14.6113 31.2383L14 34L16.9948 33.591C17.1583 33.5687 17.24 33.5575 17.3114 33.558C17.3865 33.5585 17.4264 33.5626 17.5001 33.5773C17.5701 33.5912 17.6742 33.6279 17.8823 33.7014C18.4306 33.8949 19.0191 34 19.6316 34Z" stroke="white" stroke-width="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

    ),
    LocationIcon : () => (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="48" height="48" rx="10" fill="#7F70ED"/>
<path d="M24 24.5C25.6569 24.5 27 23.1569 27 21.5C27 19.8431 25.6569 18.5 24 18.5C22.3431 18.5 21 19.8431 21 21.5C21 23.1569 22.3431 24.5 24 24.5Z" stroke="white" stroke-width="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M24 34C26 30 32 27.4183 32 22C32 17.5817 28.4183 14 24 14C19.5817 14 16 17.5817 16 22C16 27.4183 22 30 24 34Z" stroke="white" stroke-width="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

    ),
    CallIcon : () => (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="48" height="48" rx="10" fill="#7F70ED"/>
<path d="M20.3803 20.8534C21.0763 22.303 22.0251 23.6616 23.2266 24.8632C24.4282 26.0648 25.7869 27.0136 27.2365 27.7096C27.3612 27.7694 27.4235 27.7994 27.5024 27.8224C27.7828 27.9041 28.127 27.8454 28.3644 27.6754C28.4313 27.6275 28.4884 27.5704 28.6027 27.4561C28.9523 27.1064 29.1271 26.9316 29.3029 26.8174C29.9658 26.3864 30.8204 26.3864 31.4833 26.8174C31.6591 26.9316 31.8339 27.1064 32.1835 27.4561L32.3783 27.6509C32.9098 28.1824 33.1755 28.4481 33.3198 28.7335C33.6069 29.301 33.6069 29.9713 33.3198 30.5389C33.1755 30.8242 32.9098 31.09 32.3783 31.6214L32.2207 31.779C31.6911 32.3087 31.4263 32.5735 31.0662 32.7757C30.6667 33.0001 30.0462 33.1615 29.588 33.1601C29.1751 33.1589 28.8928 33.0788 28.3284 32.9186C25.295 32.0576 22.4326 30.4332 20.0447 28.0452C17.6567 25.6572 16.0322 22.7948 15.1712 19.7614C15.011 19.197 14.9309 18.9148 14.9297 18.5018C14.9283 18.0436 15.0897 17.4231 15.3141 17.0236C15.5164 16.6636 15.7812 16.3988 16.3108 15.8691L16.4684 15.7115C16.9999 15.1801 17.2656 14.9143 17.551 14.77C18.1185 14.4829 18.7888 14.4829 19.3564 14.77C19.6417 14.9143 19.9075 15.1801 20.4389 15.7115L20.6338 15.9064C20.9834 16.256 21.1582 16.4308 21.2725 16.6066C21.7035 17.2694 21.7035 18.124 21.2725 18.7869C21.1582 18.9627 20.9834 19.1375 20.6338 19.4871C20.5195 19.6014 20.4623 19.6586 20.4145 19.7254C20.2445 19.9628 20.1858 20.3071 20.2675 20.5874C20.2905 20.6663 20.3204 20.7287 20.3803 20.8534Z" stroke="white" stroke-width="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

    )
    
}
