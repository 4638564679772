import { Heart } from 'lucide-react';
import React from 'react';
import WordFadeIn from './magicui/word-fade-in';
import { useNavigate } from 'react-router-dom';

const ApplyInternship = () => {
  const nagivate = useNavigate();
  const move = () =>{
    nagivate('/internships')
  }
  return (
    <div className='bg-[#5133A0] text-white font-Inter py-10 px-6 mx-7 md:mx-12 lg:mx-20 rounded-lg flex flex-col items-center justify-center gap-6 md:gap-8 my-6' >
      <div className='flex flex-col items-center justify-center text-center'>
        <WordFadeIn 
          words={
            <>
              You&apos;ve Reached the Bottom of Our Heart <Heart className='text-white w-6 h-6 md:w-8 md:h-8 ml-2 inline-block' />
            </>
          }
          className='font-medium text-2xl md:text-3xl lg:text-4xl text-white'
        />
      </div>
      <p className='text-[#E9D7FE] font-light text-base md:text-lg lg:text-xl font-Inter tracking-normal text-center'>
        Apply for internships today and start your journey towards success with KampKode!
      </p>
      <button className='rounded-md text-[#344054] bg-white font-semibold font-Inter w-32 h-12 md:w-36 md:h-14 lg:w-40 lg:h-16 cursor-pointer'
      onClick={move}
      >
        Apply Now
      </button>
    </div>
  )
}

export default ApplyInternship;
