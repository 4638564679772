import React from "react";

interface RatingProps {
  value: number;
}

const Rating: React.FC<RatingProps> = ({ value }) => {
  const renderStar = (index: number) => {
    const decimalPart = value - index;
    if (decimalPart >= 0.75) {
      return <i className="fas fa-star text-yellow-400" key={index} />;
    } else if (decimalPart >= 0.25) {
      return <i className="fas fa-star-half-alt text-yellow-400" key={index} />;
    } else {
      return <i className="far fa-star text-yellow-400" key={index} />;
    }
  };

  return (
    <div>{Array.from({ length: 5 }, (_, index) => renderStar(index))}</div>
  );
};

export default Rating;
