export const BenifitsCardIcons = {
    AtomIcon: () => (
      <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M31.3783 28.1559C30.882 28.717 30.3612 29.2711 29.8166 29.8156C21.941 37.6912 12.0742 40.5932 7.77845 36.2975C4.83338 33.3524 5.27136 27.7888 8.37568 22.0436M12.6312 15.9955C13.1477 15.4083 13.691 14.8286 14.2603 14.2593C22.1358 6.38372 32.0027 3.48171 36.2984 7.77748C39.2455 10.7246 38.8049 16.2938 35.6948 22.0432M29.8166 14.2593C37.6922 22.1349 40.5942 32.0017 36.2984 36.2975C32.0027 40.5932 22.1358 37.6912 14.2603 29.8156C6.38469 21.9401 3.48269 12.0732 7.77845 7.77748C12.0742 3.48171 21.941 6.38371 29.8166 14.2593ZM23.8336 21.9992C23.8336 23.0117 23.0128 23.8325 22.0003 23.8325C20.9878 23.8325 20.1669 23.0117 20.1669 21.9992C20.1669 20.9867 20.9878 20.1658 22.0003 20.1658C23.0128 20.1658 23.8336 20.9867 23.8336 21.9992Z" stroke="#7F56D9" stroke-width="3.55208" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>      

    ),
    UserCheck:() => (
      <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.9998 28.4167H13.7498C11.1913 28.4167 9.91204 28.4167 8.87108 28.7324C6.52734 29.4434 4.69324 31.2775 3.98228 33.6212C3.6665 34.6622 3.6665 35.9415 3.6665 38.5M29.3332 33L32.9998 36.6667L40.3332 29.3333M26.5832 13.75C26.5832 18.3063 22.8895 22 18.3332 22C13.7768 22 10.0832 18.3063 10.0832 13.75C10.0832 9.19365 13.7768 5.5 18.3332 5.5C22.8895 5.5 26.5832 9.19365 26.5832 13.75Z" stroke="#7F56D9" stroke-width="3.66667" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    ),
    HeartHand:() => (
      <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1254_14291)">
<path d="M12.4994 41.8483H17.9375C18.6465 41.8483 19.3512 41.9327 20.0385 42.1014L25.7845 43.4977C27.0313 43.8014 28.3301 43.831 29.5899 43.5863L35.9431 42.3503C37.6213 42.0234 39.1652 41.2197 40.3751 40.0428L44.87 35.6703C46.1536 34.4237 46.1536 32.4009 44.87 31.1523C43.7143 30.028 41.8842 29.9015 40.5767 30.8549L35.3381 34.6768C34.5879 35.2252 33.675 35.5205 32.7361 35.5205H27.6775L30.8974 35.5204C32.7123 35.5204 34.1824 34.0903 34.1824 32.3249V31.6858C34.1824 30.2198 33.1568 28.9416 31.6954 28.5873L26.7256 27.3787C25.9168 27.1825 25.0885 27.0834 24.2559 27.0834C22.2459 27.0834 18.6075 28.7476 18.6075 28.7476L12.4994 31.3019M4.16602 30.4167V42.5C4.16602 43.6668 4.16602 44.2502 4.39309 44.6959C4.59282 45.0879 4.91153 45.4066 5.30354 45.6063C5.74919 45.8334 6.33258 45.8334 7.49935 45.8334H9.16602C10.3328 45.8334 10.9162 45.8334 11.3618 45.6063C11.7538 45.4066 12.0725 45.0879 12.2723 44.6959C12.4994 44.2502 12.4994 43.6668 12.4994 42.5V30.4167C12.4994 29.2499 12.4994 28.6665 12.2723 28.2209C12.0725 27.8289 11.7538 27.5102 11.3618 27.3104C10.9162 27.0834 10.3328 27.0834 9.16602 27.0834H7.49935C6.33258 27.0834 5.74919 27.0834 5.30354 27.3104C4.91153 27.5102 4.59282 27.8289 4.39309 28.2209C4.16602 28.6665 4.16602 29.2499 4.16602 30.4167ZM-32.6092 -14.3776C-33.8526 -16.9794 -41.2795 -19.5112 -44.0674 -18.1808C-46.8552 -16.8504 -48.0429 -13.6954 -46.8757 -10.9257C-46.1543 -9.21397 -44.0875 -5.88993 -42.6139 -3.60028C-42.0694 -2.75428 -41.7972 -2.33129 -41.3995 -2.08383C-41.0584 -1.87158 -40.6497 -3.58701 -40.2482 -3.60028C-39.78 -3.61576 -39.3146 -2.01613 -38.42 -2.47654C-35.999 -3.72261 -32.5471 -5.56795 -31.0665 -6.68967C-28.6709 -8.50468 -28.0775 -11.8408 -29.871 -14.3769C-31.6646 -16.913 -30.2315 -16.0092 -32.6092 -14.3776Z" stroke="#7F56D9" stroke-width="4.16667" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M43.1318 15.7708V12.9335C43.1318 12.7641 43.1318 12.6793 43.106 12.6046C43.0832 12.5384 43.046 12.4782 42.997 12.4282C42.9417 12.3717 42.8659 12.3338 42.7144 12.258L38.4118 10.1067M30.8597 11.0507V17.4762C30.8597 17.8273 30.8597 18.0029 30.9145 18.1566C30.9629 18.2925 31.0418 18.4154 31.1451 18.5161C31.2621 18.6299 31.4216 18.703 31.7408 18.8493L37.7825 21.6184C38.014 21.7245 38.1297 21.7776 38.2503 21.7985C38.3572 21.8171 38.4664 21.8171 38.5733 21.7985C38.6939 21.7776 38.8096 21.7245 39.0411 21.6184L45.0828 18.8493C45.4019 18.703 45.5615 18.6299 45.6784 18.5161C45.7818 18.4154 45.8607 18.2925 45.9091 18.1566C45.9639 18.0029 45.9639 17.8273 45.9639 17.4762V11.0507M28.9717 10.1067L38.074 5.55554C38.1979 5.49362 38.2598 5.46266 38.3247 5.45048C38.3823 5.43968 38.4413 5.43968 38.4988 5.45048C38.5638 5.46266 38.6257 5.49362 38.7495 5.55554L47.8519 10.1067L38.7495 14.6579C38.6257 14.7198 38.5638 14.7508 38.4988 14.763C38.4413 14.7738 38.3823 14.7738 38.3247 14.763C38.2598 14.7508 38.1979 14.7198 38.074 14.6579L28.9717 10.1067Z" stroke="#7F56D9" stroke-width="1.88802" strokeLinecap="round" strokeLinejoin="round"/>
</g>
<defs>
<clipPath id="clip0_1254_14291">
<rect width="50" height="50" fill="white"/>
</clipPath>
</defs>
</svg>

    ),
    MessageCheck:() => (
      <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.8119 21.5625L21.5619 25.3125L29.9994 16.875M23.4369 37.5C32.239 37.5 39.3744 30.3645 39.3744 21.5625C39.3744 12.7605 32.239 5.625 23.4369 5.625C14.6349 5.625 7.49942 12.7605 7.49942 21.5625C7.49942 23.3437 7.79163 25.0567 8.33074 26.6561C8.53361 27.258 8.63505 27.559 8.65335 27.7902C8.67141 28.0185 8.65776 28.1786 8.60127 28.4005C8.54407 28.6253 8.41779 28.859 8.16522 29.3265L5.09836 35.0032C4.6609 35.8129 4.44217 36.2177 4.49113 36.5302C4.53377 36.8024 4.69394 37.042 4.92912 37.1854C5.19913 37.3501 5.65685 37.3028 6.57231 37.2082L16.1742 36.2157C16.465 36.1856 16.6104 36.1706 16.7429 36.1756C16.8732 36.1806 16.9653 36.1929 17.0924 36.2222C17.2216 36.2519 17.3841 36.3145 17.7091 36.4398C19.4867 37.1246 21.418 37.5 23.4369 37.5Z" stroke="#7F56D9" stroke-width="3.75" strokeLinecap="round" strokeLinejoin="round"/>
</svg>


    ),
    Certificate:() => (
<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.9162 36.666H9.16618C7.14113 36.666 5.49951 35.0244 5.49951 32.9993V7.33268C5.49951 5.30764 7.14113 3.66602 9.16618 3.66602H34.8328C36.8579 3.66602 38.4995 5.30764 38.4995 7.33268V32.9993C38.4995 35.0244 36.8579 36.666 34.8328 36.666H32.0828M21.9995 34.8327C25.0371 34.8327 27.4995 32.3702 27.4995 29.3327C27.4995 26.2951 25.0371 23.8327 21.9995 23.8327C18.9619 23.8327 16.4995 26.2951 16.4995 29.3327C16.4995 32.3702 18.9619 34.8327 21.9995 34.8327ZM21.9995 34.8327L22.0388 34.8323L16.1854 40.6857L11 35.5003L16.5355 29.9647M21.9995 34.8327L27.853 40.6857L33.0385 35.5003L27.5029 29.9647M16.4995 10.9993H27.4995M12.8328 17.416H31.1662" stroke="#7F56D9" stroke-width="3.72396" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

    ),
    Target:() => (
      <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M29.3331 14.667V9.16699L34.8331 3.66699L36.6664 7.33366L40.3331 9.16699L34.8331 14.667H29.3331ZM29.3331 14.667L21.9998 22.0002M40.3332 22.0003C40.3332 32.1255 32.1251 40.3337 21.9998 40.3337C11.8746 40.3337 3.6665 32.1255 3.6665 22.0003C3.6665 11.8751 11.8746 3.66699 21.9998 3.66699M31.1665 22.0003C31.1665 27.0629 27.0624 31.167 21.9998 31.167C16.9372 31.167 12.8332 27.0629 12.8332 22.0003C12.8332 16.9377 16.9372 12.8337 21.9998 12.8337" stroke="#7F56D9" stroke-width="3.66667" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

    ),

}
